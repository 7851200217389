import React from 'react';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Store from './../../services/Store.js';
import Helpers from './../../services/Helpers.js';
import { NavLink, withRouter } from "react-router-dom";
import Avatar from './../../components/Avatar.js';

class Header extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	      	translations:{},
	      	navigationToggled:false,
	      	excepted_routes:["gauth", "mauth", "impersonate","onboarding"],
	      	user_name:Store.retrieveUserName(),
	      	user_image:Store.retrieveUserImage()
	  	};
	  	this.moduleName='header'; 
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.getTranslations(this.moduleName)
            .then(data=>{
            	oldState.translations=data;			                						                
                this.setState(oldState);
            }).catch(error=>{
            	oldState.translations=error;
            	this.setState(oldState);
            });
        window.ee.on('languageChanged', () => { 
        	let oldState=Object.assign({}, this.state);
        	Helpers.getTranslations(this.moduleName)
            .then(data=>{
            	oldState.translations=data;			                						                
                this.setState(oldState);
            }).catch(error=>{
            	oldState.translations=error;
            	this.setState(oldState);
            });	        
	    });
	    window.ee.on('preferencesChanged', () => { 
        	let oldState=Object.assign({}, this.state);
	        oldState.user_name=Store.retrieveUserName();
	        oldState.user_image=Store.retrieveUserImage();
	        this.setState(oldState);
	    });
	}

	componentWillUnmount(){
      	window.ee.removeListener('languageChanged', () => { 
        	//
      	}); 
      	window.ee.removeListener('preferencesChanged', () => { 
        	//
      	});  
  	}

	logout(event){
		Helpers.doRequest('post','logout')
 			.then(data=>{
 				Helpers.clearTranslations();
            	Store.clearData();
				window.location.href=process.env.REACT_APP_DEFAULT_URL;
 			}).catch(error=>{
 				Helpers.clearTranslations();
            	Store.clearData();
				window.location.href=process.env.REACT_APP_DEFAULT_URL;
        	});			
	}

	handleNavigation(e,path){
		if(this.state.navigationToggled){
			this.setState({navigationToggled:false});
		}
	}

	navigationToggled(value){
		this.setState({navigationToggled:value});
	}

	switchEnvironment(index){
		Helpers.doRequest('post','switch',{'id':this.props.environments[index].id})
 			.then(data=>{
            	Store.clearData();
				window.location.href=data.redirect+'?microjwt='+data.microjwt;
 			}).catch(error=>{
 				Helpers.showAlerts(this,error);
        	});	
	}

	render(){
		let logoContent;
		let selectedEnvironment;
		for(let x in this.props.environments){
			if(this.props.environments[x].selected){
				selectedEnvironment=this.props.environments[x].name;
			}
		}
		if(this.props.environments.length && this.props.environments.length>1 && this.state.excepted_routes.indexOf(this.props.main_page) === -1){
			logoContent=<NavDropdown title={selectedEnvironment} id="logo">
				{ this.props.environments.map((item, key) =>
				<NavDropdown.Item className={"w-100 "+(item.selected ? "hidden" : "")} key={key} onClick={(e) => (item.selected ? ()=>{} : this.switchEnvironment(key))} disabled={(item.selected ? true : false)}>{(item.selected ? <span className="font-weight-bold">{item.name}</span> : item.name)}</NavDropdown.Item>
			) }
			</NavDropdown>
		}else if(this.props.environments.length && this.state.excepted_routes.indexOf(this.props.main_page) === -1){
			logoContent=<NavDropdown title={selectedEnvironment} id="logo" className="simple_channel"></NavDropdown>
		}else{
			logoContent=<h5>{selectedEnvironment}</h5>
		}
		let headerContent=<Navbar.Collapse id="header-navbar-nav" className="col-sm-9">	
			<Nav variant="pills" className="ml-auto text-right justify-content-end"> 
				{ (this.state.excepted_routes.indexOf(this.props.main_page) === -1 && (this.props.permissions.environment || this.props.permissions.microtraining) ? 
		  			<NavLink className="nav-link" to="/trainings" onClick={(e)=>this.handleNavigation(e,'trainings')}>{Helpers.translate('Microtrainings',this.state.translations.general,'general')}</NavLink> : null) }
				{ (this.state.excepted_routes.indexOf(this.props.main_page) === -1 && (this.props.permissions.environment || this.props.permissions.team) ? 
		  			<NavLink className="nav-link" 
		  				to="/teams" 
		  				onClick={(e)=>this.handleNavigation(e,'teams')}
		  			>
		  				{Helpers.translate('Teams',this.state.translations.general,'general')}
		  			</NavLink>
		  			 : null) 
		  		}
		  		{ (this.state.excepted_routes.indexOf(this.props.main_page) === -1 && (this.props.permissions.environment || this.props.permissions.campaign) ? 
		  			<NavLink className="nav-link" 
		  				to="/campaigns" 
		  				onClick={(e)=>this.handleNavigation(e,'campaigns')}
		  			>
		  				{Helpers.translate('Campaigns',this.state.translations.general,'general')}
		  			</NavLink> 
		  			: null) 
		  		}
		  		{ (this.state.excepted_routes.indexOf(this.props.main_page) === -1 && this.props.permissions.environment ? 
		  			<NavLink className="nav-link" 
			  			to="/administration" 
			  			onClick={(e)=>this.handleNavigation(e,'administration')}
			  			isActive={(match, location) => {
						    if(location.pathname.indexOf('administration') !== -1 || location.pathname.indexOf('users') !== -1 || location.pathname.indexOf('roles') !== -1){
						    	return true;
						    }else{
						    	return false;
						    }
						  }}
		  			>
		  			{Helpers.translate('Domain and Roles',this.state.translations.general,'general')}
		  			</NavLink> 
		  			: null) 
		  		}
			</Nav>
			<Nav className="text-right">
	      		<NavDropdown alignRight title={<Avatar user_image={this.state.user_image} user_name={this.state.user_name} />} id="logout">
	        		{(this.state.excepted_routes.indexOf(this.props.main_page) === -1 ? <NavDropdown.Item onClick={(e) => this.props.history.push("/account")}>{Helpers.translate('Account preferences',this.state.translations.general,'general')}</NavDropdown.Item> : null)}					    
	      			{(this.state.excepted_routes.indexOf(this.props.main_page) === -1 ? <NavDropdown.Item onClick={(e) => ()=>{}}>{Helpers.translate('Manage subscription',this.state.translations.general,'general')}</NavDropdown.Item> : null)}
	      			<NavDropdown.Item className="logout" onClick={(e) => this.logout(e)}>{Helpers.translate('Sign Out',this.state.translations.header,'header')}</NavDropdown.Item>					    
	      		</NavDropdown>
	    	</Nav>
		</Navbar.Collapse>			 
		return (
			<div className="content menu">
				<div className="col-sm-12">
		      		<div className="row">
		      			<Navbar id="header_nav" className="col-sm-12" expanded={this.state.navigationToggled} bg="light" expand="md" onToggle={(e)=>this.navigationToggled(e)}>
						  	<Navbar.Toggle aria-controls="header-navbar-nav" />
						  	<Navbar.Collapse id="header-navbar-logo" className="col-sm-3">
						  		<img alt=""  src={require('./../../assets/images/logo_senseii.png') }  />
						  		{ logoContent }							
							</Navbar.Collapse>
						  	{ headerContent}				    	
						</Navbar>
		      		</div>
				</div>	
			</div>
		)		
	}
}
export default withRouter(Header);
