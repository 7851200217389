import React from 'react';

export default class DatePickerInput extends React.Component{

	render(){
		return (
			<input type="text" className="form-control" 
			value={this.props.value} 
			onClick={this.props.onClick} 
			readOnly
			placeholder="DD/MM/YYYY hh:ii"/>
		);
	}

}