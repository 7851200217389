import React from 'react';
import styles from './css/Notification.module.css';
import Helpers from './../services/Helpers.js';
import DismissibleAlert from './DismissibleAlert.js';
import { withRouter } from "react-router-dom";

class Notification extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	      	alerts:[]
	  	};
	  	this.listeners=[];
	}

	componentDidMount(){
		if(this.listeners.indexOf("notificationData") === -1){
	      window.ee.on('notificationData', (data) => { 
	        this.setState({'alerts':data});
	      });
	    }
	}

	componentWillUnmount(){
	    if(this.listeners.indexOf("notificationData") !== -1){
	      window.ee.removeListener('notificationData', () => { 
	        //
	      });
	    }   
	}

	render(){
		const generalMessages = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} 
	        	type={item.type} 
	        	message={item.msg}
	        	dismissTimeout={5000} 
	        	dismissAlert={(e) => Helpers.closeAlert(this,key)}
	        	autoDismiss={()=>Helpers.closeAlert(this,key)}
	        />
	    );		
		return (
			<div className={styles["notification"]}>{generalMessages}</div>
		);
	}

}
export default withRouter(Notification);