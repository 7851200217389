import React from 'react';
import styles from './css/Register.module.css';
import Helpers from './../services/Helpers.js';
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default class Activation extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	activation:this.props.data,
	      	translations:{"general":{}}
	  	};
	  	this.validations={
	  		"accept_terms":["required"]
	  	};  	
	}

	saveActivation(){
		let fieldTranslations={
    		"accept_terms":"This"
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.activation,fieldTranslations,this.state.translations.general,true);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.activation);
		Helpers.doRequest('post','activation',obj)
            .then(data=>{
            	this.props.onSuccess(data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
			<DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return (
			<div className="page-login registration">
				<div className="page-login-vertical">
					<div className="page-login-box">
						<div className="row">
							<div className="col-sm-12">
								<h1 className="text-center">Activate account</h1>
							</div>
						</div>
						<div className="row">
							<div className="page-login-panel">
								{generalMsg}
								<Form className="text-left">	
									<Form.Group>
								    	<Form.Label className="ft-16">Email</Form.Label>
								    	<InputGroup className={this.state.errors.email ? 'border-error' : ''}>
								    		<InputGroup.Prepend>
										     	<i className={"fas fa-envelope "+styles['registration-input-group-prepend-i']}></i>
										    </InputGroup.Prepend>
										    <Form.Control className={"pl-0 "+styles['registration-input-group-input']} type="email" value={this.state.activation.email || ''} name="email" placeholder="Email" autoComplete="off" disabled/>
								    	</InputGroup>
								  		<p>We'll never share your email with anyone else</p>
								  	</Form.Group>				
								  	<Form.Group>
								    	<Form.Label className="ft-16">Name</Form.Label>
								    	<InputGroup className={this.state.errors.email ? 'border-error' : ''}>
										    <InputGroup.Prepend>
										     	<i className={"fas fa-user "+styles['registration-input-group-prepend-i']}></i>
										    </InputGroup.Prepend>
										    <Form.Control type="text" className={"pl-0 "+styles['registration-input-group-input']} value={this.state.activation.name || ''} name="name" placeholder="Name" autoComplete="off" disabled/>
										</InputGroup>								    	
								  	</Form.Group>
								  	<Form.Group controlId="accept_terms">
									    <Form.Check 
									    	type="checkbox" 
									    	custom 
									    	label={<span>Accept <a href="#" target="_blank">Terms</a> & <a href="#" target="_blank">Privacy Policy</a></span>} 
									    	onChange={(e)=>Helpers.updateStateValue(this,e.target.checked,'accept_terms','activation')} 
									    	checked={this.state.activation.accept_terms && this.state.activation.accept_terms === true ? 'checked' : ''}
									    />
									    {this.state.errors.accept_terms && <p className="text-danger mt-1">{this.state.alertsField.accept_terms}</p>}
									</Form.Group>							  	
								</Form>
								<div className="col-sm-12 mt-4">
									<Button type="submit" onClick={()=>this.saveActivation()} disabled={this.state.activation.accept_terms && this.state.activation.accept_terms === true ? false : true}>Activate</Button>
								</div>
							</div>
						</div>
					</div>
			   	</div>
		   	</div>
		)
	}

}