import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Badge from 'react-bootstrap/Badge';
import MultipleChoiceThreeQuestions from './../../components/MultipleChoiceThreeQuestions.js';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';

class Message extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		create:true,
	  		message_id:null,
	  		campaign_id:null,
	  		show_content:false,
	  		message:{},
	  		activeTab:null,	  		
	  		tmp:{
	  			edit_name:false,
	  			name:''
	  		},
	  		tab_changed:0
	  	};
	  	this.moduleName='campaign';
	  	this.message_validations={
	  		"name":["required","min:3"],
	  		"templates":["required","selected:1"]
	  	};
	  	this.message_name_validations={
	  		"name":["required","min:3"]
	  	};
	}

	componentDidMount(){
		if(!this.props.match.params.campaign_id || (this.props.match.params.campaign_id && isNaN(this.props.match.params.campaign_id))){
			this.props.history.push("/");
		}else{
			if(this.props.match.params.id){
				let oldState=Object.assign({}, this.state);
				if(!isNaN(parseInt(this.props.match.params.id))){
					Helpers.doRequest('get','messages/'+this.props.match.params.id+'/edit?campaign_id='+this.props.match.params.campaign_id)
			 			.then(data=>{  		 				       	                 	            	
			            	oldState.create=false;
			            	oldState.show_content=true;
			            	oldState.message=data.message;
			            	oldState.message_id=data.message.message_id;
			            	oldState.campaign_id=this.props.match.params.campaign_id;
			            	oldState.activeTab=data.message.languages[0].language_code;
			            	Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
			 			}).catch(error=>{
			            	//	
			        	});
				}else{
					Helpers.doRequest('get','messages/create?campaign_id='+this.props.match.params.campaign_id)
			 			.then(data=>{  
			 				if(!data.message.allow_create){
			 					this.props.history.push("/campaigns/"+this.props.match.params.campaign_id);
								return false;
			 				}	
			 				oldState.show_content=true;	 
			 				oldState.campaign_id=this.props.match.params.campaign_id;			       	                 	    
			            	oldState.message=data.message;
			            	Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
			 			}).catch(error=>{
			            	//	
			        	});		
				}		
			}
		}		
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		oldState[parent][model]=value;
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        this.setState(oldState);  
    }

	updateTemplateSelection(index){
		let oldState=Object.assign({},this.state);
		for(let x in oldState.message.templates){
			oldState.message.templates[x].checked=(parseInt(x)===parseInt(index) ? true : false);
		}
		this.setState(oldState);
	}	

	createMessage(){
		let fieldTranslations={
    		"name":Helpers.translate('Message name',this.state.translations.campaign,'campaign'),
    		"templates":Helpers.translate('This',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.message_validations,this.state.message,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.message);
		obj.campaign_id=this.state.campaign_id;
		obj.template_id=0;
		for(let x in obj.templates){
			if(obj.templates[x].checked){
				obj.template_id=obj.templates[x].id;
				break;
			}
		}
		Helpers.doRequest('post','messages',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.message=data.message;
            	oldState.message_id=data.message.message_id;
            	oldState.create=false;
            	oldState.activeTab=data.message.languages[0].language_code;
            	this.setState(oldState);
            	this.props.history.push("/campaigns/"+this.state.campaign_id+"/messages/"+data.message.message_id)
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	updateName(){
		let fieldTranslations={
    		"name":Helpers.translate('Message name',this.state.translations.campaign,'campaign')
    	};
		let formErrors=FormValidation.checkValidity(this.message_name_validations,this.state.tmp,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.tmp);
		obj.message_id=this.state.message_id;
		Helpers.doRequest('post','messages/update_name',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.message.name=oldState.tmp.name;
            	oldState.tmp.name='';
            	oldState.tmp.edit_name=false;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updateTabsValue(value,model,tabIndex){
    	let oldState=Object.assign({}, this.state);  
    	if(model === 'status'){
    		if(value !== null){
    			this.updateStatus(tabIndex,value.id);
    			return false;    		
        	}else{
        		oldState.message.languages[tabIndex][model]=value;
        	}
    	}else{
    		oldState.message.languages[tabIndex][model]=value;
    	} 	
    	this.setState(oldState);
    }

    renderTab(tab){
    	if(this.state.activeTab === tab){
    		return false;
    	}
    	let oldState=Object.assign({}, this.state);
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];	
		oldState.success={};
	    oldState.alertsField=[];
	    this.setState(oldState);	
    }

    updateTab(data,index){
    	let oldState=Object.assign({}, this.state);
    	oldState.message.languages[index]=data.item;
    	this.setState(oldState);
    	let genThis=this;
    	setTimeout(function(){
    		Helpers.showAlerts(genThis,data);
    	},200);
    }

    updateLanguagesData(data){
    	let oldState=Object.assign({}, this.state);
    	oldState.message.languages=data.message.languages;
    	oldState.tab_changed++;
    	this.setState(oldState);
    	let genThis=this;
    	setTimeout(function(){
    		Helpers.showAlerts(genThis,data);
    	},200);
    }

    updateStatus(index,status){
		let obj={};
		obj.message_language_id=this.state.message.languages[index].id;
		obj.status=status;
		Helpers.doRequest('post','messages/update_status',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.message.languages[index]=data.item;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    sendPreviewMail(id){
    	let obj={};
		obj.message_language_id=id;
		obj.email=this.state.message.preview_email;
		Helpers.doRequest('post','messages/preview_send',obj)
            .then(data=>{
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    tabComponent(item,key){
    	switch(this.state.message.template_identifier){
    		case 'multiple_choice_3_questions':
    			return <MultipleChoiceThreeQuestions data={item} 
    				translations={this.state.translations}
    				allow_create={this.state.message.allow_create}
    				header_max_size={this.state.message.header_max_size}
    				errors={this.state.errors}
    				index={key}
    				data_main={ (key>0 ? this.state.message.languages[0] : null) }
    				alertsField={this.state.alertsField}
    				onSuccess={(data,index)=>this.updateLanguagesData(data)}
    				onError={(data)=>Helpers.showAlerts(this,data,true)}
    				tab_changed={this.state.tab_changed}
    				createNewMessage={()=>this.showCreateNewMessage()} />;
    		default:
    			return null;
    	}
    }

    showCreateNewMessage(){
    	let oldState=Object.assign({},this.state);
    	oldState.message_id=null;
    	oldState.create=true;
    	oldState.activeTab=null;
    	oldState.tmp={ edit_name:false, name:''};
	  	oldState.tab_changed=0;
	  	Helpers.doRequest('get','messages/create?campaign_id='+this.state.campaign_id)
			.then(data=>{  
				if(!data.message.allow_create){
					this.props.history.push("/campaigns/"+this.props.match.params.campaign_id);
					return false;
				}				       	                 	    
	    		oldState.message=data.message;
	    		this.setState(oldState);
	    		this.props.history.push("/campaigns/"+this.state.campaign_id+"/messages/new");	
			}).catch(error=>{
	    	//	
		});  	
    }

	render(){
		let bodyContent;
		if(this.state.create === true){
			let templates;
			if(this.state.message.templates){
				templates=this.state.message.templates.map((item, key)=>
						<Form.Group className="width_100" controlId={"template_radio_"+key} key={key}>
						    <Form.Check 
						    type="radio"
						    name="" 
						    custom 
						    label={ item.name } 
						    onChange={(e)=>this.updateTemplateSelection(key)} 
						    checked={this.state.message.templates[key].checked && this.state.message.templates[key].checked === true ? 'checked' : ''}
						    />
						</Form.Group>									      
	    			);
			}
			bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
					<h3 className="text-left">{Helpers.translate('New Message',this.state.translations.campaign,'campaign')}</h3>			
					<div className="mt-3 col-sm-5">
						<div className="row">
							<Form className="text-left width_100">						
							  	<Form.Group>
							    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Message name',this.state.translations.campaign,'campaign')}</Form.Label>
							    	<div className="row">
							    		<div className="col-sm-12">
							    			<Form.Control type="text" value={this.state.message.name || ''} 
							    			name="name"
							    			maxLength="191"
							    			placeholder={Helpers.translate('Message name',this.state.translations.campaign,'campaign')}
							    			onChange={(e)=>this.updateStateValue(e.target.value,'name','message')}
							    			autoComplete="off" 
							    			onKeyPress={(e) => Helpers.submitForm(this,e,'createMessage') } />
							    		</div>
							    	</div>			    									    	
							  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
							  	</Form.Group>
							  	<Form.Group>
							    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Template',this.state.translations.general,'general')}</Form.Label>
							    	<Form.Label className="d-block">{Helpers.translate('Select 1 template',this.state.translations.campaign,'campaign')}</Form.Label>
							    	<div>{ templates }</div>			    									    	
							  		{this.state.errors.templates && <p className="text-danger mt-1">{this.state.alertsField.templates}</p>}
							  	</Form.Group>
							  	<div className="mt-3 text-left">
									<Button onClick={()=>this.createMessage()}>{Helpers.translate('Create message',this.state.translations.campaign,'campaign')}</Button>
								</div>						  	
							</Form>
						</div>					
					</div>
				</div>
		}else{
			let tabs=this.state.message.languages.map((item, key)=>
						<Nav.Link className="nav-general" eventKey={item.language_code} key={key}>
							<span>
								<span>{item.language_code.toUpperCase()}</span>
								<Badge className="ml-2 ft-11" pill variant={ (item.status_id === 1 ? "success" : "warning") }>{ Helpers.translate(item.status_name,this.state.translations.general,'general') }</Badge>
							</span>
						</Nav.Link>	      											      
	    			);
	    	let tab_content=this.state.message.languages.map((item, key)=>
						<div id={item.language_code} role="tabpanel" aria-hidden="false" key={key} className={"fade tab-pane "+(this.state.activeTab === item.language_code ? "active show" : "")}>		      		
				      		<div className="col-md-4 col-sm-12 mt-3 message_upper_box pl-0">
				      			<Form className="text-left width_100">
					      			<Form.Group>
								    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Status',this.state.translations.general,'general')}</Form.Label>
								    	<Select options={item.statuses}
								    		value={item.status && item.status.length ? item.status : []} 
								    		className={"selectize "+(this.state.errors.status ? 'border-error' : '')}
			          						isClearable={true}
			          						isSearchable={true}
			          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
								    		getOptionLabel={option => option.name}
								    		getOptionValue={option => option.id}
								    		isOptionDisabled={option => option.is_disabled === true}
								    		onChange={(value)=>this.updateTabsValue(value,'status',key)}
								    	/>
								  	</Form.Group>
								  	<Form.Group className="hidden">
								    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Preview',this.state.translations.general,'general')}</Form.Label>
								    	<Form.Label>
								    		<Button className="ml-2" variant="link" size="sm" onClick={()=>{console.log('click')}}>
									    		<span>{Helpers.translate('Preview in browser',this.state.translations.campaign,'campaign')}</span>
									    		<i className="ml-2 fas fa-external-link-alt"></i>
									    	</Button>
								    	</Form.Label>
								  	</Form.Group>
								  	{item.preview_allowed && <Form.Group>
								    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Send preview mail',this.state.translations.campaign,'campaign')}</Form.Label>
								    	<InputGroup>					    		
								    		<InputGroup.Prepend>
											      <InputGroup.Text><i className="far fa-envelope"></i></InputGroup.Text>
											</InputGroup.Prepend>
											<Form.Control type="text" value={this.state.message.preview_email || ''} 
								    			name="name"
								    			placeholder={Helpers.translate('Email',this.state.translations.general,'general')}
								    			onChange={(e)=>this.updateStateValue(e.target.value,'preview_email','message')}
								    			autoComplete="off" />
								    		<InputGroup.Append>
											    <Button variant="outline-secondary" onClick={()=>this.sendPreviewMail(item.id)}>{Helpers.translate('Send',this.state.translations.general,'general')}</Button>
											</InputGroup.Append>
								    	</InputGroup>						    	
								  	</Form.Group>}
					      		</Form>
				      		</div>
				      		<div className="mt-3">{ this.tabComponent(item,key) }</div>		      		
				      	</div>									      
	    			);
			bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
					<div className="row">
						<div className="d-flex mt-2 width_100 steps">
							<div className="col-md-4 col-sm-12 text-left">
								<div className="back-button text-left">
									<Link to={"/campaigns/"+this.state.campaign_id}><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to messages overview',this.state.translations.campaign,'campaign')}</span></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12">
							{!this.state.tmp.edit_name && <h4 className="text-left mt-5 mt-3-mobile">
								<span>{ this.state.message.name || '' }</span>
								<Button variant="link" size="sm" className="ml-2" onClick={()=>this.updateStateValue([this.state.message.name,true],['name','edit_name'],'tmp')}>
									<i className="fas fa-pencil-alt"></i>
								</Button>
							</h4>}
							{this.state.tmp.edit_name && 
							<h4 className="text-left mt-5 mt-3-mobile">
								<Form.Group>
							    	<div className="row">
							    		<div className="col-sm-8">
							    			<InputGroup>
							    				<Form.Control type="text" value={this.state.tmp.name || ''} 
							    				name="name"
							    				maxLength="191"
							    				placeholder={Helpers.translate('Message name',this.state.translations.campaign,'campaign')}
							    				onChange={(e)=>this.updateStateValue(e.target.value,'name','tmp')}
							    				autoComplete="off" />
							    				<InputGroup.Append>
											      	<InputGroup.Text className="pointer" onClick={()=>this.updateName()}><i className="fas fa-check"></i></InputGroup.Text>
											      	<InputGroup.Text className="pointer" onClick={()=>this.updateStateValue(['',false],['name','edit_name'],'tmp')}><i className="fas fa-times"></i></InputGroup.Text>
											    </InputGroup.Append>
							    			</InputGroup>				    			
							    		</div>
							    	</div>			    									    	
							  		{this.state.errors.name && <p className="text-danger mt-1 ft-16 text-left">{this.state.alertsField.name}</p>}
							  	</Form.Group>
							</h4>
							}
						</div>
						<div className="col-sm-12 mt-2">
							<Nav className="text-left" id="pills-tab" as="nav" variant="pills" role="tablist" activeKey={this.state.activeTab} onSelect={(k) => this.renderTab(k)}>
								{tabs}
							</Nav>
						</div>										
					</div>
					<div className="tab-content">
						{tab_content}
					</div>
			</div>
		}
		return (
			<div className="content">
				{this.state.show_content && bodyContent}
		   	</div>
		);
	}

}
export default withRouter(Message);