import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default class DismissibleAlert extends React.Component{

	render(){
		let className=this.props.modal ? "alert-fixed-modal" : "alert-fixed";
		if(this.props.dismissTimeout){
			let genThis=this;
			setTimeout(function(){
				genThis.props.autoDismiss();
			},parseInt(this.props.dismissTimeout));
		}
		return (
			<Alert variant={this.props.type} className={ className } onClose={() => this.props.dismissAlert(this.props.index)} dismissible>
		        {this.props.message}
		    </Alert>
		)
	}

}