import React from 'react';
import Form from 'react-bootstrap/Form';
import Helpers from './../services/Helpers.js';
import Table from 'react-bootstrap/Table';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Select from 'react-select';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export default class TeamMembers extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	members:this.props.members,
	      	search:{
	      		page:1,
	      		order_by:this.props.members.order_by,
	      		order:this.props.members.order,
	      		search:'',
	      		language_filter:[]
	      	}     	
	  	};
	  	this.searchPromise=0;
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		oldState[parent][model]=value;
	        	if(model === 'global_check'){
	        		for(let x in this.state.members.list){
	        			oldState.members.list[x].checked=value;
	        		}
	        	}
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        this.setState(oldState);  
    }

    updateMembersCheck(value,index){
    	let oldState=Object.assign({}, this.state);
		oldState.members.list[index].checked=value;
		if(!value){
			oldState.members.global_check=false;
		}
		this.setState(oldState); 
    }

    deleteMembers(id){
    	let itemsToDelete=[];
    	if(!id){
    		let itemsSelected=false;
    		for(let x in this.state.members.list){
    			if(this.state.members.list[x].checked){
    				itemsSelected=true;
    				itemsToDelete.push({'id':this.state.members.list[x].id});
    			}
    		}
    		if(!itemsSelected){
    			this.props.showError({'errors':{'error':Helpers.translate('Select at least one item',this.props.translations.general,'general')}});
    			return false;
    		}  		
    	}else{
    		itemsToDelete.push({'id':id});
    	}
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete the selected line(s)?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let oldState=Object.assign({}, this.state);
		            oldState.errors={};
					oldState.alerts=[];	
					oldState.success={};
				    oldState.alertsField=[];
				    oldState.search.page=1;
				    let obj={};
				    obj.members=itemsToDelete;
				    obj.team_id=this.props.team_id;
				    for(let x in oldState.search){
			        	obj[x]=oldState.search[x];
			        }
				    Helpers.doRequest('post','team_members/delete',obj)
		 			.then(data=>{  		
		 				oldState.members=data.members; 
		 				this.props.updateMembersData(data.members);				       	                 	            	         	
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    statusMembers(status,id){
    	let itemsToUpdate=[];
    	if(!id){
    		let itemsSelected=false;
    		for(let x in this.state.members.list){
    			if(this.state.members.list[x].checked){
    				itemsSelected=true;
    				itemsToUpdate.push({'id':this.state.members.list[x].id,'status':(status === 1 ? 1 : 0)});
    			}
    		}
    		if(!itemsSelected){
    			this.props.showError({'errors':{'error':Helpers.translate('Select at least one item',this.props.translations.general,'general')}});
    			return false;
    		}  		
    	}else{
    		itemsToUpdate.push({'id':id,'status':(status === 1 ? 1 : 0)});
    	}
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{(status === 1 ? 
		        	Helpers.translate('Are you sure you want to deactivate the selected line(s)?',this.props.translations.general,'general') : 
		        	Helpers.translate('Are you sure you want to activate the selected line(s)?',this.props.translations.general,'general'))}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let oldState=Object.assign({}, this.state);
		            oldState.errors={};
					oldState.alerts=[];	
					oldState.success={};
				    oldState.alertsField=[];
				    let obj={};
				    obj.members=itemsToUpdate;
				    obj.team_id=this.props.team_id;
				    for(let x in oldState.search){
			        	obj[x]=oldState.search[x];
			        }
				    Helpers.doRequest('post','team_members/status',obj)
		 			.then(data=>{  		
		 				oldState.members=data.members; 
		 				this.props.updateMembersData(data.members);				       	                 	            	         	
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    loadList(value,model){
    	let oldState=Object.assign({}, this.state);
        oldState.errors={};
        oldState.alerts=[]; 
        oldState.alertsField=[];
        oldState.search[model]=value;
        let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        Helpers.doRequest('get','team_members?team_id='+this.props.team_id+urlString)
            .then(data=>{                                                                   
                oldState.members=data.members;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

    sortList(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.search.page=1;
    	if(oldState.search.order_by !== value){
    		oldState.search.order='asc';
    		oldState.search.order_by=value;  
    	}else{
    		oldState.search.order_by=value;  
    		if(oldState.search.order === 'asc'){
	    		oldState.search.order = 'desc';
	    	}else if(oldState.search.order === 'desc'){
	    		oldState.search.order = '';
	    		oldState.search.order_by='';  
	    	}else{
	    		oldState.search.order = 'asc';
	    	}
    	}   		
    	let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        Helpers.doRequest('get','team_members?team_id='+this.props.team_id+urlString)
            .then(data=>{                                                                   
                oldState.members=data.members;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

    searchList(value,model,identifier){
		let oldState=Object.assign({}, this.state);
		oldState.search[model]=value;	
		if(model !== 'page'){
			oldState.search.page=1;	
		}
		let urlString="";
        for(let x in oldState.search){
        	if(Array.isArray(oldState.search[x]) && oldState.search[x].length === 1){
        		urlString+="&"+x+"="+oldState.search[x][0][identifier];
        	}else{
        		urlString+="&"+x+"="+oldState.search[x];
        	}     	
        }
        this.setState(oldState);
		if(this.searchPromise){
			clearTimeout(this.searchPromise);
		}
		this.searchPromise=setTimeout(()=>{   
            Helpers.doRequest('get','team_members?team_id='+this.props.team_id+urlString)
	            .then(data=>{                                                                   
	                oldState.members=data.members;
	                this.setState(oldState);
	            }).catch(error=>{
	                //  
	            });
	    },500);
	}

    render(){
    	const membersContent=this.state.members.list.map((item, key) =>
		        <tr key={key}>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.email }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.first_name }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.last_name }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.language_name }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.trainings_active }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ item.trainings_done }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ new moment(Helpers.adjustDateTimezone(new Date(item.created_at))).format("DD/MM/YYYY HH:mm") }</td>
		        	<td onClick={()=>this.props.editMember(item.id)}>{ new moment(Helpers.adjustDateTimezone(new Date(item.updated_at))).format("DD/MM/YYYY HH:mm") }</td>
		        	<td className="text-center checkbox">
		        		<Form.Group controlId={"member_check_"+key} key={key}>
						    <Form.Check 
						    	type="checkbox" 
						    	custom 
						    	label="" 
						    	onChange={(e)=>this.updateMembersCheck(e.target.checked,key)} 
						    	checked={item.checked && item.checked === true ? 'checked' : ''}
						    />
						</Form.Group>
		        	</td>
		        	<td className="text-right">
		        		{(item.is_archived ? <a href="#" className="dropdown-item" onClick={(event)=>this.statusMembers(null,item.id)}><i className="fas fa-bell-slash"></i></a> 
		        		: <a href="#" className="dropdown-item" onClick={(event)=>this.statusMembers(1,item.id)}><i className="fas fa-bell"></i></a>)}
		        		<a href="#" className="dropdown-item" onClick={(event)=>this.props.editMember(item.id)}><i className="fas fa-pen"></i></a>
		        		<a href="#" className="dropdown-item" onClick={(event)=>this.deleteMembers(item.id)}><i className="fas fa-times-circle"></i></a>
		        	</td>
		        </tr>
		    ); 
    	let membersPagination;
	    if(this.state.members.page_count>1){
	    	let forcePageObj = {}
			if (this.state.search.page === 1) {
			    forcePageObj['forcePage'] = 0;
			}
    		membersPagination=<ReactPaginate
				        previousLabel={'<'}
				        nextLabel={'>'}
				        breakLabel={'...'}
				        pageCount={this.state.members.page_count}
				        marginPagesDisplayed={2}
				        pageRangeDisplayed={5}	
				        {...forcePageObj}			        
				        onPageChange={(data)=>this.loadList(parseInt(data.selected)+1,'page')}
				        containerClassName={'pagination pagination-sm'}
				        pageClassName={'page-item'}
				        pageLinkClassName={'page-link'}
				        previousClassName={'page-item'}
				        nextClassName={'page-item'}
				        previousLinkClassName={'page-link'}
				        nextLinkClassName={'page-link'}
				        activeClassName={'active'} />
		}
    	return (
    		<div>
    			<div className="row">
					<div className="col-sm-12 col-md-8 col-lg-8">
		    			<Form.Control type="text" value={this.state.search.search} 
		    			name="name"
		    			placeholder={Helpers.translate('Search',this.props.translations.general,'general')}
		    			onChange={(e)=>this.searchList(e.target.value,'search')}
		    			autoComplete="off" />
		    			{this.state.search.search.length>0 && 
		    				<i className="fas fa-backspace search_remove" onClick={()=>this.searchList("",'search')}></i>
		    			}
		    		</div>
		    		<div className="col-sm-12 col-md-4 col-lg-4">
		    			<Select options={this.props.languages || []}
				    		value={this.state.search.language_filter && this.state.search.language_filter.length ? this.state.search.language_filter : []} 
				    		className="selectize"
      						isClearable={true}
      						isSearchable={false}
      						placeholder={Helpers.translate('Select',this.props.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.code}
				    		onChange={(value)=>this.searchList((value !== null ? [value]: value),'language_filter','code')}
				    	/>
		    		</div>
				</div>
				<Table responsive="sm" size="sm" className="text-left active_rows">
		  			<thead>
				      <tr>
				        <th className="pointer" onClick={()=>this.sortList("email")}>
				        	<span>{Helpers.translate('Email',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'email' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="pointer" onClick={()=>this.sortList("first_name")}>
				        	<span>{Helpers.translate('First name',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'first_name' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="pointer" onClick={()=>this.sortList("last_name")}>
				        	<span>{Helpers.translate('Last name',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'last_name' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th>{Helpers.translate('Language',this.props.translations.general,'general')}</th>
				        <th>
				        	<span>{Helpers.translate('Trainings active',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'trainings_active' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th>
				        	<span>{Helpers.translate('Trainings done',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'trainings_done' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="pointer" onClick={()=>this.sortList("created_at")}>
				        	<span>{Helpers.translate('Added',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'created_at' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="pointer" onClick={()=>this.sortList("updated_at")}>
				        	<span>{Helpers.translate('Last modified',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'updated_at' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="text-center checkbox">
				        	<Form.Group className="mb-0" controlId="member_global_check">
					        	<Form.Check 
								    type="checkbox" 
								    custom 
								    label=""
								    onChange={(e)=>this.updateStateValue(e.target.checked,'global_check','members')} 
								    checked={this.state.members.global_check && this.state.members.global_check === true ? 'checked' : ''}
								/>
							</Form.Group>
				    	</th>
				        <th className="text-right">
				        	<DropdownButton alignRight variant="success" size="sm"
								title=''>
								<Dropdown.Item className="w-100 text-left" onClick={(event)=>this.statusMembers(null)}>{Helpers.translate('Activate',this.props.translations.general,'general')}</Dropdown.Item>
								<Dropdown.Item className="w-100 text-left" onClick={(event)=>this.statusMembers(1)}>{Helpers.translate('Deactivate',this.props.translations.general,'general')}</Dropdown.Item>
								<Dropdown.Item className="w-100 text-left" onClick={(event)=>this.deleteMembers()}>{Helpers.translate('Delete',this.props.translations.general,'general')}</Dropdown.Item>
							</DropdownButton>
				        </th>
				      </tr>
				    </thead>		    
				    <tbody>{ membersContent }</tbody>
		  		</Table>
		  		<div className="clearfix text-center">{ membersPagination }</div>
		   	</div>
    	);
    }

}