import React from 'react';
import Chart from "chart.js";

let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);
    
    let chart = this.chart.chart;
    let ctx = chart.ctx;
    let width = chart.width;
    let height = chart.height;

    let fontSize = ((height - chart.chartArea.top) / 100).toFixed(2);
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    let text = chart.config.data.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = (height + chart.chartArea.top) / 2;

    ctx.fillText(text, textX, textY);
  }
});

export default class DoughnutChart extends React.Component{

	constructor(props) {
		super(props);
		this.chartRef=React.createRef();  
	}

	componentDidMount() {
        this.generateChart(false);
    }

    componentDidUpdate() {
        this.generateChart(false);
    }

    generateChart(update){
    	let dChartRef = this.chartRef.current.getContext("2d");

        if(update){
        	dChartRef.data=this.props.data;
        	dChartRef.update();
        }else{
        	new Chart(dChartRef, {
	            type: "doughnut",
	            data: this.props.data,
	            options: {
	            	responsive: true,
	            	aspectRatio:this.props.aspect_ratio,
	            	cutoutPercentage:(this.props.cutout ? this.props.cutout : 50),
	            	title: {
			            display: true,
			            text: this.props.title,
			            fontSize:this.props.fontSize
			        },
			        tooltips: {
            			enabled: this.props.tooltip_enabled
	            	},
	              	legend: {
	                	display: this.props.display_legend
	              	}
	            }
	        });
        }      
    }

	render() {
		return (
			<canvas ref={this.chartRef}></canvas>
		);
	}

}