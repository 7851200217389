import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormValidation from './../../services/FormValidation.js';
import InputGroup from 'react-bootstrap/InputGroup';
import TeamMembers from './../../components/TeamMembers.js';
import Badge from 'react-bootstrap/Badge';
import Dropzone from 'react-dropzone';
import Store from './../../services/Store.js';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';

class Team extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		show_content:false,
	  		create:true,
	  		team:{},
	  		team_id:null,
	  		tmp:{
	  			edit_data:false,
	  			name:'',
	  			language:null,
	  			description:''
	  		},
	  		import_csv:false,
	  		import_step:1,
	  		import_step_1_next:false,
	  		import_step_2_next:false,
	  		import_data:{}
	  	};
	  	this.moduleName='team';
	  	this.team_validations={
	  		"name":["required","min:3"]
	  	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','teams/'+this.props.match.params.id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.create=false;
		            	oldState.show_content=true;
		            	oldState.team=data.team;
		            	oldState.team_id=data.team.team_id;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				                if(this.props.location.state && this.props.location.state.msg){
				                	let msg=Object.assign({}, this.props.location.state.msg);
				                	window.history.pushState(null, '');
				                	let genThis=this;
				                	setTimeout(function(){		                		                		
				                		Helpers.showAlerts(genThis,msg);				                					           
					            	},200);
				                }
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}else{ 	
				if(!this.props.permissions.team.full_control){
					this.props.history.push("/teams");
					return false;
				}
				Helpers.doRequest('get','teams/create')
		 			.then(data=>{  	
		 				if(!data.team.allow_create){
		 					this.props.history.push("/teams");
							return false;
		 				}	 				       	                 	            	
		            	oldState.show_content=true;
		            	oldState.team=data.team;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}			
		}
	}

    createTeam(){
		let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.team_validations,this.state.team,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.team);
		if(this.state.team.language && this.state.team.language.length){
			obj.language=this.state.team.language[0].code;
		}
		delete obj.languages;
		Helpers.doRequest('post','teams',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.team=data.team;
            	oldState.team_id=data.team.team_id;
            	oldState.create=false;
            	this.setState(oldState);
            	this.props.history.push("/teams/"+data.team.team_id)
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	updateTeam(){
		let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.team_validations,this.state.tmp,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.tmp);
		if(obj.language && obj.language.length){
			obj.language=obj.language[0].code;
		}
		Helpers.doRequest('put','teams/'+this.state.team_id,obj)
	        .then(data=>{
	        	let oldState=Object.assign({},this.state);
            	oldState.team.name=oldState.tmp.name;
            	oldState.team.language=oldState.tmp.language;
            	oldState.team.description=oldState.tmp.description;

            	oldState.tmp.name='';
            	oldState.tmp.language=null;
            	oldState.tmp.description='';
            	oldState.tmp.edit_data=false;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
	        }).catch(error=>{
	        	Helpers.showAlerts(this,error,true);
	        });
	}

	onCsvDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
			if(acceptedFiles.length){
				if(parseInt(acceptedFiles[0].size/1000) > parseInt(this.state.team.import_max_size/1000)){
	    			let errorMsg={'errors':{'error':Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')+" "+this.state.team.import_max_size/1000+"Kb"}};
	    			Helpers.showAlerts(this,errorMsg);
	    			return false;
	    		}
	    		let oldState=Object.assign({},this.state);
	    		oldState.import_data.file=acceptedFiles[0];
	    		oldState.import_step_1_next=true;
	    		this.setState(oldState);    
			}
		}
	}

	previewMatch(){
		let genThis=this;
		let formData = new FormData();
		formData.append("team_id", this.state.team_id);
		formData.append("file", this.state.import_data.file);
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'teams/import_step_one', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
	        	let oldState=Object.assign({}, genThis.state);
	   	     	oldState.import_data=data.import_data;
	   	     	oldState.import_step=2;
	   	     	oldState.import_step_1_next=false;
	   	     	oldState.import_step_2_next=false;
            	genThis.setState(oldState);            	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
	}

	updateMatchData(value,index){
		let oldState=Object.assign({},this.state);
		if(value !== null){
    		oldState.import_data.preview[index].match_field=[];
    		oldState.import_data.preview[index].match_field.push(value);
    	}else{
    		oldState.import_data.preview[index].match_field=value;
    	}
    	let import_step_2_next=false;
    	for(let x in oldState.import_data.preview){
    		if(oldState.import_data.preview[x].match_field && oldState.import_data.preview[x].match_field !== null && oldState.import_data.preview[x].match_field[0].id === 'email'){
    			import_step_2_next=true;
    			break;
    		}
    	}
    	oldState.import_step_2_next=import_step_2_next;
		this.setState(oldState);
	}

	finalizeImport(){
		let obj={'fields':[]};
		obj.team_id=this.state.team_id;
		obj.file_name=this.state.import_data.file_name;
		obj.delimiter=this.state.import_data.delimiter;
		for(let x in this.state.import_data.preview){
			if(this.state.import_data.preview[x].match_field !== null && Array.isArray(this.state.import_data.preview[x].match_field) && this.state.import_data.preview[x].match_field.length>0){
				obj.fields.push(this.state.import_data.preview[x].match_field[0].id);
			}else{
				obj.fields.push('');
			}
		}
		obj.update_members=this.state.import_data.update_members ? '1' : '0';
		Helpers.doRequest('post','teams/import_step_two',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.import_data=data.import_data;
            	oldState.import_step_2_next=false;
			   	oldState.import_step=3;
		        this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error);
            });
	}

	reloadTeam(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','teams/'+this.state.team_id+'/edit')
 			.then(data=>{  		
 				oldState.import_step=1; 
 				oldState.import_data={};				       	                 	            	
            	oldState.import_csv=false;
            	oldState.team=data.team;
            	this.setState(oldState);
 			}).catch(error=>{
            	//	
        	});
	}

	render(){
	    let bodyContent;
	    if(this.state.create === true){
	    	bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
	    			<div className="back-button text-left">
						<Link to="/teams"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to Teams',this.state.translations.team,'team')}</span></Link>
					</div>
					<h4 className="text-left mt-5 mt-3-mobile">{Helpers.translate('New team',this.state.translations.team,'team')}</h4>			
					<div className="mt-3 col-sm-5">
						<div className="row">
							<Form className="text-left width_100">						
							  	<Form.Group>
							    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Team name',this.state.translations.team,'team')}</Form.Label>
							    	<Form.Label className="d-block">{Helpers.translate('Choose your team name',this.state.translations.team,'team')}</Form.Label>
							    	<div className="row">
							    		<div className="col-sm-12">
							    			<Form.Control type="text" value={this.state.team.name || ''} 
							    			name="name"
							    			placeholder={Helpers.translate('Team name',this.state.translations.team,'team')}
							    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'name','team')}
							    			autoComplete="off" 
							    			onKeyPress={(e) => Helpers.submitForm(this,e,'createTeam') } />
							    		</div>
							    	</div>			    									    	
							  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
							  	</Form.Group>
							  	<Form.Group>
							    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Language',this.state.translations.general,'general')}</Form.Label>
									<Select options={this.state.team.languages}
							    		value={this.state.team.language && this.state.team.language.length ? this.state.team.language : []} 
							    		className="selectize"
			      						isClearable={false}
			      						isSearchable={false}
			      						placeholder={Helpers.translate('Language',this.state.translations.general,'general')}
							    		getOptionLabel={option => option.name}
							    		getOptionValue={option => option.code}
							    		onChange={(value)=>Helpers.updateStateValue(this,(value !== null ? [value] : value),'language','team')}
							    	/>
							  	</Form.Group>
							  	<Form.Group>
					    			<Form.Control as="textarea" rows="3" 
					    				placeholder={Helpers.translate('Description',this.state.translations.general,'general')}
					    				value={ this.state.team.description || ''} 
							    		onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'description','team')}/>				  				    	
								</Form.Group>
							  	<div className="mt-3 text-left">
									<Button className="btn-general" onClick={()=>this.createTeam()}>{Helpers.translate('Create team',this.state.translations.team,'team')}</Button>
								</div>						  	
							</Form>
						</div>					
					</div>
				</div>
	    }else{
	    	let importContent;
	    	if(this.state.import_csv && this.state.import_step === 1){
	    		importContent=<div className="mt-4">
	    			<h5 className="text-left font-weight-bold mb-3">{Helpers.translate('Import from CSV file',this.state.translations.general,'general')}</h5>
	    			<div>
    					<Form.Group className="text-left">
    						<div className="col-sm-8">
    							<Dropzone onDrop={(acceptedFiles)=>this.onCsvDrop(acceptedFiles)} accept={'.csv'} multiple={false}>
								  {({getRootProps, getInputProps}) => (
								    <section className="row">
								      <div {...getRootProps()}>
								        <input {...getInputProps()} />
								        <InputGroup>
						    				<Form.Control className="pointer" type="text" value={(this.state.import_data.file && this.state.import_data.file.name ? this.state.import_data.file.name : '')}
						    				name="name"
						    				maxLength="191"
						    				placeholder=''
						    				onChange={(e)=>()=>{}}
						    				autoComplete="off" 
						    				readOnly />
						    				<InputGroup.Append>
										      	<InputGroup.Text className="pointer">{Helpers.translate('Browse',this.state.translations.general,'general')}</InputGroup.Text>
										    </InputGroup.Append>
						    			</InputGroup>
								      </div>
								    </section>
								  )}
								</Dropzone>	
    						</div>
					    	<div className="mt-2">
					    		<span>{Helpers.translate('Acceptable file types: CSV',this.state.translations.general,'general')}</span>
					    	</div>				    	
					  	</Form.Group>
	    			</div>
	    			<h3 className="mt-5">
						<p className="d-inline-block float-left">
							<Button className="ft-14" variant="secondary" onClick={()=>Helpers.updateStateValue(this,[false,{}],['import_csv','import_data'])}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</Button>
						</p>
						<Button className="float-right ft-14" disabled={!this.state.import_step_1_next} onClick={()=>this.previewMatch()}>
							<span>{Helpers.translate('Continue',this.state.translations.general,'general')}</span>		
						</Button>							
					</h3>
	    		</div>
	    	}else if(this.state.import_csv && this.state.import_step === 2){
	    		let tableContent;
			    if(this.state.import_data.preview){
		    		tableContent=this.state.import_data.preview.map((item, key) =>
		    			<tr key={key}>
				        	<td>
				        		{ item.list.map((subitem,subkey) => 
	        							<p key={subkey}>{subitem}</p>
	        						)
	        					}
				        	</td>		        			      
				        	<td>
				        		<Select options={this.state.import_data.match_fields}
						    		value={item.match_field && item.match_field.length ? item.match_field : []} 
						    		className="selectize"
	          						isClearable={true}
	          						isSearchable={true}
	          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateMatchData(value,key)}
						    	/>
				        	</td>
				        </tr>
				    );
		    	}
	    		importContent=<div className="mt-4">
	    			<h5 className="text-left font-weight-bold mb-3">{Helpers.translate('Match import data',this.state.translations.general,'general')}</h5>  			
	    			<Table responsive="sm" size="sm" className="text-left active_rows">
			  			<thead>
					      <tr className="table-secondary">
					        <th>{Helpers.translate('Data',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('Match fields',this.state.translations.general,'general')}</th>
					      </tr>
					    </thead>
					    <tbody>
					    	{ tableContent }
					    </tbody>
			  		</Table>
			  		<Form className="mt-2 text-left width_100">	
	    				<Form.Group controlId="update_members">
					    	<Form.Check 
							    type="checkbox" 
							    custom 
							    label={Helpers.translate("Update existing team members",this.state.translations.team,'team')} 
							    onChange={(e)=>Helpers.updateStateValue(this,e.target.checked,'update_members','import_data')} 
							    checked={this.state.import_data.update_members && this.state.import_data.update_members === true ? 'checked' : ''}						   
						    />
					  	</Form.Group>
					 </Form>
			  		<h3 className="mt-5">
						<p className="d-inline-block float-left">
							<Button className="ft-14" variant="secondary" onClick={()=>Helpers.updateStateValue(this,[false,{}],['import_csv','import_data'])}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</Button>
						</p>
						<Button className="float-right ft-14" disabled={!this.state.import_step_2_next} onClick={()=>this.finalizeImport()}>
							<span>{Helpers.translate('Continue',this.state.translations.general,'general')}</span>	
						</Button>							
					</h3>
	    		</div>
	    	}else if(this.state.import_csv && this.state.import_step === 3){
	    		importContent=<div className="mt-4">
	    			<div className="text-left">
					  	<h4 className="mb-2">
					  		<span>{this.state.import_data.added} {Helpers.translate('Team members',this.state.translations.general,'general')} {Helpers.translate('added',this.state.translations.general,'general')}</span>				  		
					  	</h4>
					  	<h4>
					  		<span> {this.state.import_data.updated} {Helpers.translate('Team members',this.state.translations.general,'general')} {Helpers.translate('updated',this.state.translations.general,'general')}</span>				  		
					  	</h4>
					  	{
				  			(this.state.import_data.errors.length>0 ? 
				  				this.state.import_data.errors.map((item,key) => 
        							<p className="text-danger" key={key}>{item}</p>
        						)
				  			: null)
				  		}
					  	<p>
					    	<Button className="ft-4 btn-general mt-5" variant="primary" onClick={()=>this.reloadTeam()}>{Helpers.translate('Back to list',this.state.translations.general,'general')}</Button>
					  	</p>
					</div>
	    		</div>
	    	}
	    	bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
	    			<div className="back-button text-left">
						<Link to="/teams"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to Teams',this.state.translations.team,'team')}</span></Link>
					</div>
					{!this.state.tmp.edit_data && <h4 className="text-left mt-5 mt-3-mobile">
						<span>{ this.state.team.name || '' }</span>
						{!this.state.import_csv && this.props.permissions.team.full_control && <Button className="ml-2" variant="link" size="sm" onClick={()=>Helpers.updateStateValue(this,[this.state.team.name,this.state.team.language,this.state.team.description,true],['name','language','description','edit_data'],'tmp')}>
				    		<i className="fas fa-pencil-alt"></i>
				    	</Button>}
					</h4>}
					{this.state.tmp.edit_data && 
					<div className="mt-5 col-sm-5">
						<div className="row">
							<Form className="text-left width_100">	
								<Form.Group>
				    				<Form.Control type="text" value={this.state.tmp.name || ''} 
				    				name="name"
				    				maxLength="191"
				    				placeholder={Helpers.translate('Team name',this.state.translations.team,'team')}
				    				onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'name','tmp')}
				    				autoComplete="off" />				    						    									    	
							  		{this.state.errors.name && <p className="text-danger mt-1 text-left">{this.state.alertsField.name}</p>}
							  	</Form.Group>
							  	<Form.Group>
									<Select options={this.state.team.languages}
							    		value={this.state.tmp.language && this.state.tmp.language.length ? this.state.tmp.language : []} 
							    		className="selectize"
			      						isClearable={false}
			      						isSearchable={false}
			      						placeholder={Helpers.translate('Language',this.state.translations.general,'general')}
							    		getOptionLabel={option => option.name}
							    		getOptionValue={option => option.code}
							    		onChange={(value)=>Helpers.updateStateValue(this,(value !== null ? [value] : value),'language','tmp')}
							    	/>
							  	</Form.Group>
							  	<Form.Group>
					    			<Form.Control as="textarea" rows="3" 
					    				placeholder={Helpers.translate('Description',this.state.translations.general,'general')}
					    				value={ this.state.tmp.description || ''} 
							    		onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'description','tmp')}/>				  				    	
								</Form.Group>
							  	<div className="mt-3 text-right">
							  		<Button variant="light" onClick={()=>Helpers.updateStateValue(this,['',null,'',false],['name','language','description','edit_data'],'tmp')}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</Button>
									<Button className="btn-general ml-1" onClick={()=>this.updateTeam()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
								</div>
						  	</Form>
					  	</div>
					</div>}
					{!this.state.import_csv && <div className="mt-1 text-left">
			  			<span>{ (this.state.team.members && this.state.team.members.list ? this.state.team.members.list.length : 0) }</span>
			  			<span className="ml-1">{Helpers.translate('members',this.state.translations.general,'general')}</span>
					</div>}
					{!this.state.import_csv && this.state.team.members && this.state.team.members.allow_create && <div className="mt-3 text-left">
			  			<Button className="btn-general mb-3" onClick={()=>this.props.history.push("/teams/"+this.state.team_id+"/members/new")}>{Helpers.translate('Add new member',this.state.translations.team,'team')}</Button>	
			  			<Button className="ml-2 mb-3 btn-yellow" onClick={()=>Helpers.updateStateValue(this,[true,1,false],['import_csv','import_step','import_step_1_next'])}>{Helpers.translate('Import from CSV file',this.state.translations.general,'general')}</Button>				    						  								    						  								
					</div>}
					{this.state.team.members && !this.state.team.members.allow_create && <div className="mt-3 text-left">
			  			<p className="mt-2">{Helpers.translate('Maximum number of team members reached',this.state.translations.team,'team')}</p>				  								
					</div>}
					{!this.state.import_csv && <div className="text-left">
						<TeamMembers
							team_id={this.state.team_id} 
		    				members={this.state.team.members} 
		    				languages={this.state.team.languages}
		    				translations={this.state.translations} 
		    				showError={(data)=>Helpers.showAlerts(this,data)}
		    				editMember={(id)=>this.props.history.push("/teams/"+this.state.team_id+"/members/"+id)}
		    				updateMembersData={(data)=>Helpers.updateStateValue(this,data,'members','team')}
		    				/>						
					</div>}
					{this.state.import_csv && 
						<div className="mt-2">
							<div className="d-flex width_100 steps">
								<div className="col-md-4 offset-md-4 col-sm-12 text-center">
									<div className="row">
										<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center "+(this.state.import_step===1 ? "active" : "")}>
											<div className="setup_card d-block">
												<Badge pill variant="light">1</Badge>
											</div>
											<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Select file',this.state.translations.general,'general')}</span>
										</div>
										<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center "+(this.state.import_step===2 ? "active" : "")}>
											<div className="setup_card d-block">
												<Badge pill variant="light">2</Badge>
											</div>
											<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Match data',this.state.translations.general,'general')}</span>
										</div>
										<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center "+(this.state.import_step===3 ? "active" : "")}>
											<div className="setup_card d-block">
												<Badge pill variant="light">3</Badge>
											</div>
											<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Report',this.state.translations.general,'general')}</span>
										</div>
									</div>
								</div>						
							</div>
							{importContent}
						</div>
					}
				</div>
	    }
		return (
			<div className="content">
				{this.state.show_content && bodyContent}
		   	</div>
		);
	}

}
export default withRouter(Team);