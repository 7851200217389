import React from 'react';
import Button from 'react-bootstrap/Button';
import Helpers from './../../services/Helpers.js';
import CardDeck from 'react-bootstrap/CardDeck';
import Badge from 'react-bootstrap/Badge';
import CampaignCard from './../../components/CampaignCard.js';
import Table from 'react-bootstrap/Table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from './../../components/DatePickerInput.js';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DoughnutChart from './../../components/DoughnutChart.js';
import TrainingMessages from './../../components/TrainingMessages.js';
import Select from 'react-select';
import { withRouter,Link } from 'react-router-dom';
import FormValidation from './../../services/FormValidation.js';

class MicroTraining extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		show_content:false,
	  		create:true,
	  		step:1,
	  		step_1_next:false,
	  		step_2_next:false,
	  		step_3_next:false,
	  		training:{
	  			campaigns:{},
	  			teams:{},
	  			date:{}
	  		},
	  		detail:{},
	  		training_id:null,
	  		team_search:{
	      		order_by:"name",
	      		order:"asc",
	      		team_id:""
	      	},
	      	activeTab:"status",
	      	messages:{},
	      	campaign_search:{
	      		page:1,
	      		search:"",
	      		filter_by:"all",
	      		order_by:"publication_date"
	      	},
	  	};
	  	this.moduleName='training';
	  	this.searchPromise=0;
	  	this.training_create_validations={
	  		"start_date":["required"]
	  	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','trainings/'+this.props.match.params.id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.create=false;
		            	oldState.show_content=true;
		            	oldState.detail=data.training;
		            	oldState.training_id=this.props.match.params.id;
		            	if(data.training.data.status === 0){
		            		oldState.detail.data.start_date=Helpers.adjustDateTimezone(new Date(data.training.data.start_date));
			            	let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);
			            	if(Math.round(oldState.detail.data.start_date.getTime()/1000)>currentDayMaxDate){
			            		oldState.detail.data.min_time=null;
								oldState.detail.data.max_time=null;
			            	}else{
			            		oldState.detail.data.min_time=new Date();
								if(oldState.detail.data.min_time.getMinutes()>30){
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
								}else{
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
								}
								oldState.detail.data.max_time=(new Date()).setHours(23,30);
			            	}		            	
		            	}else if(data.training.data.status === 3){
		            		oldState.detail.data.next_date=Helpers.adjustDateTimezone(new Date(data.training.data.next_date));
		            		let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);
			            	if(Math.round(oldState.detail.data.next_date.getTime()/1000)>currentDayMaxDate){
			            		oldState.detail.data.min_time=null;
								oldState.detail.data.max_time=null;
			            	}else{
			            		oldState.detail.data.min_time=new Date();
								if(oldState.detail.data.min_time.getMinutes()>30){
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
								}else{
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
								}
								oldState.detail.data.max_time=(new Date()).setHours(23,30);
			            	}
		            	}	            	
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				                let genThis=this;
				                setTimeout(function(){
				            		genThis.renderTab('messages');
				            	},200);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            	let genThis=this;
				            	setTimeout(function(){
				            		genThis.renderTab('messages');
				            	},200);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}else{ 
				if(!this.props.permissions.microtraining.full_control){
					this.props.history.push("/trainings");
					return false;
				}
				let urlString="";
		        for(let x in oldState.campaign_search){
		        	urlString+="&"+x+"="+oldState.campaign_search[x];
		        }
		        urlString=urlString.replace("&", "?");			
				Helpers.doRequest('get','trainings/step_one'+urlString)
		 			.then(data=>{  	
		 				if(!data.campaigns.allow_create){
		 					this.props.history.push("/trainings");
							return false;
		 				}	 				       	                 	            	
		            	oldState.show_content=true;
		            	oldState.training.campaigns=data.campaigns;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}			
		}
	}

	loadCampaigns(value,model,append){
		let step_1_next=false;
		let oldState=Object.assign({}, this.state);
		oldState.campaign_search[model]=value;	
		if(model !== 'page'){
			oldState.campaign_search.page=1;	
		}  		
    	let urlString="";
        for(let x in oldState.campaign_search){
        	if(Array.isArray(oldState.campaign_search[x]) && oldState.campaign_search[x].length === 1){
        		urlString+="&"+x+"="+oldState.campaign_search[x][0].id;
        	}else{
        		urlString+="&"+x+"="+oldState.campaign_search[x];
        	}     	
        }
        urlString=urlString.replace("&", "?");	
		if(model === 'search'){
			this.setState(oldState);
			if(this.searchPromise){
				clearTimeout(this.searchPromise);
			}
			this.searchPromise=setTimeout(()=>{   
	            Helpers.doRequest('get','trainings/step_one'+urlString)
		            .then(data=>{
		            	if(append){
		            		for(let x in data.campaigns.list){
		            			oldState.training.campaigns.list.push(data.campaigns.list[x]);
		            		}
		            		oldState.training.campaigns.show_more=data.campaigns.show_more;
		            	}else{
		            		oldState.training.campaigns=data.campaigns;
		            	}
		            	for(let x in oldState.training.campaigns.list){
		            		if(oldState.training.campaigns.list[x].selected){
		            			step_1_next=true;
		            			break;
		            		}
						} 
						oldState.step_1_next=step_1_next;                                                      		                
		                this.setState(oldState);
		            }).catch(error=>{
		                //  
		            });
		    },500);
		}else{
			Helpers.doRequest('get','trainings/step_one'+urlString)
	            .then(data=>{                                                                   
	                if(append){
	            		for(let x in data.campaigns.list){
	            			oldState.training.campaigns.list.push(data.campaigns.list[x]);
	            		}
	            		oldState.training.campaigns.show_more=data.campaigns.show_more;
	            	}else{
	            		oldState.training.campaigns=data.campaigns;
	            	}
	            	for(let x in oldState.training.campaigns.list){
	            		if(oldState.training.campaigns.list[x].selected){
	            			step_1_next=true;
	            			break;
	            		}
					} 
					oldState.step_1_next=step_1_next;
	                this.setState(oldState);
	            }).catch(error=>{
	                //  
	            });
		}	      
	}

	selectCampaign(index){
		let step_1_next=false;
		let oldState=Object.assign({}, this.state);
		let currentSelectedState=oldState.training.campaigns.list[index].selected;
		for(let x in oldState.training.campaigns.list){
			oldState.training.campaigns.list[x].selected=false;
		}
		oldState.training.campaigns.list[index].selected=!currentSelectedState;
		if(oldState.training.campaigns.list[index].selected){
			step_1_next=true;
		}
		oldState.step_1_next=step_1_next;
		this.setState(oldState);
	}

	goToStepTwo(){
		this.sortTeamList();
	}

	sortTeamList(value){
		let oldState=Object.assign({}, this.state);
    	if(value){
    		if(oldState.team_search.order_by !== value){
	    		oldState.team_search.order='asc';
	    		oldState.team_search.order_by=value;  
	    	}else{
	    		oldState.team_search.order_by=value;  
	    		if(oldState.team_search.order === 'asc'){
		    		oldState.team_search.order = 'desc';
		    	}else if(oldState.team_search.order === 'desc'){
		    		oldState.team_search.order = '';
		    		oldState.team_search.order_by='';  
		    	}else{
		    		oldState.team_search.order = 'asc';
		    	}
	    	} 
    	} 	  		
    	let urlString="";
        for(let x in oldState.team_search){
        	urlString+="&"+x+"="+oldState.team_search[x];
        }
        urlString=urlString.replace("&", "?");
        Helpers.doRequest('get','trainings/step_two'+urlString)
            .then(data=>{                                                                   
                oldState.training.teams=data.teams;
                oldState.step=2;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
	}

	selectTeam(index){
		let oldState=Object.assign({}, this.state);
		for(let x in oldState.training.teams.list){
			if(parseInt(x) === parseInt(index)){
				oldState.training.teams.list[x].selected=true;
				oldState.team_search.team_id=oldState.training.teams.list[x].id;
			}else{
				oldState.training.teams.list[x].selected=false;
			}		
		}
		oldState.step_2_next=true;
		this.setState(oldState);
	}

	goToStepThree(){
		let frequency_name="";
		let campaign_language_id;
		for(let x in this.state.training.campaigns.list){
			if(this.state.training.campaigns.list[x].selected){
				frequency_name=this.state.training.campaigns.list[x].frequency_name;
				campaign_language_id=this.state.training.campaigns.list[x].id;
				break;
			}
		}
		if(!frequency_name){
			return false;
		}
		let oldState=Object.assign({}, this.state);
		oldState.step=3;
		oldState.training.date.frequency_name=Helpers.translate(frequency_name,this.state.translations.training,'training').toLowerCase();
		oldState.training.date.min_time=new Date();
		if(oldState.training.date.min_time.getMinutes()>30){
			oldState.training.date.min_time.setHours((oldState.training.date.min_time.getHours())+1,0);
		}else{
			oldState.training.date.min_time.setHours((oldState.training.date.min_time.getHours()),30);
		}
		oldState.training.date.max_time=(new Date()).setHours(23,30);
		Helpers.doRequest('get','trainings/step_three?campaign_language_id='+campaign_language_id)
            .then(data=>{  
            	oldState.training.date.frequency=data.date.frequency;
            	oldState.training.date.frequencies=data.date.frequencies;
            	oldState.training.date.mesages_number=data.date.mesages_number;                                                                     
                oldState.training.date.types=data.date.types;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });	
	}

	setStartDate(date){
		let oldState=Object.assign({}, this.state);
		oldState.errors={};
		let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);		
		if(Math.round(date.getTime()/1000)>currentDayMaxDate){
			oldState.training.date.min_time=null;
			oldState.training.date.max_time=null;
			oldState.training.date.start_date=date;
		}else{
			oldState.training.date.min_time=new Date();
			if(oldState.training.date.min_time.getMinutes()>30){
				oldState.training.date.min_time.setHours((oldState.training.date.min_time.getHours())+1,0);
			}else{
				oldState.training.date.min_time.setHours((oldState.training.date.min_time.getHours()),30);
			}
			oldState.training.date.max_time=(new Date()).setHours(23,30);
			oldState.training.date.start_date=(date.getTime()>oldState.training.date.min_time.getTime() ? date : oldState.training.date.min_time);
		}
		let step_3_next=false;
		for(let x in oldState.training.date.types){
			if(oldState.training.date.types[x].checked){
				step_3_next=true;
			}
			if(oldState.training.date.types[x].id === 1){
				let last_date_to_run=new Date(date.getTime());
				let iteration=1;
				switch(oldState.training.date.frequency[0].id){
					case 1:				
						while(iteration<oldState.training.date.mesages_number){
							last_date_to_run.setDate(last_date_to_run.getDate()+7);
							iteration++;
						}
						break;
					default:
						while(iteration<oldState.training.date.mesages_number){
							last_date_to_run.setDate(last_date_to_run.getDate()+1);
							iteration++;
						}
						break;
				}	
				oldState.training.date.types[x].description=Helpers.translate('All team members will receive the same message at the same time. The training will end when the last message will be sent on',this.state.translations.general,'general')+" "+new moment(last_date_to_run).format("DD/MM/YYYY");
			}
		}
		oldState.step_3_next=step_3_next;
		this.setState(oldState);
	} 

	createTraining(){
		let fieldTranslations={
    		"start_date":Helpers.translate('Start date',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.training_create_validations,this.state.training.date,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let campaign_language_id=0;
		for(let x in this.state.training.campaigns.list){
			if(this.state.training.campaigns.list[x].selected){
				campaign_language_id=this.state.training.campaigns.list[x].id;
				break;
			}
		}
		if(!campaign_language_id){
			return false;
		}
		let team_id=0;
		for(let x in this.state.training.teams.list){
			if(this.state.training.teams.list[x].selected){
				team_id=this.state.training.teams.list[x].id;
				break;
			}
		}
		if(!team_id){
			return false;
		}
		if(!this.state.training.date.start_date){
			return false;
		}
		let type_id=0;
		for(let x in this.state.training.date.types){
			if(this.state.training.date.types[x].checked){
				type_id=this.state.training.date.types[x].id;
				break;
			}
		}

		let obj={};
		obj.campaign_language_id=campaign_language_id;
		obj.team_id=team_id;
		obj.type_id=type_id;
		obj.frequency_id=this.state.training.date.frequency[0].id;
		obj.start_date= (new moment(this.state.training.date.start_date, "YYYY-MM-DDTHH:mm").utc()).unix();	
		Helpers.doRequest('post','trainings',obj)
            .then(data=>{
            	this.props.history.push("/trainings",{"msg":data});
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	renderTab(tab){
    	let oldState=Object.assign({}, this.state);
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];	
		oldState.success={};
	    oldState.alertsField=[];
    	switch(tab){
    		case 'status':
    			Helpers.doRequest('get','trainings/'+this.state.training_id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.detail=data.training;
		            	if(data.training.data.status === 0){
		            		oldState.detail.data.start_date=Helpers.adjustDateTimezone(new Date(data.training.data.start_date));
			            	let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);
			            	if(Math.round(oldState.detail.data.start_date.getTime()/1000)>currentDayMaxDate){
			            		oldState.detail.data.min_time=null;
								oldState.detail.data.max_time=null;
			            	}else{
			            		oldState.detail.data.min_time=new Date();
								if(oldState.detail.data.min_time.getMinutes()>30){
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
								}else{
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
								}
								oldState.detail.data.max_time=(new Date()).setHours(23,30);
			            	}		            	
		            	}else if(data.training.data.status === 3){
		            		oldState.detail.data.next_date=Helpers.adjustDateTimezone(new Date(data.training.data.next_date));
		            		let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);
			            	if(Math.round(oldState.detail.data.next_date.getTime()/1000)>currentDayMaxDate){
			            		oldState.detail.data.min_time=null;
								oldState.detail.data.max_time=null;
			            	}else{
			            		oldState.detail.data.min_time=new Date();
								if(oldState.detail.data.min_time.getMinutes()>30){
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
								}else{
									oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
								}
								oldState.detail.data.max_time=(new Date()).setHours(23,30);
			            	}
		            	}  	
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'messages':
    			Helpers.doRequest('get','trainings/messages?training_id='+this.state.training_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.messages=data.messages;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		default:	
    			this.setState(oldState);		
    	}	
    }

    deleteTraining(){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Do you want to remove this training? This action cannot be undone.',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					Helpers.doRequest('delete','trainings/'+this.state.training_id)
			            .then(data=>{
			            	this.props.history.push("/trainings",{"msg":data});
			            }).catch(error=>{
			            	//
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});	
    }

    updateStartDate(date){
    	let oldState=Object.assign({}, this.state);
		let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);		
		if(Math.round(date.getTime()/1000)>currentDayMaxDate){
			oldState.detail.data.min_time=null;
			oldState.detail.data.max_time=null;
			oldState.detail.data.start_date=date;
		}else{
			oldState.detail.data.min_time=new Date();
			if(oldState.detail.data.min_time.getMinutes()>30){
				oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
			}else{
				oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
			}
			oldState.detail.data.max_time=(new Date()).setHours(23,30);
			oldState.detail.data.start_date=(date.getTime()>oldState.detail.data.min_time.getTime() ? date : oldState.detail.data.min_time);
		}
		let obj={};
		obj.start_date=(new moment(oldState.detail.data.start_date, "YYYY-MM-DDTHH:mm").utc()).unix();	
		Helpers.doRequest('put','trainings/'+oldState.training_id,obj)
            .then(data=>{
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });	
    }

    onHoldTraining(){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Do you want to put on hold this training?',this.state.translations.training,'training')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					Helpers.doRequest('post','trainings/on_hold',{'training_id':this.state.training_id})
			            .then(data=>{
			            	this.props.history.push("/trainings",{"msg":data});
			            }).catch(error=>{
			            	//
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    updateNextDate(date){
    	let oldState=Object.assign({}, this.state);
		let currentDayMaxDate=Math.round((new Date()).setHours(23,59,59)/1000);		
		if(Math.round(date.getTime()/1000)>currentDayMaxDate){
			oldState.detail.data.min_time=null;
			oldState.detail.data.max_time=null;
			oldState.detail.data.next_date=date;
		}else{
			oldState.detail.data.min_time=new Date();
			if(oldState.detail.data.min_time.getMinutes()>30){
				oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours())+1,0);
			}else{
				oldState.detail.data.min_time.setHours((oldState.detail.data.min_time.getHours()),30);
			}
			oldState.detail.data.max_time=(new Date()).setHours(23,30);
			oldState.detail.data.next_date=(date.getTime()>oldState.detail.data.min_time.getTime() ? date : oldState.detail.data.min_time);
		}
		this.setState(oldState);
    }

    restartTraining(){
    	let obj={};
		obj.training_id=this.state.training_id;
		obj.next_date= (new moment(this.state.detail.data.next_date, "YYYY-MM-DDTHH:mm").utc()).unix();	
		Helpers.doRequest('post','trainings/restart',obj)
            .then(data=>{
            	this.props.history.push("/trainings",{"msg":data});
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updateTypesSelection(index){
    	let oldState=Object.assign({},this.state);
    	let step_3_next=false;
    	for(let x in oldState.training.date.types){
			oldState.training.date.types[x].checked=(parseInt(x)===parseInt(index) ? true : false);
			if(oldState.training.date.types[x].checked){
				step_3_next=true;
			}
		}
		oldState.step_3_next=step_3_next;
    	this.setState(oldState);
    }

    updateFrequency(value){
    	let oldState=Object.assign({},this.state);
    	oldState.training.date.frequency=value;
    	let date=oldState.training.date.start_date ? oldState.training.date.start_date : new Date();
    	for(let x in oldState.training.date.types){
			if(oldState.training.date.types[x].id === 1){
				let last_date_to_run=new Date(date.getTime());
				let iteration=1;
				switch(oldState.training.date.frequency[0].id){
					case 1:				
						while(iteration<oldState.training.date.mesages_number){
							last_date_to_run.setDate(last_date_to_run.getDate()+7);
							iteration++;
						}
						break;
					default:
						while(iteration<oldState.training.date.mesages_number){
							last_date_to_run.setDate(last_date_to_run.getDate()+1);
							iteration++;
						}
						break;
				}	
				oldState.training.date.types[x].description=Helpers.translate('All team members will receive the same message at the same time. The training will end when the last message will be sent on',this.state.translations.general,'general')+" "+new moment(last_date_to_run).format("DD/MM/YYYY");
				break;
			}
		}
    	this.setState(oldState);
    }

	render(){
		let bodyContent;
		if(this.state.create === true){
			if(this.state.step === 1){
				let campaigns;
				if(this.state.training.campaigns.list && this.state.training.campaigns.list.length>0){
					let campaignContent=this.state.training.campaigns.list.map((item, key) =>
		    			<CampaignCard item={item} index={key} key={key}
		    				showClick={true}
		    				showFooter={true}
		    				showLastUpdated={true}
		    				translations={this.state.translations}
		    				selectCampaign={(index)=>this.selectCampaign(index)}
		    				container_class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-2"
		    			/>		
				    );
		    		campaigns=<CardDeck className="row">{campaignContent}</CardDeck>
				}		
				bodyContent=<div className="mt-4 w-100">					
						<Form className="text-left width_100">
							<Form.Group>
						    	<div className="row">
						    		<div className="col-sm-12">
						    			<h4 className="text-left font-weight-bold">{Helpers.translate('Select a campaign',this.state.translations.training,'training')}</h4>
						    		</div>
						    		<div className="col-sm-12 col-md-6 col-lg-6">
						    			<Form.Control type="text" value={this.state.campaign_search.search} 
						    			name="name"
						    			placeholder={Helpers.translate('Search',this.state.translations.general,'general')}
						    			onChange={(e)=>this.loadCampaigns(e.target.value,'search')}
						    			autoComplete="off" />
						    			{this.state.campaign_search.search.length>0 && 
						    				<i className="fas fa-backspace search_remove" onClick={()=>this.loadCampaigns("",'search')}></i>
						    			}
						    		</div>
						    		<div className="col-sm-12 col-md-6 col-lg-6">
						    			<div className="row">
						    				<div className="col-sm-6">
								    			<Select options={this.state.training.campaigns.language_filter || []}
										    		value={this.state.training.campaigns.selected_language && this.state.training.campaigns.selected_language.length ? this.state.training.campaigns.selected_language : []} 
										    		className="selectize"
					          						isClearable={false}
					          						isSearchable={false}
					          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
										    		getOptionLabel={option => option.name}
										    		getOptionValue={option => option.id}
										    		onChange={(value)=>this.loadCampaigns((value !== null ? [value]: value),'filter_by')}
										    	/>
								    		</div>
								    		<div className="col-sm-6">
								    			<Select options={this.state.training.campaigns.order_filter || []}
										    		value={this.state.training.campaigns.selected_order && this.state.training.campaigns.selected_order.length ? this.state.training.campaigns.selected_order : []} 
										    		className="selectize"
					          						isClearable={false}
					          						isSearchable={false}
					          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
										    		getOptionLabel={option => option.name}
										    		getOptionValue={option => option.id}
										    		onChange={(value)=>this.loadCampaigns((value !== null ? [value]: value),'order_by')}
										    	/>
								    		</div>
						    			</div>
						    		</div>			    		
						    	</div>			    									    						  		
						  	</Form.Group>
						</Form>
						{campaigns}
						{this.state.training.campaigns.show_more &&
						<h3 className="mt-5">
							<Button variant="light" onClick={()=>this.loadCampaigns(this.state.campaign_search.page+1,'page',true)}>
								<span>{Helpers.translate('View more',this.state.translations.general,'general')}</span>
								<span className="ml-2"><i className="fas fa-chevron-down"></i></span>
							</Button>							
						</h3>}
					</div>
			}else if(this.state.step === 2){
				let teamContent;
				if(this.state.training.teams && this.state.training.teams.list){
					teamContent=this.state.training.teams.list.map((item, key) =>
				        <tr key={key} className={(item.selected && item.selected === true ? "border-success" : "")}>
				        	<td onClick={()=>this.selectTeam(key)}>
				        		{ (item.selected && item.selected === true ? <i className="ft-25 fas fa-check-circle text-success"></i> : <i className="ft-25 far fa-circle"></i>) }
				        	</td>
				        	<td onClick={()=>this.selectTeam(key)}>{ item.name }</td>
				        	<td onClick={()=>this.selectTeam(key)}>{ item.members }</td>
				        </tr>
				    );
				}
				bodyContent=<div className="mt-4 w-100">
						<h4 className="text-left font-weight-bold">{Helpers.translate('Select team',this.state.translations.training,'training')}</h4>
						<div>
							<Table responsive="sm" size="sm" className="text-left active_rows team-table">
								<thead>
									<tr>
										<th></th>
										<th className="pointer" onClick={()=>this.sortTeamList("name")}>
								        	<span>{Helpers.translate('Name',this.state.translations.general,'general')}</span>
								        	{
								        		(this.state.team_search.order && this.state.team_search.order_by === 'name' ? (this.state.team_search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
								        </th>
								        <th>{Helpers.translate('Team members',this.state.translations.general,'general')}</th>
									</tr>
								</thead>
								<tbody>{ teamContent }</tbody>
							</Table>
						</div>
						<h3 className="mt-5">
							<p className="d-inline-block float-left">			
								<Button variant="link" size="sm" onClick={()=>this.setState({ step:1 })}>
									<i className="fas fa-chevron-left"></i>
									<span className="ml-3">{Helpers.translate('Previous',this.state.translations.general,'general')}</span>
								</Button>
							</p>							
						</h3>
					</div>
			}else if(this.state.step === 3){	
				let training_types;
				if(this.state.training.date.types && this.state.training.date.types.length>0){
					training_types=this.state.training.date.types.map((item, key)=>
							<Form.Group className="width_100 text-left" controlId={"training_types_"+key} key={key}>
							    <Form.Check 
							    type="radio"
							    name="" 
							    custom 
							    label={ <React.Fragment><p className="font-weight-bold mb-0">{item.name}</p><p className="mb-0">{item.description}</p></React.Fragment> } 
							    onChange={(e)=>this.updateTypesSelection(key)} 
							    checked={this.state.training.date.types[key].checked && this.state.training.date.types[key].checked === true ? 'checked' : ''}
							    />
							</Form.Group>									      
		    			);
				}	
				bodyContent=<div className="mt-4 w-100">
						<h4 className="text-left font-weight-bold">{Helpers.translate('Select frequency and start date',this.state.translations.training,'training')}</h4>
						<div className="text-left">
							<DatePicker
						      selected={this.state.training.date.start_date}
						      onChange={date => this.setStartDate(date)}
						      showTimeSelect
						      minDate={new Date()}
						      minTime={this.state.training.date.min_time}
						      maxTime={this.state.training.date.max_time}
						      dateFormat="dd/MM/yyyy h:mm aa"
						      customInput={<DatePickerInput />}
						    />
						    {this.state.errors.start_date && <p className="text-danger mt-1">{this.state.alertsField.start_date}</p>}
						</div>
						<div className="text-left mt-4">
							<p className="ft-16">{Helpers.translate('After this date, messages will be sent',this.state.translations.training,'training')}</p>
							<div className="col-sm-4 pl-0 pr-0">
								<Select options={this.state.training.date.frequencies}
						    		value={this.state.training.date.frequency && this.state.training.date.frequency.length ? this.state.training.date.frequency : []} 
						    		className="selectize"
	          						isClearable={false}
	          						isSearchable={false}
	          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateFrequency([value])}
						    	/>							
							</div>
						</div>
						<h4 className="text-left font-weight-bold mt-3 mb-4">{Helpers.translate('How long should the training run',this.state.translations.training,'training')}</h4>
						<div>{ training_types }</div>
						<h3 className="mt-5">
							<p className="d-inline-block float-left">
								<Button variant="link" size="sm" onClick={()=>this.setState({ step:2 })}>
									<i className="fas fa-chevron-left"></i>
									<span className="ml-3">{Helpers.translate('Previous',this.state.translations.general,'general')}</span>
								</Button>
							</p>
						</h3>
					</div>
			}		
		}else{		
			let trainingMessages;
			let trainingStatus=<div className="clearfix row mt-4 mt-3-mobile">
					<div className="col-lg-4 col-md-12 col-sm-12 mb-5">
						<div className="card_wrapper">
							<Form className="text-left width_100 col-sm-12 white-bg">
								<Form.Group>
									<div className="row">
										<Form.Label className="ft-16 col-sm-6 training_status">
								  			<Badge variant={ (this.state.detail.data.status === 1 ? "info" : (this.state.detail.data.status === 2 ? "success" : "warning")) }>
								  				{ Helpers.translate(this.state.detail.data.status_name,this.state.translations.general,'general') }
								  			</Badge>
								  		</Form.Label>
								  		{(this.state.detail.data.status === 0 || this.state.detail.data.status === 3) && this.props.permissions.microtraining.full_control && <Form.Label className="ft-16 col-sm-6 text-right">
								  			<Button variant="light" onClick={()=>this.deleteTraining()}>
								  				<i className="fas fa-trash-alt"></i>
											</Button>
								  		</Form.Label>}
								  		{this.state.detail.data.status === 1 && this.props.permissions.microtraining.full_control && <Form.Label className="ft-16 col-sm-6 text-right">
								  			<Button variant="light" onClick={()=>this.onHoldTraining()}>
								  				<i className="far fa-hand-paper"></i>
											</Button>
								  		</Form.Label>}	
									</div>						  						  								  		    									    				  		
							  	</Form.Group>
							  	{this.state.detail.data.status === 3 && this.props.permissions.microtraining.full_control &&
							  		<Form.Group>
							  			<div className="row">
							  				<Form.Label className="ft-16 col-sm-6">{Helpers.translate('Restart',this.state.translations.general,'general')}</Form.Label>		    									    				  		
									  		<Form.Label className="ft-16 col-sm-6 font-weight-bold text-right">
									  			<DatePicker
											      selected={this.state.detail.data.next_date}
											      onChange={date => this.updateNextDate(date)}
											      showTimeSelect
											      minDate={new Date()}
											      minTime={this.state.detail.data.min_time}
											      maxTime={this.state.detail.data.max_time}
											      dateFormat="dd/MM/yyyy h:mm aa"
											      customInput={<DatePickerInput />}
											    />
											    <Button className="ml-2" variant="light" onClick={()=>this.restartTraining()}>
									  				<i className="fas fa-play-circle"></i>
												</Button>
									  		</Form.Label>
							  			</div>						  				    									    				  
								  	</Form.Group>
							  	}
							  	<Form.Group>
							  		<div className="row">
							  			<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Campaign',this.state.translations.general,'general')}</Form.Label>		    									    				  		
							  			<Form.Label className="ft-16 col-sm-6 text-right">{ this.state.detail.card_info.title }</Form.Label>		    									    
							  		</div>						  		
							  	</Form.Group>
								<Form.Group>
									<div className="row">
										<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Team',this.state.translations.general,'general')}</Form.Label>		    									    				  		
							  			<Form.Label className="ft-16 col-sm-6 text-right">{ this.state.detail.data.team_name }</Form.Label>		    									    				  
									</div>					  		
							  	</Form.Group>
							  	<Form.Group>
							  		<div className="row">
							  			<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Team members',this.state.translations.general,'general')}</Form.Label>		    									    				  		
										<Form.Label className="ft-16 col-sm-6 text-right">{ this.state.detail.data.team_members }</Form.Label>	
							  		</div>			  			    									    				  					  		
							  	</Form.Group>
							  	<Form.Group>
							  		<div className="row">
							  			<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Start date',this.state.translations.general,'general')}</Form.Label>		    									    				  		
								  		<Form.Label className="ft-16 col-sm-6 text-right">
								  			{(this.state.detail.data.status !== 0 || !this.props.permissions.microtraining.full_control) && <span>{ new moment(Helpers.adjustDateTimezone(new Date(this.state.detail.data.start_date))).format("DD/MM/YYYY HH:mm") }</span>}
								  			{this.state.detail.data.status === 0 && this.props.permissions.microtraining.full_control && 
								  				<DatePicker
											      selected={this.state.detail.data.start_date}
											      onChange={date => this.updateStartDate(date)}
											      showTimeSelect
											      minDate={new Date()}
											      minTime={this.state.detail.data.min_time}
											      maxTime={this.state.detail.data.max_time}
											      dateFormat="dd/MM/yyyy h:mm aa"
											      customInput={<DatePickerInput />}
											    />
								  			}
								  		</Form.Label>
							  		</div>			  				    									    				  
							  	</Form.Group>
							  	<Form.Group>
							  		<div className="row">
							  			<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Messages',this.state.translations.general,'general')}</Form.Label>		    									    				  		
										<Form.Label className="ft-16 col-sm-6 text-right">{ this.state.detail.card_info.total_ready_messages }</Form.Label>	
							  		</div>			  			    									    				  					  		
							  	</Form.Group>
							  	{this.state.detail.data.status !==0 && <Form.Group>
							  		<div className="row">
							  			<Form.Label className="ft-16 col-sm-6 font-weight-bold">{Helpers.translate('Unsubscribes',this.state.translations.general,'general')}</Form.Label>		    									    				  		
							  			<Form.Label className="ft-16 col-sm-6 text-right">{ this.state.detail.data.unsubscribes }</Form.Label>
							  		</div>					  				    									    				  
							  	</Form.Group>}
							</Form>
						</div>					
					</div>
					{this.state.detail.data.status !== 0 && <div className="col-lg-8 col-md-12 col-sm-12">
						<div className="clearfix col-md-12 col-sm-12 mb-3">
							<div className="width_100 float-left">
								<h4 className="text-left font-weight-bold">{Helpers.translate('Average performance',this.state.translations.training,'training')}</h4>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 float-left">
								<div className="pl-3 pb-3 pr-3">
									<DoughnutChart
										data={this.state.detail.charts.open_rate}
					                    display_legend={false}
					                    aspect_ratio="1"
					                    cutout="90"
					                    title={Helpers.translate('Open Rate',this.state.translations.general,'general')}
					                    fontSize="25"
					                    tooltip_enabled={false}
					                />
								</div>							
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 float-left">
								<div className="pl-3 pb-3 pr-3">
									<DoughnutChart
										data={this.state.detail.charts.click_rate}
					                    display_legend={false}
					                    aspect_ratio="1"
					                    cutout="90"
					                    title={Helpers.translate('Click Rate',this.state.translations.general,'general')}
					                    fontSize="25"
					                    tooltip_enabled={false}
					                />
								</div>						
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 float-left">
								<div className="pl-3 pb-3 pr-3">
									<DoughnutChart
										data={this.state.detail.charts.correct_rate}
					                    display_legend={false}
					                    aspect_ratio="1"
					                    cutout="90"
					                    title={Helpers.translate('Correct answers',this.state.translations.general,'general')}
					                    fontSize="25"
					                    tooltip_enabled={false}
					                />
								</div>								
				                <h3 className="text-center mt-3">
				                	<span>{Helpers.translate('Target',this.state.translations.general,'general')}: {this.state.detail.charts.correct_rate_target_percentage}%</span>
				                </h3>
							</div>
						</div>					
					</div>}							
				</div>
				if(this.state.messages.list){
					trainingMessages=<TrainingMessages
							training_id={this.state.training_id} 
		    				messages={this.state.messages} 
		    				translations={this.state.translations} 
		    			/>
				}			
			bodyContent=<div>		  
			      	{trainingStatus}
			      	{trainingMessages}
			    </div>
		}
		return (
			<div className="content training_view">
				{this.state.show_content && this.state.create && <div className="col-sm-12 mt-5 mt-3-mobile">
					<h4 className="text-left font-weight-bold">{Helpers.translate('New microtraining',this.state.translations.training,'training')}</h4>			
					<div className="row width_100">
						<div className="d-flex mt-2 width_100 steps">
							<div className="col-md-4 col-sm-12 text-left">
								<div className="back-button text-left">
									<Link to="/trainings"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to trainings',this.state.translations.training,'training')}</span></Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 text-center">
								<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center d-inline-block "+(this.state.step===1 ? "active" : "")}>
									<div className="setup_card d-block">
										<Badge pill variant="light">1</Badge>									
									</div>
									<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Campaign',this.state.translations.general,'general')}</span>
								</div>
								<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center d-inline-block "+(this.state.step===2 ? "active" : "")}>
									<div className="setup_card d-block">
										<Badge pill variant="light">2</Badge>										
									</div>
									<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Team',this.state.translations.general,'general')}</span>
								</div>
								<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center d-inline-block "+(this.state.step===3 ? "active" : "")}>
									<div className="setup_card d-block">
										<Badge pill variant="light">3</Badge>								
									</div>
									<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Date',this.state.translations.general,'general')}</span>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 text-right">
								{
									this.state.step === 1 &&
									<Button className="float-right btn-general" disabled={!this.state.step_1_next} onClick={()=>this.goToStepTwo()}>
										<span>{Helpers.translate('Continue',this.state.translations.general,'general')}</span>
									</Button>
								}
								{
									this.state.step === 2 &&
									<Button className="float-right btn-general" disabled={!this.state.step_2_next} onClick={()=>this.goToStepThree()}>
										<span>{Helpers.translate('Continue',this.state.translations.general,'general')}</span>
									</Button>
								}
								{
									this.state.step === 3 &&
									<Button className="float-right btn-general" disabled={!this.state.step_3_next} onClick={()=>this.createTraining()}>
										<span>{Helpers.translate('Save',this.state.translations.general,'general')}</span>
									</Button>
								}
							</div>					
						</div>					
					</div>
					{bodyContent}			
				</div>}
				{this.state.show_content && !this.state.create && <div className="col-sm-12 mt-5 mt-3-mobile">
					<div className="back-button text-left">
						<Link to="/trainings"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to trainings',this.state.translations.training,'training')}</span></Link>
					</div>
					{bodyContent}				
				</div>}
		   	</div>
		);
	}

}
export default withRouter(MicroTraining);