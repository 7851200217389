import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Form from 'react-bootstrap/Form';
import { ReactSortable } from 'react-sortablejs';

class Campaigns extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],      	
	  		translations:{},
	  		campaigns:{
	  			'list':[]
	  		},
	  		search:{
	  			'page': 1,
	  			'search':""
	  		},
	  		disable_reorder:true,
	  		show_save_reorder:false,
	  		sequence_order:[]
	  	};
	  	this.moduleName='campaign';
	  	this.searchPromise=0;
	}

	componentDidMount(){
		this.loadList(true);
	}

	loadList(withTranslations){
		let oldState=Object.assign({}, this.state);	
		let urlString="";
        for(let x in oldState.search){
        	if(Array.isArray(oldState.search[x]) && oldState.search[x].length === 1){
        		urlString+="&"+x+"="+oldState.search[x][0].id;
        	}else{
        		urlString+="&"+x+"="+oldState.search[x];
        	}     	
        }
        urlString=urlString.replace("&", "?");	
		Helpers.doRequest('get','campaigns'+urlString)
 			.then(data=>{  		 				       	                 	            	
            	oldState.campaigns=data.campaigns;
            	if(withTranslations){
            		Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
            	}else{
            		this.setState(oldState);
            	}       	
 			}).catch(error=>{
            	//	
        	});
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	oldState[parent][model]=value;      	
        }else{
        	oldState[model]=value;
        }  
        this.setState(oldState);  
    }

	deleteCampaign(id){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete the selected line?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					Helpers.doRequest('delete','campaigns/'+id)
			            .then(data=>{
			            	this.loadList();
			            }).catch(error=>{
			            	//
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});		
	}

	searchList(value,model){
		let oldState=Object.assign({}, this.state);
		oldState.search[model]=value;	
		if(model !== 'page'){
			oldState.search.page=1;	
		}
		let urlString="";
        for(let x in oldState.search){
        	if(Array.isArray(oldState.search[x]) && oldState.search[x].length === 1){
        		urlString+="&"+x+"="+oldState.search[x][0].id;
        	}else{
        		urlString+="&"+x+"="+oldState.search[x];
        	}     	
        }
        urlString=urlString.replace("&", "?");	
        this.setState(oldState);
		if(this.searchPromise){
			clearTimeout(this.searchPromise);
		}
		this.searchPromise=setTimeout(()=>{   
            Helpers.doRequest('get','campaigns'+urlString)
	            .then(data=>{                                                                   
	                oldState.campaigns=data.campaigns;
	                this.setState(oldState);
	            }).catch(error=>{
	                //  
	            });
	    },500);
	}

	handlePageCampaigns(data){
		let oldState=Object.assign({}, this.state);
        oldState.errors={};
        oldState.alerts=[]; 
        oldState.alertsField=[];
        oldState.search.page=data.selected+1;
        let urlString="";
        for(let x in oldState.search){
        	if(Array.isArray(oldState.search[x]) && oldState.search[x].length === 1){
        		urlString+="&"+x+"="+oldState.search[x][0].id;
        	}else{
        		urlString+="&"+x+"="+oldState.search[x];
        	}     	
        }
        urlString=urlString.replace("&", "?");
        Helpers.doRequest('get','campaigns'+urlString)
            .then(data=>{                                                                   
                oldState.campaigns=data.campaigns;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
	}

	handleSort(evt){
    	if(!this.state.disable_reorder && evt.oldIndex !== evt.newIndex){
    		let oldState=Object.assign({}, this.state);
    		const movedRow = oldState.campaigns.list[evt.oldIndex];
			oldState.campaigns.list.splice(evt.oldIndex, 1);
      		oldState.campaigns.list.splice(evt.newIndex, 0, movedRow);
      		oldState.sequence_order=[];
      		for(let x in oldState.campaigns.list){
      			oldState.campaigns.list[x].sequence_order=(parseInt(x)+1);
      			oldState.sequence_order.push({'campaign_id':oldState.campaigns.list[x].id});
      		}
      		oldState.errors={};
			oldState.alerts=[];	
			oldState.success={};
		    oldState.alertsField=[];
		    this.setState(oldState);	    
    	}
    }

    saveReorder(){
    	let obj={};
	    obj.sequence_order=this.state.sequence_order;
	    Helpers.doRequest('post','campaigns/reorder',obj)
	    .then(data=>{                                                                   
                //
            }).catch(error=>{
                //  
            });
	    this.setState({'disable_reorder':true,'show_save_reorder':false});
    }

	render(){
    	const campaignContent=this.state.campaigns.list.map((item, key) =>
			<tr key={key}>
				<td onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>{ item.sequence_order }</td>
	        	<td onClick={()=>this.props.history.push("/campaigns/"+item.id)}>{ item.name }</td>
	        	<td className={ (item.languages.length > 1 ? "p-0" : "") } onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>
	        		{
	        			(item.languages.length === 1 ? (item.languages[0].name) : 
	        				<Table responsive="sm" className="mb-0">
	        					<tbody>
	        					{ item.languages.map((subitem,subkey) => 
	        							<tr key={subkey}><td className={ (subkey === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0") }>{ subitem.name }</td></tr>
	        						)
	        					}
	        					</tbody>
	        				</Table>
	        			) 
	        		}
	        	</td>
	        	<td className={ (item.languages.length === 1 ? (item.languages[0].status === 1 ? "bg-success" : "bg-warning") : "p-0") } onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>
	        		{
	        			(item.languages.length === 1 ? (Helpers.translate(item.languages[0].status_name,this.state.translations.general,'general')) : 
	        				<Table responsive="sm" className="mb-0">
	        					<tbody>
	        					{ item.languages.map((subitem,subkey) => 
	        							<tr className={(subitem.status === 1 ? "bg-success" : "bg-warning")} key={subkey}><td className={ (subkey === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0") }>{ Helpers.translate(subitem.status_name,this.state.translations.general,'general') }</td></tr>
	        						)
	        					}
	        					</tbody>
	        				</Table>
	        			) 
	        		}
	        	</td>	
	        	<td className={ (item.languages.length > 1 ? "p-0" : "") } onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>
	        		{
	        			(item.languages.length === 1 ? (Helpers.translate(item.languages[0].availability_status,this.state.translations.general,'general')) : 
	        				<Table responsive="sm" className="mb-0">
	        					<tbody>
	        					{ item.languages.map((subitem,subkey) => 
	        							<tr key={subkey}><td className={ (subkey === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0") }>{ Helpers.translate(subitem.availability_status,this.state.translations.general,'general') }</td></tr>
	        						)
	        					}
	        					</tbody>
	        				</Table>
	        			) 
	        		}
	        	</td>	        			      
	        	<td onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>{ item.messages }</td>
	        	<td onClick={(event)=>this.props.history.push("/campaigns/"+item.id)}>{Helpers.translate(item.trainings_usage,this.state.translations.general,'general')}</td>
	        	<td className="text-center">
	        		<div className="text-right">
	        			<Link className="dropdown-item" to={"/campaigns/"+item.id}><i className="fas fa-pen"></i></Link>
	        			{this.props.permissions.campaign && this.props.permissions.campaign.full_control && !item.is_default && 
							<a href="#" className="dropdown-item" onClick={(event)=>this.deleteCampaign(item.id)}><i className="fas fa-times-circle"></i></a>
						}
					</div>
	        	</td>
	        </tr>
	    );
	    let campaignPagination;
	    if(this.state.campaigns.page_count>1){
    		campaignPagination=<ReactPaginate
				        previousLabel={'<'}
				        nextLabel={'>'}
				        breakLabel={'...'}
				        pageCount={this.state.campaigns.page_count}
				        marginPagesDisplayed={2}
				        pageRangeDisplayed={5}				        
				        onPageChange={(data)=>this.handlePageCampaigns(data)}
				        containerClassName={'pagination pagination-sm'}
				        pageClassName={'page-item'}
				        pageLinkClassName={'page-link'}
				        previousClassName={'page-item'}
				        nextClassName={'page-item'}
				        previousLinkClassName={'page-link'}
				        nextLinkClassName={'page-link'}
				        activeClassName={'active'} />
    	}
		return (
			<div className="content">
				<div className="col-sm-12 mt-5 mt-3-mobile">
					{this.props.permissions.campaign && this.props.permissions.campaign.full_control && <h4 className="text-right">
						<Button className="btn-general" disabled={(this.state.campaigns.allow_create ? false : true)} onClick={()=>(this.state.campaigns.allow_create ? this.props.history.push("/campaigns/new") : ()=>{})}>{Helpers.translate('Create new campaign',this.state.translations.campaign,'campaign')}</Button>
					</h4>}
					<h4 className="text-left">
						<span>{Helpers.translate('Campaigns',this.state.translations.general,'general')}</span>
						{this.props.permissions.campaign && this.props.permissions.campaign.full_control && <React.Fragment>
							{this.state.show_save_reorder && <Button className="btn-general float-right" onClick={()=>this.saveReorder()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>}
							{this.state.show_save_reorder && <Button variant="light float-right mr-2" onClick={()=>Helpers.updateStateValue(this,[true,false],['disable_reorder','show_save_reorder'])}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</Button>}
							{!this.state.show_save_reorder && <Button variant="light float-right" onClick={()=>Helpers.updateStateValue(this,[false,true],['disable_reorder','show_save_reorder'])}>{Helpers.translate('Edit order',this.state.translations.general,'general')}</Button>}
						</React.Fragment>}
					</h4>
					<div className="mt-3 text-left">
					{this.props.permissions.campaign && this.props.permissions.campaign.full_control && <React.Fragment>
						{this.state.campaigns.list && !this.state.campaigns.allow_create && 
							<p className="mt-2">{Helpers.translate('You have reached the maximum numbers of campaigns',this.state.translations.campaign,'campaign')}</p>
						}
						</React.Fragment>}										
					</div>
					<div className="mt-3 text-left">
						<div className="row">
							<div className="col-sm-12">
				    			<Form.Control type="text" value={this.state.search.search} 
				    			name="name"
				    			placeholder={Helpers.translate('Search',this.state.translations.general,'general')}
				    			onChange={(e)=>this.searchList(e.target.value,'search')}
				    			autoComplete="off" />
				    			{this.state.search.search.length>0 && 
				    				<i className="fas fa-backspace search_remove" onClick={()=>this.searchList("",'search')}></i>
				    			}
				    		</div>
						</div>					
						<Table responsive="sm" size="sm" className="text-left active_rows">
				  			<thead>
						      <tr className="table-secondary">
						      	<th>{Helpers.translate('Sequence',this.state.translations.campaign,'campaign')}</th>
						        <th>{Helpers.translate('Campaign',this.state.translations.campaign,'campaign')}</th>					        
						        <th>{Helpers.translate('Language',this.state.translations.general,'general')}</th>
						        <th>{Helpers.translate('Campaign status',this.state.translations.campaign,'campaign')}</th>
						        <th>{Helpers.translate('Campaign type',this.state.translations.campaign,'campaign')}</th>
						        <th>{Helpers.translate('Messages',this.state.translations.general,'general')}</th>
						        <th>{Helpers.translate('Microtrainings usage',this.state.translations.campaign,'campaign')}</th>
						        <th></th>
						      </tr>
						    </thead>
					    	<ReactSortable
			                    tag="tbody" 
			                    list={this.state.campaigns.list}
			                    setList={(newState) => ()=>{}}
			                    onEnd={(evt) => this.handleSort(evt)}>			    
						    	{ campaignContent }
						    </ReactSortable>
				  		</Table>
					</div>
					<div>{ campaignPagination }</div>
				</div>			
		   	</div>
		);
	}

}
export default withRouter(Campaigns);