import React from 'react';
import Helpers from './../services/Helpers.js';
import Store from './../services/Store.js';
import Dropzone from 'react-dropzone';
import * as loadImage from 'blueimp-load-image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Editor } from '@tinymce/tinymce-react';

export default class MultipleChoiceThreeQuestions extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		item:this.props.data
	  	};
	  	this.tinymceConfig={
           height: 200,
           menubar: false,
           branding: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help'
           ],
           toolbar:
             'bold italic | bullist numlist'
        };
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);            
        if(parent){
        	let oldState_parent;
        	if(parent.indexOf(".") !== -1){
        		let splittedParent=parent.split(".");
        		oldState_parent=oldState[splittedParent[0]][splittedParent[1]];
        	}else{
        		oldState_parent=oldState[parent];
        	}
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState_parent[model[x]]=value[x];
        		}
        	}else{
        		oldState_parent[model]=value;     		
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        this.setState(oldState);  
    }

    copyFromMainLanguage(){
    	let escapedValues=['header_image'];
    	let oldState=Object.assign({}, this.state);
    	for(let x in this.props.data_main.data){
    		if(escapedValues.indexOf(x) === -1){
	        	oldState.item.data[x]=this.props.data_main.data[x];
	        }
    	}
    	this.setState(oldState);
    }

	onMessageHeaderDrop(acceptedFiles){
    	if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		if(parseInt(acceptedFiles[0].size/1000) > parseInt(this.props.header_max_size/1000)){
	    			let errorMsg={'errors':{'error':Helpers.translate('Maximum size allowed is',this.props.translations.general,'general')+" "+this.props.header_max_size/1000+"Kb"}};
	    			this.props.onError(errorMsg);
	    			return false;
	    		}  		
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(acceptedFiles[0], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(acceptedFiles[0], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(acceptedFiles[0].type));
				  		convertedImage.preview=URL.createObjectURL(convertedImage);
				  		Helpers.stopLoading();	
				  		oldState.item.data.header_image=convertedImage;
				    	this.setState(oldState); 
				  	}, loadImageOptions)
				});	    
	    	}    	
	    }
    }

    updateData(){
    	let genThis=this;
		let formData = new FormData();
		formData.append("message_language_id", this.state.item.id);
		for(let x in this.state.item.data){
			if(x==='answer_one_correct' || x==='answer_two_correct' || x === 'answer_three_correct'){
				formData.append(x, (this.state.item.data[x] ? '1' : '0'));
			}else if(x==='header_image' && this.state.item.data[x] === null){
				continue;
			}else{
				formData.append(x, this.state.item.data[x]);
			}	
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'messages/template_data', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
            	genThis.props.onSuccess(data);          	
	        }else{
	        	Helpers.stopLoading();
	        	genThis.props.onError(JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
    }

    updateCorrectOption(value,model){
    	let oldState=Object.assign({}, this.state);
    	oldState.item.data.answer_one_correct=false;
    	oldState.item.data.answer_two_correct=false;
    	oldState.item.data.answer_three_correct=false;
    	oldState.item.data[model]=value;
    	this.setState(oldState);
    }

    componentDidUpdate(prevProps) {
  		if (prevProps.tab_changed !== undefined && prevProps.tab_changed !== this.props.tab_changed) {
    		this.setState({ item: this.props.data });
  		}
	}

	render(){
		return(
			<div className="row">
				<div className="col-lg-6 col-md-8 col-sm-12 text-left">
					<Form className="text-left width_100">
						{this.props.index>0 && <Form.Group>
							<div>
								<Button variant="info" onClick={()=>this.copyFromMainLanguage()}>
									<span>{Helpers.translate('Copy from',this.props.translations.general,'general')} {this.props.data_main.language_code.toUpperCase()}</span>
									<span className="ml-2"><i className="far fa-copy"></i></span>
								</Button>
							</div>
						</Form.Group>}
						<Form.Group>
						    <Form.Label className="ft-16 font-weight-bold">
						    	<span>{Helpers.translate('Title',this.props.translations.general,'general')}</span>
						    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
						    </Form.Label>
					    	<div className="row">
					    		<div className="col-sm-12">
					    			<Form.Control maxLength="191" type="text" value={ this.state.item.data.title || ''} 
					    			name="title"
					    			placeholder={Helpers.translate('Title',this.props.translations.general,'general')}
					    			onChange={(e)=>this.updateStateValue(e.target.value,'title','item.data')}
					    			autoComplete="off" />
					    		</div>
					    	</div>
					    	{this.props.errors.title && <p className="text-danger mt-1">{this.props.alertsField.title}</p>}
						</Form.Group>
						<Form.Group>
						    <Form.Label className="ft-16 font-weight-bold">
						    	<span>{Helpers.translate('Intro text',this.props.translations.campaign,'campaign')}</span>
						    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
						    </Form.Label>
					    	<div className="row">
					    		<div className="col-sm-12">
					    			<Editor
					    				apiKey={ process.env.REACT_APP_TINYMCE_KEY }
								        value={ this.state.item.data.intro_text || '' }
								        init={ this.tinymceConfig }
								        onEditorChange={(content,editor)=>this.updateStateValue(content,'intro_text','item.data')}
	       							/>
					    		</div>
					    	</div>
					    	{this.props.errors.intro_text && <p className="text-danger mt-1">{this.props.alertsField.intro_text}</p>}
						</Form.Group>
						<Form.Group>
							<div className="row">
								<div className="col-md-2">
									<Form.Label className="ft-16 font-weight-bold">
								    	<span>{Helpers.translate('Answer 1',this.props.translations.campaign,'campaign')}</span>
								    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
								    </Form.Label>
								</div>
								<div className="col-md-8">
									<Form.Control maxLength="191" type="text" value={ this.state.item.data.answer_one || ''} 
						    			name="answer_one"
						    			placeholder={Helpers.translate('Answer 1',this.props.translations.campaign,'campaign')}
						    			onChange={(e)=>this.updateStateValue(e.target.value,'answer_one','item.data')}
						    			autoComplete="off" />
								</div>
								<div className="col-md-2">
									<Form.Label className="ft-16">
								    	<Form.Check 
									    	type="radio" 
									    	custom 
									    	id={"check_answer_one_"+this.props.index}
									    	label={Helpers.translate('correct',this.props.translations.general,'general')} 
									    	onChange={(e)=>this.updateCorrectOption(e.target.checked,'answer_one_correct')} 
									    	checked={this.state.item.data.answer_one_correct && this.state.item.data.answer_one_correct === true ? 'checked' : ''}
									    />
								    </Form.Label>
								</div>
							</div>
					    	{this.props.errors.answer_one && <p className="text-danger mt-1">{this.props.alertsField.answer_one}</p>}
						</Form.Group>
						<Form.Group>
							<div className="row">
								<div className="col-md-2">
									<Form.Label className="ft-16 font-weight-bold">
								    	<span>{Helpers.translate('Answer 2',this.props.translations.campaign,'campaign')}</span>
								    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
								    </Form.Label>
								</div>
								<div className="col-md-8">
									<Form.Control maxLength="191" type="text" value={ this.state.item.data.answer_two || ''} 
						    			name="answer_two"
						    			placeholder={Helpers.translate('Answer 2',this.props.translations.campaign,'campaign')}
						    			onChange={(e)=>this.updateStateValue(e.target.value,'answer_two','item.data')}
						    			autoComplete="off" />
								</div>
								<div className="col-md-2">
									<Form.Label className="ft-16">
								    	<Form.Check 
									    	type="radio" 
									    	custom 
									    	id={"check_answer_two_"+this.props.index}
									    	label={Helpers.translate('correct',this.props.translations.general,'general')} 
									    	onChange={(e)=>this.updateCorrectOption(e.target.checked,'answer_two_correct')} 
									    	checked={this.state.item.data.answer_two_correct && this.state.item.data.answer_two_correct === true ? 'checked' : ''}
									    />
								    </Form.Label>
								</div>
							</div>
					    	{this.props.errors.answer_two && <p className="text-danger mt-1">{this.props.alertsField.answer_two}</p>}
						</Form.Group>
						<Form.Group>
							<div className="row">
								<div className="col-md-2">
									<Form.Label className="ft-16 font-weight-bold">
								    	<span>{Helpers.translate('Answer 3',this.props.translations.campaign,'campaign')}</span>
								    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
								    </Form.Label>
								</div>
								<div className="col-md-8">
									<Form.Control maxLength="191" type="text" value={ this.state.item.data.answer_three || ''} 
						    			name="answer_three"
						    			placeholder={Helpers.translate('Answer 3',this.props.translations.campaign,'campaign')}
						    			onChange={(e)=>this.updateStateValue(e.target.value,'answer_three','item.data')}
						    			autoComplete="off" />								
								</div>
								<div className="col-md-2">
									<Form.Label className="ft-16">
								    	<Form.Check 
									    	type="radio" 
									    	custom 
									    	id={"check_answer_three_"+this.props.index}
									    	label={Helpers.translate('correct',this.props.translations.general,'general')} 
									    	onChange={(e)=>this.updateCorrectOption(e.target.checked,'answer_three_correct')} 
									    	checked={this.state.item.data.answer_three_correct && this.state.item.data.answer_three_correct === true ? 'checked' : ''}
									    />
								    </Form.Label>
								</div>
							</div>
					    	{this.props.errors.answer_three && <p className="text-danger mt-1">{this.props.alertsField.answer_three}</p>}
						</Form.Group>
						<Form.Group>
						    <Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Footer text',this.props.translations.campaign,'campaign')}</Form.Label>
					    	<div className="row">
					    		<div className="col-sm-12">
					    			<Editor
					    				apiKey={ process.env.REACT_APP_TINYMCE_KEY }
								        value={ this.state.item.data.footer_text || '' }
								        init={ this.tinymceConfig }
								        onEditorChange={(content,editor)=>this.updateStateValue(content,'footer_text','item.data')}
	       							/>
					    		</div>
					    	</div>
						</Form.Group>
						<Form.Group>
						    <Form.Label className="ft-16 font-weight-bold">
						    	<span>{Helpers.translate('Feedback text',this.props.translations.campaign,'campaign')}</span>
						    	<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
						    </Form.Label>
					    	<div className="row">
					    		<div className="col-sm-12">
					    			<Editor
					    				apiKey={ process.env.REACT_APP_TINYMCE_KEY }
								        value={ this.state.item.data.feedback_text || '' }
								        init={ this.tinymceConfig }
								        onEditorChange={(content,editor)=>this.updateStateValue(content,'feedback_text','item.data')}
	       							/>
					    		</div>
					    	</div>
					    	{this.props.errors.feedback_text && <p className="text-danger mt-1">{this.props.alertsField.feedback_text}</p>}
						</Form.Group>
					</Form>
				</div>
				<div className="offset-lg-1 col-lg-5 col-md-4 col-sm-12">
					<Form className="text-left width_100">
						<Form.Group>
							<div className="text-left">
					  			<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Header image',this.props.translations.general,'general')}</Form.Label>
					  			{this.state.item.data.header_image !== null && <div className="delete_picture" >
									<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.updateStateValue(null,'header_image','item.data')}></i>		      	
							    </div>}
					  		</div>
							{this.state.item.data.header_image === null && <div className="text-left">
								<Dropzone onDrop={(acceptedFiles)=>this.onMessageHeaderDrop(acceptedFiles)} accept={'image/jpg,image/png,image/jpeg'} multiple={false}>
								  {({getRootProps, getInputProps}) => (
								    <section className="d-inline-block">
								      <div {...getRootProps()}>
								        <input {...getInputProps()} />
								        <Button className="btn-general" variant="secondary">{Helpers.translate('Upload question image',this.props.translations.general,'general')}</Button>
								      </div>
								    </section>
								  )}
								</Dropzone>
								<p><small>{Helpers.translate('Maximum size allowed is',this.props.translations.general,'general')} {this.props.header_max_size/1000}Kb</small></p>
							</div>}	
							{this.state.item.data.header_image !== null && <div className="dropZoneListThumb" >
						      <div className="thumbInner pointer">
						        <img src={this.state.item.data.header_image.preview} alt="" onClick={()=>window.open(this.state.item.data.header_image.preview,'_blank')}/>
						      </div>
						    </div>}			
						</Form.Group>
					</Form>
				</div>
				<div className="col-sm-12 mt-3 mb-3 text-left">
					<Button className="btn-general float-left" onClick={()=>this.updateData()}>{Helpers.translate('Save changes',this.props.translations.general,'general')}</Button>
					{
						this.props.allow_create && <Button className="btn-general float-right" onClick={()=>this.props.createNewMessage()}>{Helpers.translate('Add new message',this.props.translations.campaign,'campaign')}</Button>
					}
				</div>					
			</div>
		);
	}

}