import React from 'react';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import { withRouter } from 'react-router-dom';

class Onboarding extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		team:{},
	  		translations:{},
	      	step:null,
	      	carouselIndex:0
	  	};
	  	this.moduleName='onboarding';
	  	this.validation_team={
      		"name":["min:3"]
      	}; 
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		const current_step=Store.retrieveStep();
		switch(current_step){
			case 'first_team':
			default:
				oldState.step=current_step; 
				Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
		}
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	oldState[parent][model]=value;       	
        }else{
        	oldState[model]=value;
        }   
        this.setState(oldState);  
    }

	submitForm(e,functionName){
		if(e.key === "Enter"){
			e.preventDefault();
			this[functionName]();
		}
	}

	submitTeamData(){
		let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.validation_team,this.state.team,fieldTranslations,this.state.translations.general,true);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.team);
		Helpers.doRequest('post','first_team',obj)
            .then(data=>{
            	let oldState=Object.assign({}, this.state);
            	oldState.step=data.step;
            	this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	accessDashboard(){
		Helpers.doRequest('post','dashboard',{})
            .then(data=>{
            	let storedData=JSON.parse(localStorage.MicroTData);
            	storedData.step=data.step;
            	storedData.main_page=data.main_page;
            	Store.updateLoginData(storedData,true);
            	window.ee.emit('storeChange');
            	this.props.history.push("/"+data.main_page);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	changeSlide(index){
		if((this.state.carouselIndex === 0 && index === 5) || (this.state.carouselIndex === 5 && index === 0)){
			return false;
		}
		this.setState({'carouselIndex':index});
	}

	render(){ 
	    let firstTeam; 
	    if(this.state.step === 'first_team'){
	    	firstTeam=<div>
						<div className="page-login-vertical">
							<div className="page-login-box">
								<div className="row">
									<div className="page-login-panel">
										<Form className="text-left">					
										  	<Form.Group>
										    	<h4 className="mt-3 font-weight-bold">{Helpers.translate('Do you already want to create the first team?',this.state.translations.onboarding,'onboarding')}</h4>
										    	<Form.Label><small>{Helpers.translate('You can change/create extra teams later',this.state.translations.onboarding,'onboarding')}</small></Form.Label>
										  	</Form.Group>	
										  	<Form.Group>
										    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Name',this.state.translations.general,'general')}</Form.Label>
										    	<div className="row">
										    		<div className="col-sm-12">
										    			<Form.Control type="text" className="pl-1" value={this.state.team.name || ''} 
										    			name="name"
										    			onChange={(e)=>this.updateStateValue(e.target.value,'name','team')}
										    			autoComplete="off"
										    			onKeyPress={(e) => this.submitForm(e,'submitTeamData')}/>
										    		</div>
										    	</div>			    									    	
										  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
										  	</Form.Group>						  	
										</Form>
										<div className="col-sm-12 mt-4">
											<Button onClick={()=>this.submitTeamData()}>{Helpers.translate('Submit',this.state.translations.general,'general')}</Button>
										</div>
									</div>
								</div>
							</div>
					   	</div>
				   	</div>
	    }
	    let dashboardConfirmation;
	    if(this.state.step === 'dashboard_confirmation'){
	    	dashboardConfirmation=<div className="col-sm-12 mt-5">
				<Carousel className="onboarding_carousel"
					activeIndex={this.state.carouselIndex}
                  	onSelect={(index,e)=>this.changeSlide(index)}
					indicators={false}
					interval={null}
					nextIcon={(this.state.carouselIndex === 5 ? null : <i className="fas fa-chevron-right"></i>)}
					prevIcon={(this.state.carouselIndex === 0 ? null : <i className="fas fa-chevron-left"></i>)}
					>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						<Carousel.Caption>
					      <h3>{Helpers.translate('Please follow this short guide to learn about our features',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" className="d-block w-100"
							src={require('./../../assets/images/onboarding/slide_1.png')} />
						</div>							    
					</Carousel.Item>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						<Carousel.Caption>
					      <h3>{Helpers.translate('Onboard your team members',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" 
							className="carousel-img d-block w-100"
							src={require('./../../assets/images/onboarding/slide_2.png')} />
					    </div>				    
					</Carousel.Item>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						<Carousel.Caption>
					      <h3>{Helpers.translate('Choose your favorite campaign and launch a new training',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" 
							className="carousel-img d-block w-100"
							src={require('./../../assets/images/onboarding/slide_3.png')} />
					    </div>				    
					</Carousel.Item>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						<Carousel.Caption>
					      <h3>{Helpers.translate('Create new campaigns',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" 
							className="carousel-img d-block w-100"
							src={require('./../../assets/images/onboarding/slide_4.png')} />
					   	</div>				    
					</Carousel.Item>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						<Carousel.Caption>
					      <h3>{Helpers.translate('Check the progress of your teams',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" 
							className="carousel-img d-block w-100"
							src={require('./../../assets/images/onboarding/slide_5.png')} />
					    </div>				    
					</Carousel.Item>
					<Carousel.Item className="col-sm-12 pl-5 pr-5">
						 <Carousel.Caption>
					      <h3>{Helpers.translate('Add new users to your channel and create content together',this.state.translations.onboarding,'onboarding')}</h3>
					    </Carousel.Caption>
						<div className="col-sm-8 offset-sm-2">
							<img alt="" 
							className="carousel-img d-block w-100"
							src={require('./../../assets/images/onboarding/slide_6.png')} />
					    </div>					   
					</Carousel.Item>
				</Carousel>
				{this.state.carouselIndex === 5 && <div className="col-sm-12 mt-4">
					<Button type="submit" onClick={()=>this.accessDashboard()}>{Helpers.translate('Start your training',this.state.translations.onboarding,'onboarding')}</Button>
				</div>}
			</div>
	    }
		return (		
			<div className="content">
				{firstTeam}
				{dashboardConfirmation}
		   	</div>
		)
	}
}
export default withRouter(Onboarding);
