export default{
	state: {
		logged:false,
		accessToken:'',
		accessTokenExpires:'',
		name:'',
		step:'',
		main_page:'',
		permissions:{},
		environments:[],
		image:""
	},
	retrieveStatus(){
		return this.state.logged;
	},
	retrieveAccessToken(){
		return this.state.accessToken;
	},
	retrieveRefreshToken(){
		return this.state.refreshToken;
	},
	retrieveUserName(){
		return this.state.name;
	},
	retrieveStep(){
		return this.state.step;
	},
	retrieveMainPage(){
		return this.state.main_page;
	},
	retrieveUserPermissions(){
		return this.state.permissions;
	},
	retrieveUserEnvironments(){
		return this.state.environments;
	},
	retrieveUserImage(){
		return this.state.image;
	},
	setStep(step){
		this.state.step=step;
		let storedData=JSON.parse(localStorage.MicroTData);
		storedData.step=step;
		localStorage.setItem('MicroTData',JSON.stringify(storedData));
	},
	setMainPage(page){
		this.state.main_page=page;
		let storedData=JSON.parse(localStorage.MicroTData);
		storedData.main_page=page;
		localStorage.setItem('MicroTData',JSON.stringify(storedData));
	},
	setUserName(name){
		this.state.name=name;
		let storedData=JSON.parse(localStorage.MicroTData);
		storedData.name=name;
		localStorage.setItem('MicroTData',JSON.stringify(storedData));
	},
	setUserImage(image){
		this.state.image=image;
		let storedData=JSON.parse(localStorage.MicroTData);
		storedData.image=image;
		localStorage.setItem('MicroTData',JSON.stringify(storedData));
	},
	saveLoginData(payload){
		this.state.logged=true;
		this.state.accessToken=payload.token;
		this.state.accessTokenExpires=parseInt(payload.expiresIn);
		this.state.name=payload.name;
		this.state.step=payload.step;
		this.state.main_page=payload.main_page;
		this.state.permissions=payload.permissions;
		this.state.environments=payload.environments;
		this.state.image=payload.image;
		localStorage.setItem('MicroTData',JSON.stringify(payload));
	},
	updateLoginData(payload,bool){
		this.state.logged=true;
		this.state.accessToken=payload.token;
		this.state.accessTokenExpires=parseInt(payload.expiresIn);
		this.state.name=payload.name;
		this.state.step=payload.step;
		this.state.main_page=payload.main_page;
		this.state.permissions=payload.permissions;
		this.state.environments=payload.environments;
		this.state.image=payload.image;
		if(bool){
			localStorage.setItem('MicroTData',JSON.stringify(payload));
		}	
	},
	clearData(state){
		this.state.logged=false;
		this.state.accessToken='';
		this.state.accessTokenExpires='';
		this.state.name='';
		this.state.step='';
		this.state.main_page='';
		this.state.permissions={};
		this.state.environments=[];
		this.state.image='';
		localStorage.removeItem('MicroTData');
	}
}
