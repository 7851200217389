import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class Teams extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	currentPage: 1,
	  		translations:{},
	  		teams:{}
	  	};
	  	this.moduleName='team';
	}

	componentDidMount(){
		this.loadList(true);
	}

	loadList(withTranslations){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','teams?page='+oldState.currentPage)
 			.then(data=>{  		 				       	                 	            	
            	oldState.teams=data.teams;
            	if(withTranslations){
            		Helpers.getTranslations(this.moduleName)
			            .then(data=>{
			            	oldState.translations=data;			                						                
			                this.setState(oldState);
			            }).catch(error=>{
			            	oldState.translations=error;
			            	this.setState(oldState);
			            });
            	}else{
            		this.setState(oldState);
            	}       	
 			}).catch(error=>{
            	//	
        	});
	}

    deleteTeam(id){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete the selected line?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					Helpers.doRequest('delete','teams/'+id)
			            .then(data=>{
			            	this.loadList();
			            }).catch(error=>{
			            	//
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});		
	}

	handlePageTeams(data){
		let oldState=Object.assign({}, this.state);
        oldState.errors={};
        oldState.alerts=[]; 
        oldState.alertsField=[];
        oldState.currentPage=data.selected+1;
        Helpers.doRequest('get','teams?&page='+oldState.currentPage)
            .then(data=>{                                                                   
                oldState.teams=data.teams;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
	}

	render(){
	    let teamContent;
	    if(this.state.teams.list){
    		teamContent=this.state.teams.list.map((item, key) =>
    			<tr key={key}>
		        	<td onClick={()=>this.props.history.push("/teams/"+item.id)}>{ item.name }</td>		        			      
		        	<td className="border-right-0" onClick={(event)=>this.props.history.push("/teams/"+item.id)}>{ item.members }</td>
		        	<td className="text-center">
		        		<div className="text-right">
		        			<Link className="dropdown-item" to={"/teams/"+item.id}><i className="fas fa-pen"></i></Link>
		        			{this.props.permissions.team && this.props.permissions.team.full_control && !item.is_default &&
		        				<a href="#" className="dropdown-item" onClick={(event)=>this.deleteTeam(item.id)}><i className="fas fa-times-circle"></i></a>
		        			}
						</div>
		        	</td>
		        </tr>
		    );
    	} 
	    let teamPagination;
	    if(this.state.teams.page_count>1){
    		teamPagination=<ReactPaginate
				        previousLabel={'<'}
				        nextLabel={'>'}
				        breakLabel={'...'}
				        pageCount={this.state.teams.page_count}
				        marginPagesDisplayed={2}
				        pageRangeDisplayed={5}				        
				        onPageChange={(data)=>this.handlePageTeams(data)}
				        containerClassName={'pagination pagination-sm'}
				        pageClassName={'page-item'}
				        pageLinkClassName={'page-link'}
				        previousClassName={'page-item'}
				        nextClassName={'page-item'}
				        previousLinkClassName={'page-link'}
				        nextLinkClassName={'page-link'}
				        activeClassName={'active'} />
    	}
		return(
			<div className="content">
				<div className="col-sm-12 mt-5">
					<h4 className="text-left">{Helpers.translate('Teams',this.state.translations.general,'general')}</h4>
					{this.props.permissions.team && this.props.permissions.team.full_control && <div className="mt-3 text-left">
						<Button className="btn-general" disabled={(this.state.teams.allow_create ? false : true)} onClick={()=>(this.state.teams.allow_create ? this.props.history.push("/teams/new") : ()=>{})}>{Helpers.translate('Add new team',this.state.translations.team,'team')}</Button>
						{this.state.teams.list && !this.state.teams.allow_create && 
							<p className="mt-2">{Helpers.translate('You have reached the maximum numbers of teams',this.state.translations.team,'team')}</p>
						}
					</div>}
					<div className="mt-3 text-left">
						<Table responsive="sm" size="sm" className="text-left active_rows">
				  			<thead>
						      <tr className="table-secondary">
						        <th>{Helpers.translate('Team',this.state.translations.team,'team')}</th>
						        <th>{Helpers.translate('Team members',this.state.translations.general,'general')}</th>
						        <th></th>
						      </tr>
						    </thead>
						    <tbody>
						    	{ teamContent }
						    </tbody>
				  		</Table>
					</div>
					<div>{ teamPagination }</div>
				</div>			
		   	</div>
		);
	}

}
export default withRouter(Teams);