import React from 'react';
import Button from 'react-bootstrap/Button';
import Helpers from './../services/Helpers.js';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import CampaignMoreInfo from './CampaignMoreInfo.js';

export default class CampaignCard extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	      	show_modal:false,
	      	more_data:{} 	
	  	};
	}

	showMoreInfo(){
		let oldState=Object.assign({}, this.state);     
		Helpers.doRequest('get','trainings/campaign_more_info?campaign_language_id='+this.props.item.id)
 			.then(data=>{  		 				       	                 	            	
            	oldState.show_modal=true;
            	oldState.more_data=data.campaign;
            	this.setState(oldState);
 			}).catch(error=>{
            	//	
        	});
	}

	render(){
		return (
			<div className={"card_wrapper "+(this.props.container_class ? this.props.container_class : "col-lg-3 col-md-4 col-sm-6 col-xs-12")}>
				{this.props.item.selected && <i className="fas fa-check-circle text-success"></i>}
				<Card className="ml-0 mr-0 mb-4" border={(this.props.item.selected ? "success" : "")}>
    				<div className={ (this.props.showClick ? "pointer" : "") } onClick={()=>(this.props.showClick ? this.props.selectCampaign(this.props.index) : ()=>{})}>
	    				<Card.Img variant="top" src={this.props.item.campaign_image} />
	    				<Card.Body>
	    					<Card.Text className="ft-16 font-weight-bold text-justify mt-2 mb-2">{this.props.item.title}</Card.Text>
					      	<Card.Text className="text-justify description">{this.props.item.description}</Card.Text>
					      	<div className="row">
					      		<Card.Text className={"col-sm-12 col-md-12 d-inline-block border-right "+(this.props.showLastUpdated ? "col-lg-4" : "col-lg-6")}>
						      		<span className="font-weight-bold d-block">{Helpers.translate('Language',this.props.translations.general,'general')}</span>
						      		<span>{this.props.item.code.toUpperCase()}</span>
						      	</Card.Text>
						      	<Card.Text className={"col-sm-12 col-md-12 d-inline-block "+(this.props.showLastUpdated ? "col-lg-4 border-right" : "col-lg-6")}>
					      			<span className="font-weight-bold d-block">{Helpers.translate('Messages',this.props.translations.general,'general')}</span>
					      			<span>{this.props.item.total_ready_messages}</span>
						      	</Card.Text>
						      	{this.props.showLastUpdated && <Card.Text className="col-lg-4 col-sm-12 col-md-12 d-inline-block">
					      			<span className="font-weight-bold d-block">{Helpers.translate('Last updated',this.props.translations.general,'general')}</span>
						      		<span>{this.props.item.last_updated}</span>
						      	</Card.Text>}
					      	</div>				      						      	
					    </Card.Body>
    				</div>				
				    {this.props.showFooter && <Card.Footer className="text-right pr-0">
				      	<Button variant="link" size="sm" onClick={()=>this.showMoreInfo()}>{Helpers.translate('View more info',this.props.translations.general,'general')}</Button>
				    </Card.Footer>}
		        </Card>
		        <Modal
					show={this.state.show_modal}
			      	size="lg"
			      	aria-labelledby="contained-modal-label"
			      	centered
			      	backdrop="static"
			      	onHide={()=>Helpers.updateStateValue(this,false,'show_modal')}
			    >
				    <Modal.Body>
				    	<CampaignMoreInfo 
				    		campaign_language_id={this.props.item.id}
				    		data={this.state.more_data}
				    		translations={this.props.translations}
				    	/>
				    </Modal.Body>
				    <Modal.Footer>
		        		<Button onClick={()=>Helpers.updateStateValue(this,false,'show_modal')}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button>
		      		</Modal.Footer>
			    </Modal>
			</div>
		);
	}

}