import axios from 'axios';
import Store from './Store';

export default{
	state: {
		translations_empty:{},
		translations_send_tmsp:0,
		translations_delay:parseInt(process.env.REACT_APP_TRANSLATIONS_DELAY),
		translation_modules:{},
		translations:{}
	},
	doRequest(method,url,data,withoutLoading) {
		let baseURL = process.env.REACT_APP_API_URL;
		let loadingElement;
		if(!withoutLoading){
			loadingElement=document.getElementById('loading_wrap');
		}	
		if(loadingElement){
			loadingElement.classList.remove("hidden");
		}		
		let headers={};
		let processData=true;
		if (data && data.__proto__.constructor.name === 'FormData') {
			processData=false;
            headers = {
                "content-type": undefined
            }
            if(url.indexOf('login') === -1){
				headers.Authorization="Bearer " + Store.retrieveAccessToken();
			}
        }else{
        	headers={
				"content-type":"application/json"
			};
			if(url.indexOf('login') === -1){
				headers.Authorization="Bearer " + Store.retrieveAccessToken();
			}
        }
        if(!data){
        	data={};
        }
        return new Promise((resolve, reject)=>{
        	axios({
			  	method:method,
			  	url:baseURL+url,
			  	data:data,
			  	processData: processData,
			  	headers:headers
			}).then(response=>{
				if(loadingElement){
					loadingElement.classList.add("hidden");
				}	
			  	resolve(response.data);
			}).catch(error=>{
				if(loadingElement){
					loadingElement.classList.add("hidden");
				}
				if(error.response && error.response.status===401){
					Store.clearData();
					window.ee.emit('storeChange');
				}
			  	reject(error.response.data);
			});
        });
	},
	showAlerts(scope,input,formObj,localScope){
		let oldState=Object.assign({}, scope.state);
		if(!oldState.alerts){oldState.alerts=[]};
		if(!oldState.alertsField){oldState.alertsField=[]};
		oldState.errors={};
		oldState.success={};
		oldState.alerts=[];
		oldState.alertsField=[];
		if(input.errors){
			if(input.errors.error){
				let alerts=[];
				alerts.push({
					type: 'danger',
	                msg: input.errors.error
				});
				if(localScope){
					oldState.alerts=alerts;
				}else{
					window.ee.emit('notificationData',alerts);
				}			
			}else{
				let alerts=[];
				let doNotify=false;
				for(let x in input.errors){
					if(formObj){
						oldState.alertsField[x]=input.errors[x][0];
						oldState.errors[x]=true;
					}else{
						alerts.push({
							type: 'danger',
			                msg: input.errors[x][0]
						});
						doNotify=true;
					}				
				}
				if(doNotify){
					if(localScope){
						oldState.alerts=alerts;
					}else{
						window.ee.emit('notificationData',alerts);
					}				
				}
			}
		}else if(input.success){
			if(input.success.success){
				let alerts=[];
				alerts.push({
					type: 'success',
	                msg: input.success.success
				});
				if(localScope){
					oldState.alerts=alerts;
				}else{
					window.ee.emit('notificationData',alerts);
				}			
			}else{
				for(let x in input.success){
					if(formObj){
						oldState.alertsField[x]=input.success[x][0];
						oldState.success[x]=true;
					}				
				}
			}
		}
		scope.setState(oldState);
	},
	closeAlert(scope,index){
		let oldState=Object.assign({}, scope.state);
		oldState.alerts.splice(index,1);
		scope.setState(oldState);
	},
	getTranslations(module){
		let translations={};
		return new Promise((resolve, reject)=>{		
			let doRun=false;
			if(!this.state.translations['microtrans_'+module+'_tmsp'] || (this.state.translations['microtrans_'+module+'_tmsp'] && (parseInt(this.state.translations['microtrans_'+module+'_tmsp']) < Math.floor(Date.now() / 1000)))){
				doRun=true;
			}
			if(doRun){	
				let general_param='';
				if(!this.state.translations['microtrans_general_tmsp'] || (this.state.translations['microtrans_general_tmsp'] && (parseInt(this.state.translations['microtrans_general_tmsp'])<Math.floor(Date.now() / 1000)))){
					general_param+='&module_general=1';
				}
				this.doRequest('get','translations?module='+module+general_param,{},true)
	 			.then(data=>{
	 				this.state.translations['microtrans_'+module]=data['microtrans_'+module];
	 				this.state.translations['microtrans_'+module+'_tmsp']=parseInt(Math.floor(Date.now() / 1000)+parseInt(data.microtrans_module_expiresIn));  	
	            	translations[module]=data['microtrans_'+module];
	            	if(data['microtrans_general']){
	            		this.state.translations['microtrans_general']=data['microtrans_general'];
	            		this.state.translations['microtrans_general_tmsp']=parseInt(Math.floor(Date.now() / 1000)+parseInt(data.microtrans_general_expiresIn));  	
	            		translations['general']=data['microtrans_general'];
	            	}
	            	resolve(translations);       	
	 			}).catch(error=>{
	            	resolve(translations);
	        	});
			}else{
				if(this.state.translations['microtrans_'+module]){
					translations[module]=this.state.translations['microtrans_'+module];
				}			
				if(this.state.translations['microtrans_general']){
					translations['general']=this.state.translations['microtrans_general'];
				}
				resolve(translations);
			}
		});	
	},
	showLoading(){
		let loadingElement=document.getElementById('loading_wrap');
		if(loadingElement){
			loadingElement.classList.remove("hidden");
		}
	},
	stopLoading(){
		let loadingElement=document.getElementById('loading_wrap');
		if(loadingElement){
			loadingElement.classList.add("hidden");
		}
	},
	translate(word,translation,module){
		if(word === undefined){
			return;
		}else if(word === '' || word === '-'){
			return word;
		}
		let translatedWord;
		if(this.state.translations['microtrans_'+module] && this.state.translations['microtrans_'+module][btoa(word)]){
			if(module && this.state.translations_empty[module] && this.state.translations_empty[module][btoa(word)] === ''){
				delete this.state.translations_empty[module][btoa(word)];
			}
			translatedWord=this.state.translations['microtrans_'+module][btoa(word)];
		}else if(translation && translation[btoa(word)]){
			if(module && this.state.translations_empty[module] && this.state.translations_empty[module][btoa(word)] === ''){
				delete this.state.translations_empty[module][btoa(word)];
			}
			translatedWord=translation[btoa(word)];
		}else{
			if(module && !this.state.translations_empty[module]){
				this.state.translations_empty[module]={};
			}
			if(module){
				this.state.translations_empty[module][btoa(word)]='';
			}			
			translatedWord=word;
		}
		if(this.state.translations_send_tmsp === 0){
			this.state.translations_send_tmsp=parseInt(Math.floor(Date.now() / 1000)+this.state.translations_delay);
		}else if(process.env.NODE_ENV === 'development' && this.state.translations_send_tmsp>0 && this.state.translations_send_tmsp<parseInt(Math.floor(Date.now() / 1000))){
			this.state.translations_send_tmsp=parseInt(Math.floor(Date.now() / 1000)+this.state.translations_delay);
			axios({
			  	method:'post',
			  	url:process.env.REACT_APP_API_URL+'save_translations',
			  	data:{'translations':this.state.translations_empty},
			  	headers:{"content-type":"application/json","Authorization":"Bearer " + Store.retrieveAccessToken()}
			});
		}
		return translatedWord;
	},
	clearTranslations(){
		this.state.translations={};
	},
	converterDataURItoBlob(dataURI) {
	    let byteString;
	    let mimeString;
	    let ia;

	    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
	      byteString = atob(dataURI.split(',')[1]);
	    } else {
	      byteString = encodeURI(dataURI.split(',')[1]);
	    }
	    // separate out the mime component
	    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	    // write the bytes of the string to a typed array
	    ia = new Uint8Array(byteString.length);
	    for (var i = 0; i < byteString.length; i++) {
	      ia[i] = byteString.charCodeAt(i);
	    }
	    return new Blob([ia], {type:mimeString});
	},
	isValidDate(date) {
	    try{
	      date.toISOString();
	      return true;
	    }
	    catch(ex){
	      return false;    
	    }
	},
	localAsUtc(date) {
	    return new Date(Date.UTC(
	        date.getFullYear(),
	        date.getMonth(),
	        date.getDate(),
	        date.getHours(),
	        date.getMinutes(),
	        date.getSeconds(),
	        date.getMilliseconds()
	    ));
	},
	adjustDateTimezone(date){
    	date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));
    	return date;
	},
	submitForm(scope,e,functionName){
		if(e.key === "Enter"){
			e.preventDefault();
			scope[functionName]();
		}
	},
	updateStateValue(scope,value,model,parent){
        let oldState=Object.assign({}, scope.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		oldState[parent][model]=value;
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        oldState.errors={};
        scope.setState(oldState);  
    }
}
