import React from 'react';
import Helpers from './../services/Helpers.js';
import Table from 'react-bootstrap/Table';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';

export default class Users extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	users:this.props.users,
	      	search:{
	      		page:1,
	      		order_by:"email",
	      		order:"asc"
	      	}     	
	  	};
	}

	loadList(value,model){
    	let oldState=Object.assign({}, this.state);
        oldState.errors={};
        oldState.alerts=[]; 
        oldState.alertsField=[];
        oldState.search[model]=value;
        let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        urlString=urlString.replace("&", "?");
        Helpers.doRequest('get','users'+urlString)
            .then(data=>{                                                                   
                oldState.users=data.users;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

    sortList(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.search.page=1;
    	if(oldState.search.order_by !== value){
    		oldState.search.order='asc';
    		oldState.search.order_by=value;  
    	}else{
    		oldState.search.order_by=value;  
    		if(oldState.search.order === 'asc'){
	    		oldState.search.order = 'desc';
	    	}else if(oldState.search.order === 'desc'){
	    		oldState.search.order = '';
	    		oldState.search.order_by='';  
	    	}else{
	    		oldState.search.order = 'asc';
	    	}
    	}   		
    	let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        urlString=urlString.replace("&", "?");
        Helpers.doRequest('get','users'+urlString)
            .then(data=>{                                                                   
                oldState.users=data.users;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

    deleteUser(id){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete the selected line(s)?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();	
		            let oldState=Object.assign({}, this.state);
		            oldState.errors={};
					oldState.alerts=[];	
					oldState.success={};
				    oldState.alertsField=[];
				    oldState.search.page=1;
				    let obj={};
				    obj.user_id=id;
				    for(let x in oldState.search){
			        	obj[x]=oldState.search[x];
			        }
				    Helpers.doRequest('post','users/delete',obj)
		 			.then(data=>{  		
		 				oldState.users=data.users; 				       	                 	            	         	
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

	render(){
		const usersContent=this.state.users.list.map((item, key) =>
		        <tr key={key}>
		        	<td onClick={()=>this.props.editUser(item.id)}>{ item.name }</td>
		        	<td onClick={()=>this.props.editUser(item.id)}>{ item.email }</td>	        	
		        	<td onClick={()=>this.props.editUser(item.id)}>{ item.role }</td>	
		        	<td className="text-left" onClick={()=>this.props.editUser(item.id)}>
		        		{(item.restricted_teams.length>0 ? 
		        				<ul className="mb-0">
		        					{ item.restricted_teams.map((subitem,subkey) => 
			    							<li key={subkey}>{ subitem.name }</li>	    								
			    						)
			    					}
		        				</ul>
		        			: 
		        		null)}
		        	</td>
		        	<td className="text-right">
		        		<a href="#" className="dropdown-item" onClick={(event)=>this.props.editUser(item.id)}><i className="fas fa-pen"></i></a>
		        		{
							!item.logged_user && <a href="#" className="dropdown-item" onClick={(event)=>this.deleteUser(item.id)}><i className="fas fa-times-circle"></i></a>
						}
		        	</td>
		        </tr>
		    ); 
    	let usersPagination;
	    if(this.state.users.page_count>1){
	    	let forcePageObj = {}
			if (this.state.search.page === 1) {
			    forcePageObj['forcePage'] = 0;
			}
    		usersPagination=<ReactPaginate
				        previousLabel={'<'}
				        nextLabel={'>'}
				        breakLabel={'...'}
				        pageCount={this.state.users.page_count}
				        marginPagesDisplayed={2}
				        pageRangeDisplayed={5}	
				        {...forcePageObj}			        
				        onPageChange={(data)=>this.loadList(parseInt(data.selected)+1,'page')}
				        containerClassName={'pagination pagination-sm'}
				        pageClassName={'page-item'}
				        pageLinkClassName={'page-link'}
				        previousClassName={'page-item'}
				        nextClassName={'page-item'}
				        previousLinkClassName={'page-link'}
				        nextLinkClassName={'page-link'}
				        activeClassName={'active'} />
		}
		return (
    		<div>
				<Table responsive="sm" size="sm" className="text-left active_rows">
		  			<thead>
				      <tr>
				      	<th className="pointer" onClick={()=>this.sortList("name")}>
				        	<span>{Helpers.translate('Name',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'name' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th className="pointer" onClick={()=>this.sortList("email")}>
				        	<span>{Helpers.translate('Email',this.props.translations.general,'general')}</span>
				        	{
				        		(this.state.search.order && this.state.search.order_by === 'email' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
				        	}
				        </th>
				        <th>{Helpers.translate('Role',this.props.translations.administration,'administration')}</th>
				        <th>{Helpers.translate('Teams',this.props.translations.general,'general')}</th>
				        <th className="text-center"></th>
				      </tr>
				    </thead>		    
				    <tbody>{ usersContent }</tbody>
		  		</Table>
		  		<div className="clearfix text-center">{ usersPagination }</div>
		   	</div>
    	);
	}

}