import React from 'react';
import Form from 'react-bootstrap/Form';
import Helpers from './../services/Helpers.js';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ReactSortable } from 'react-sortablejs';

export default class Messages extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	messages:this.props.messages
	  	};
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		oldState[parent][model]=value;
	        	if(model === 'global_check'){
	        		for(let x in this.state.messages.list){
	        			oldState.messages.list[x].checked=value;
	        		}
	        	}
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        this.setState(oldState);  
    }

	updateMessagesCheck(value,index){
    	let oldState=Object.assign({}, this.state);
		oldState.messages.list[index].checked=value;
		this.setState(oldState); 
    }

    deleteMessages(id){
    	let itemsToDelete=[];
    	if(!id){
    		let itemsSelected=false;
    		for(let x in this.state.messages.list){
    			if(this.state.messages.list[x].checked){
    				itemsSelected=true;
    				itemsToDelete.push({'message_id':this.state.messages.list[x].id});
    			}
    		}
    		if(!itemsSelected){
    			this.props.showError({'errors':{'error':Helpers.translate('Select at least one item',this.props.translations.general,'general')}});
    			return false;
    		}  		
    	}else{
    		itemsToDelete.push({'message_id':id});
    	}
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete the selected line(s)?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let oldState=Object.assign({}, this.state);
		            oldState.errors={};
					oldState.alerts=[];	
					oldState.success={};
				    oldState.alertsField=[];
				    let obj={};
				    obj.messages=itemsToDelete;
				    obj.campaign_id=this.props.campaign_id;
				    Helpers.doRequest('post','messages/delete',obj)
		 			.then(data=>{  		
		 				oldState.messages=data.messages; 				       	                 	            	         	
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    handleSort(evt){
    	if(evt.oldIndex !== evt.newIndex){
    		let oldState=Object.assign({}, this.state);
    		const movedRow = oldState.messages.list[evt.oldIndex];
			oldState.messages.list.splice(evt.oldIndex, 1);
      		oldState.messages.list.splice(evt.newIndex, 0, movedRow);
      		let sequence_order=[];
      		for(let x in oldState.messages.list){
      			oldState.messages.list[x].sequence_order=(parseInt(x)+1);
      			sequence_order.push({'message_id':oldState.messages.list[x].id});
      		}
      		oldState.errors={};
			oldState.alerts=[];	
			oldState.success={};
		    oldState.alertsField=[];
		    this.setState(oldState);
		    let obj={};
		    obj.sequence_order=sequence_order;
		    obj.campaign_id=this.props.campaign_id;
		    Helpers.doRequest('post','messages/reorder',obj);
    	}
    }

	render(){
		const messagesContent=this.state.messages.list.map((item, key) =>
		        <tr key={key}>
		        	<td onClick={(event)=>this.props.editMessage(item.id)}>{ item.sequence_order }</td>
		        	<td onClick={(event)=>this.props.editMessage(item.id)}>{ item.name }</td>
		        	<td onClick={(event)=>this.props.editMessage(item.id)}>{ item.type }</td>
		        	<td className={ (item.languages.length > 1 ? "p-0" : "") } onClick={(event)=>this.props.editMessage(item.id)}>
		        		{
		        			(item.languages.length === 1 ? (item.languages[0].code.toUpperCase()) : 
		        				<Table responsive="sm" className="mb-0">
		        					<tbody>
		        					{ item.languages.map((subitem,subkey) => 
		        							<tr key={subkey}><td className={ (subkey === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0") }>{ subitem.code.toUpperCase() }</td></tr>
		        						)
		        					}
		        					</tbody>
		        				</Table>
		        			) 
		        		}
		        	</td>
		        	<td className={ (item.languages.length === 1 ? (item.languages[0].status === 1 ? "bg-success" : "bg-warning") : "p-0") } onClick={(event)=>this.props.editMessage(item.id)}>
		        		{
		        			(item.languages.length === 1 ? (item.languages[0].status_name) : 
		        				<Table responsive="sm" className="mb-0">
		        					<tbody>
		        					{ item.languages.map((subitem,subkey) => 
		        							<tr className={(subitem.status === 1 ? "bg-success" : "bg-warning")} key={subkey}><td className={ (subkey === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0") }>{ Helpers.translate(subitem.status_name,this.props.translations.general,'general') }</td></tr>
		        						)
		        					}
		        					</tbody>
		        				</Table>
		        			) 
		        		}
		        	</td>
		        	<td className="hidden">
		        		<Form.Group controlId={"message_check_"+key} key={key}>
						    <Form.Check 
						    	type="checkbox" 
						    	custom 
						    	label="" 
						    	onChange={(e)=>this.updateMessagesCheck(e.target.checked,key)} 
						    	checked={item.checked && item.checked === true ? 'checked' : ''}
						    />
						</Form.Group>
		        	</td>
		        	<td className="text-right">
		        		<a href="#" className="dropdown-item" onClick={(event)=>this.props.editMessage(item.id)}><i className="fas fa-pen"></i></a>
		        		<a href="#" className="dropdown-item" onClick={(event)=>this.deleteMessages(item.id)}><i className="fas fa-times-circle"></i></a>
		        	</td>
		        </tr>
		    );  
		return (
			<div>
				<Table responsive="sm" size="sm" className="text-left active_rows">
		  			<thead>
				      <tr>
				        <th>{Helpers.translate('Sequence',this.props.translations.campaign,'campaign')}</th>
				        <th>{Helpers.translate('Message',this.props.translations.general,'general')}</th>
				        <th>{Helpers.translate('Message type',this.props.translations.campaign,'campaign')}</th>
				        <th>{Helpers.translate('Language',this.props.translations.general,'general')}</th>
				        <th>{Helpers.translate('Language status',this.props.translations.campaign,'campaign')}</th>
				        <th className="text-center hidden">
				        	<Form.Group className="mb-0" controlId="message_global_check">
					        	<Form.Check 
								    type="checkbox" 
								    custom 
								    label=""
								    onChange={(e)=>this.updateStateValue(e.target.checked,'global_check','messages')} 
								    checked={this.state.messages.global_check && this.state.messages.global_check === true ? 'checked' : ''}
								/>
							</Form.Group>
				    	</th>
				        <th className="text-center">
				        	<div className="hidden">
								<DropdownButton alignRight variant="success" size="sm"
								title=''>
									<Dropdown.Item onClick={(event)=>this.deleteMessages()}>{Helpers.translate('Delete selected',this.props.translations.general,'general')}</Dropdown.Item>
								</DropdownButton>
							</div>
				        </th>
				      </tr>
				    </thead>
				    <ReactSortable
	                    tag="tbody" 
	                    list={this.state.messages.list}
	                    setList={(newState) => ()=>{}}
	                    onEnd={(evt) => this.handleSort(evt)}>			    
				    	{ messagesContent }
				    </ReactSortable>
		  		</Table>
		  		{this.state.messages.allow_create && <div className="mt-3 text-left">
		  			<Button onClick={()=>this.props.editMessage('new')}>{Helpers.translate('Add new message',this.props.translations.campaign,'campaign')}</Button>				    						  								
				</div>}
				{this.state.messages.list && !this.state.messages.allow_create && <div className="mt-3 text-left">
		  			<p className="mt-2">{Helpers.translate('You have reached the maximum number of messages',this.props.translations.campaign,'campaign')}</p>
				</div>}
		   	</div>
		);
	}

}