import Helpers from './Helpers.js';

export default{

	checkValidity(rules,state,propertyTranslations,generalTranslations,noTranslation){
		let responseObj={'has_errors':false,'errors':{}};
		for(let x in rules){
			for(let y in rules[x]){		
				let error_masg=this.errorMessage(rules[x][y],x,state,propertyTranslations,generalTranslations,noTranslation);
                if(error_masg){
					if(!responseObj.has_errors){
	                    responseObj.has_errors=true;
	                }
	                if(!responseObj.errors[x]){
	                	responseObj.errors[x]=[];
	                }
	                responseObj.errors[x].push(error_masg);
					break;
				}
			}
		}
		return responseObj;
    },

    errorMessage(type,name,state,propertyTranslations,generalTranslations,noTranslation){
        let msg="";
        switch(true){
            case /required/.test(type):
            	if(!state[name]){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : Helpers.translate('field is required',generalTranslations,'general'));
            	}                
                break;
            case /float/.test(type):
            	if(isNaN(state[name])){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field needs to be a number' : Helpers.translate('field needs to be a number',generalTranslations,'general'));
            	}
            	break;
            case /not_empty/.test(type):
            	if(!state[name].length){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : Helpers.translate('field is required',generalTranslations,'general'));
            	}
            	break;
            case /email/.test(type):
                let regTest=/\S+@\S+\.\S+/;
                if(!regTest.test(state[name])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be a valid email address' : Helpers.translate('field must be a valid email address',generalTranslations,'general'));
                }
                break;
            case /min/.test(type):
                let splittedMin=type.split(":");
                if(state[name] && parseInt(state[name].length)<parseInt(splittedMin[1])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be at least '+splittedMin[1]+' characters' : Helpers.translate('field must be at least',generalTranslations,'general')+" "+splittedMin[1]+" "+Helpers.translate('characters',generalTranslations,'general'));
                }
                break;
            case /max/.test(type):
                let splittedMax=type.split(":");
                if(state[name] && parseInt(state[name].length)>parseInt(splittedMax[1])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'may not be greater than '+splittedMax[1]+' characters' : Helpers.translate('may not be greater than',generalTranslations,'general')+" "+splittedMax[1]+" "+Helpers.translate('characters',generalTranslations,'general'));
                }
                break;
            case /alpha_lower_numeric/.test(type):
                if(/^[a-z0-9]+$/.test(state[name]) === false){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'may only contain lowercase letters and numbers' : Helpers.translate('may only contain lowercase letters and numbers',generalTranslations,'general'));
                }
                break;
            case /alpha_numeric/.test(type):
                if(/^[a-zA-Z0-9]+$/.test(state[name]) === false){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'may only contain letters and numbers' : Helpers.translate('may only contain letters and numbers',generalTranslations,'general'));
                }
                break;
            case /checked/.test(type):
                let splittedCheck=type.split(":");
                if(state[name]){
                    let checkCount=0;
                    for(let x in state[name]){
                        if(state[name][x].checked){
                            checkCount++;
                        }
                    }
                    if(!checkCount){
                        msg=(noTranslation ? 'At least '+splittedCheck[1]+' option must be selected' : Helpers.translate('At least',generalTranslations,'general')+" "+splittedCheck[1]+" "+Helpers.translate('option must be selected',generalTranslations,'general'));
                    }               
                }
                break;
            case /selected/.test(type):
                let splittedSelect=type.split(":");
                if(state[name]){
                    let selectedCount=0;
                    for(let x in state[name]){
                        if(state[name][x].checked){
                            selectedCount++;
                        }
                    }
                    if(!selectedCount || (selectedCount !== parseInt(splittedSelect[1]))){
                        msg=(noTranslation ? splittedSelect[1]+' option must be selected' : splittedSelect[1]+" "+Helpers.translate('option must be selected',generalTranslations,'general'));
                    }               
                }
                break;
            case /permission/.test(type):
                let permissionCheck=type.split(":");
                if(state[name]){
                    let permissionCount=0;
                    for(let x in state[name]){
                        if(state[name][x].checked){
                            permissionCount++;
                        }else{
                            if(state[name][x].extra.length>0){
                                for(let y in state[name][x].extra){
                                    if(state[name][x].extra[y].checked){
                                        permissionCount++;
                                    }
                                }
                            }
                        }
                    }
                    if(!permissionCount){
                        msg=(noTranslation ? 'At least '+permissionCheck[1]+' option must be selected' : Helpers.translate('At least',generalTranslations,'general')+" "+permissionCheck[1]+" "+Helpers.translate('option must be selected',generalTranslations,'general'));
                    }               
                }
                break;
            default:
        }
        return msg;
    }

}
