import React from 'react';
import Helpers from './../services/Helpers.js';

export default class TrainingMessage extends React.Component{

	render(){
		let answers;
		if(this.props.data.answers.list && this.props.data.answers.list.length>0){
			answers=this.props.data.answers.list.map((item, key) =>
		        <tr key={key}>
		        	<td>
		        		<span>{ item.name }</span>
		        		{
		        			(item.correct ? <i className="fas fa-check text-success ml-2"></i> : null)
		        		}
		        	</td>
		        	<td style={ (item.total_rate_rgb ? {backgroundColor:item.total_rate_rgb} : {}) }>{ item.total }</td>
		        	<td style={ (item.total_rate_rgb ? {backgroundColor:item.total_rate_rgb} : {}) }>{ item.total_rate } %</td>
		        </tr>
		    );
		}

		return(
			<React.Fragment>
				<table className="table table-sm table-responsive-sm table-bordered text-center active_rows">
					<thead className="thead-dark">
						<tr>
							<th>{Helpers.translate('Options',this.props.translations.general,'general')}</th>
				        	<th>{Helpers.translate('Answers',this.props.translations.general,'general')}</th>
				        	<th>%</th>
						</tr>
					</thead>
					<tbody>{answers}</tbody>
				</table>
				<table className="mt-3 table table-sm table-responsive-sm table-bordered text-center active_rows">
					<thead className="thead-dark">
						<tr>
							<th>{Helpers.translate('Unsubscribes',this.props.translations.general,'general')}</th>
				        	<th>{Helpers.translate('Bounces',this.props.translations.general,'general')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{this.props.data.unsubscribes_number}</td>
							<td>{this.props.data.bounce_number}</td>
						</tr>
					</tbody>
				</table>
			</React.Fragment>
		);
	}

}