import React from 'react';
import './Login.css';
import Helpers from './../../services/Helpers.js';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';

class Login extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
				logoMessage: 'Get started.',
		  	alerts:[],
	      	alertsField:[],
	      	showSignUp:false
	  	};
	}

	signInMicrosoft() {
 		Helpers.doRequest('get','login/microsoft')
 			.then(data=>{
            	window.location.href = data.redirect;
 			}).catch(error=>{
            	Helpers.showAlerts(this,error);
        	});
	}

	signInGoogle() {
 		Helpers.doRequest('get','login/google')
 			.then(data=>{
 				
            	window.location.href = data.redirect;
 			}).catch(error=>{
            	Helpers.showAlerts(this,error);
        	});
	}

	signUp(){

		let genThis=this;
		Helpers.showLoading();
		setTimeout(function(){
			Helpers.stopLoading();
			genThis.setState({'showSignUp':true, 'logoMessage': 'Only a few steps until your first Microtraining account is live. Continue with:'});
		},1500);
	}

	render(){
		const generalErrors = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return (
			<div className="page-login">
				<div className="page-login-vertical">
					<div className="page-login-box-login text-left">
						<div className="row">
							<div className="col-sm-12">
								<div className="round col-sm-4 text-center">Login</div>


									{!this.state.showSignUp && <div className="">
										<label className="ft-60 font-weight-bold form-label mt-3">{this.state.logoMessage}</label>
										<label className="ft-20 text-gray">Effortless knowledge distribution and lasting retention.</label>
									</div>}
									{this.state.showSignUp && <div className="">
									<label className="ft-30 font-weight-bold form-label mt-3">{this.state.logoMessage}</label>
									</div>}
								
									
									
									
							</div>
						</div>
						<div className="row">
							<div className="page-login-panel p-0">
								{generalErrors}
								<form className="form-horizontal text-center" name="login_form">
									{!this.state.showSignUp && <div className="form-group page-login-actions">
										<div className="col-sm-6 text-center float-left mt-1">
											<button type="button" className="btn btn-lg btn-default btn-white" onClick={() => this.signInGoogle()}>
												<img alt=""  width="50"  src={require('./../../assets/images/google.png')} />
												<span className="button-text">Login  with Google</span>
											</button>
										</div>
										<div className="col-sm-6 text-center float-left mt-1">
											<button type="button" className="btn btn-lg btn-default btn-white" onClick={() => this.signInMicrosoft()}>
												<img alt="" src={require('./../../assets/images/microsoft.png')} />
												<span className="button-text">Login  with Microsoft</span>
											</button>
										</div>
										<div className="col-sm-12  col-md-12  text-center float-left">
											<hr className="hr-text" data-content="or create a new account" />
										</div>
										<div className="col-sm-12 text-center float-left">
											<Button className="btn-general   pl-5 pr-5 pt-3 pb-3" onClick={() => this.signUp()}>Create  account</Button>
										</div>
									</div>}
									{this.state.showSignUp && <div className="form-group page-login-actions">
									<div class="row">
										<div className="col-sm-6 text-center float-left">
											<button type="button" className="btn btn-lg btn-default btn-white" onClick={() => this.signInGoogle()}>
												<img alt="" width="50" src={require('./../../assets/images/google.png')} />
												<span className="button-text">Continue with Google</span>
											</button>
										</div>
										<div className="col-sm-6 text-center float-left">
											<button type="button" className="btn btn-lg btn-default btn-white" onClick={() => this.signInMicrosoft()}>
												<img alt="" src={require('./../../assets/images/microsoft.png')} />
												<span className="button-text">Continue with Microsoft</span>
											</button>
										</div>
										</div>
									</div>}
								</form>
							</div>
						</div>
					</div>
			   	</div>
		   	</div>
		)
	}
}
export default withRouter(Login);
