import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Role extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		create:true,
	  		show_content:false,
	  		role:{}
	  	};
	  	this.moduleName='administration';
	  	this.role_validations={
	  		"name":["required","min:3","max:191"],
	  		"permissions":["required","permission:1"]
	  	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','roles/'+this.props.match.params.id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.create=false;
		            	oldState.show_content=true;
		            	oldState.role=data.role;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}else{	
 				Helpers.doRequest('get','roles/create')
		 			.then(data=>{  		 				       	                 	       
		            	oldState.show_content=true;
		            	oldState.role=data.role;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}		
		}		
	}

	updatePermissions(value,index,subindex){
		let oldState=Object.assign({}, this.state);
		if(oldState.role.permissions[index].identifier === 'environment'){
			oldState.role.permissions[index].checked=value;
			for(let x in oldState.role.permissions){
				if(oldState.role.permissions[x].identifier === 'environment'){
					continue;
				}
				oldState.role.permissions[x].checked=value;
				oldState.role.permissions[x].disabled=value ? true : false;
				if(oldState.role.permissions[x].extra.length>0){
					for(let y in oldState.role.permissions[x].extra){
						oldState.role.permissions[x].extra[y].checked=value;
						oldState.role.permissions[x].extra[y].disabled=value ? true : false;
					}
				}			
			}	
		}else{
			if(subindex !== undefined){
				oldState.role.permissions[index].extra[subindex].checked=value;
			}else{
				oldState.role.permissions[index].checked=value;
				if(oldState.role.permissions[index].extra.length>0){
					for(let x in oldState.role.permissions[index].extra){
						oldState.role.permissions[index].extra[x].checked=value;
						oldState.role.permissions[index].extra[x].disabled=value ? true : false;
					}
				}
			}			
		}
		this.setState(oldState); 
	}

	saveRole(){
		let fieldTranslations={
			"name":Helpers.translate('This',this.state.translations.general,'general'),
    		"permissions":Helpers.translate('This',this.state.translations.general,'general')
    	};			
		let formErrors=FormValidation.checkValidity(this.role_validations,this.state.role,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.role);
		if(this.state.role.id){
			Helpers.doRequest('put','roles/'+this.state.role.id,obj)
	            .then(data=>{
	            	this.props.history.push("/administration",{"msg":data,"tab":"roles"});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}else{
			Helpers.doRequest('post','roles',obj)
	            .then(data=>{
	            	this.props.history.push("/administration",{"msg":data,"tab":"roles"});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}
	}

	render(){
    	let permissions;
		if(this.state.role.permissions){
			permissions=this.state.role.permissions.map((item, key)=>
					<Form.Group className="width_100" controlId={"permission_check_"+key} key={key}>
					    <Form.Label className="ft-16 font-weight-bold">{Helpers.translate(item.title,this.state.translations.administration,'administration')}</Form.Label>
					    <Form.Check 
						    type="checkbox" 
						    custom 
						    label={Helpers.translate(item.subtitle,this.state.translations.administration,'administration')} 
						    onChange={(e)=>this.updatePermissions(e.target.checked,key)} 
						    checked={item.checked && item.checked === true ? 'checked' : ''}
						    disabled={(item.disabled && item.disabled === true) || this.state.role.block_edit ? true : false}
					    />
					    {
					    	(item.extra.length > 0 ?
					    			item.extra.map((subitem, subkey)=>
					    				<Form.Group className="ml-4" controlId={"permission_extra_"+item.identifier+"_"+subitem.identifier} key={subkey}>
					    					<Form.Check 
											    type="checkbox" 
											    custom 
											    label={Helpers.translate(subitem.name,this.state.translations.administration,'administration')} 
											    onChange={(e)=>this.updatePermissions(e.target.checked,key,subkey)} 
											    checked={subitem.checked && subitem.checked === true ? 'checked' : ''}
											    disabled={(subitem.disabled && subitem.disabled === true) || this.state.role.block_edit ? true : false}
											   />
					    				</Form.Group>
					    			)
					    		: null)
					    }
					</Form.Group>									      
    			);
		}
		return (
			<div className="content">
				{this.state.show_content && <div className="col-sm-8 mt-5">
					<div className="back-button text-left">
						<Link to={{ pathname: "/administration", state:{"tab": "roles" }}}><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to roles overview',this.state.translations.training,'administration')}</span></Link>
					</div>
					<h3 className="text-left mt-3">
						{ (this.state.create ? <span>{Helpers.translate('New role',this.state.translations.administration,'administration')}</span> : <span>{Helpers.translate('Edit role',this.state.translations.administration,'administration')}</span>) }					
					</h3>
					<div>
				    	<Form className="text-left width_100 mt-3">
				    		<Form.Group>
						    	<Form.Label className="ft-16 font-weight-bold">
						    		<span>{Helpers.translate('Name',this.state.translations.general,'general')}</span>
						    	</Form.Label>
						    	<Form.Control type="text" 
						    		value={this.state.role.name || ''} 
						    		onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'name','role')}
						    		name="name" 
						    		placeholder={Helpers.translate('Name',this.state.translations.general,'general')} 
						    		autoComplete="off" 
						    		disabled={this.state.role.block_edit}/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Select permission(s)',this.state.translations.general,'general')}</Form.Label>
						    	<div>{ permissions }</div>			    									    	
						  		{this.state.errors.permissions && <p className="text-danger mt-1">{this.state.alertsField.permissions}</p>}
						  	</Form.Group>
						  	<div className="mt-3 text-left">
								<Button onClick={()=>this.saveRole()} disabled={this.state.role.block_edit}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
							</div>					
				    	</Form>
				    </div>
				</div>}
		   	</div>
		);
	}

}
export default withRouter(Role);