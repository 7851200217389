import React from 'react';
import { withRouter } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import * as loadImage from 'blueimp-load-image';
import Store from './../../services/Store.js';

class MyAccount extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		account:{}, 
	  		show_content:false,
	  		timezoneLoading:false
	  	};
	  	this.moduleName='myaccount';
	  	this.validations={
	  		"name":["required"],
	  		"language":["required","not_empty"]
	  	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','myaccount')
 			.then(data=>{  		 				       	                 	            	
            	oldState.show_content=true;
            	oldState.account=data.account;
            	Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
 			}).catch(error=>{
            	//	
        	});
	}

	onImageDrop(acceptedFiles){
    	if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		if(parseInt(acceptedFiles[0].size/1000) > parseInt(this.state.account.image_max_size/1000)){
	    			let errorMsg={'errors':{'error':Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')+" "+this.state.account.image_max_size/1000+"Kb"}};
	    			Helpers.showAlerts(this,errorMsg);
	    			return false;
	    		}  		
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(acceptedFiles[0], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(acceptedFiles[0], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(acceptedFiles[0].type));
				  		convertedImage.preview=URL.createObjectURL(convertedImage);
				  		Helpers.stopLoading();	
				  		oldState.account.image=convertedImage;
				    	this.setState(oldState); 
				  	}, loadImageOptions)
				});	    
	    	}    	
	    }
    }

    timezoneSearch(search,action){
    	if(action === 'input-change'){
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{  
	        	this.setState({timezoneLoading:true}) 
	            Helpers.doRequest('get','timezones?search='+search,{},true)
		            .then(data=>{
		            	oldState.timezoneLoading=false;
		                oldState.account.timezones=data.timezones;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

	saveAccount(){
		let fieldTranslations={
    		"name":Helpers.translate('This',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.account,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let genThis=this;
		let formData = new FormData();
		formData.append("name", this.state.account.name);
		formData.append("language", this.state.account.language[0].code);
		if(Array.isArray(this.state.account.timezone) && this.state.account.timezone.length){
			formData.append("timezone", this.state.account.timezone[0].code);
		}else{
			formData.append("timezone", "");
		}
		if(this.state.account.image !== null){
			formData.append("image", this.state.account.image);
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'myaccount', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            		let emit_changes=false;
            		if(genThis.state.account.name !== Store.retrieveUserName()){
            			Store.setUserName(genThis.state.account.name);
            			emit_changes=true;      			
            		}
            		if(data.language_changed){
            			emit_changes=true;
            			let oldState=Object.assign({}, genThis.state);
            			Helpers.clearTranslations();
            			Helpers.getTranslations(genThis.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                genThis.setState(oldState);
				                window.ee.emit('languageChanged');
				            }).catch(error=>{
				            	oldState.translations=error;
				            	genThis.setState(oldState);
				            	window.ee.emit('languageChanged');
				            });
		            }
		            if(data.image_changed){
		            	emit_changes=true;
		            	Store.setUserImage(data.image_url);
		            }
		            if(emit_changes){
		            	window.ee.emit('preferencesChanged');
		            }
            	},100);             	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response),true);
	        }          
	    };
	    oReq.send(formData);
	}

	render(){
    	return (
    		<div className="content">
				{this.state.show_content && <div className="col-sm-12 mt-5 mt-3-mobile">
					<h3 className="text-left">{Helpers.translate('Account preferences',this.state.translations.general,'general')}</h3>				
					<Form className="text-left width_100 mt-5">	
						<Form.Group>
							<Form.Label className="ft-16 font-weight-bold">
					    		<span>{Helpers.translate('Name',this.state.translations.general,'general')}</span>
					    		<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
					    	</Form.Label>
					    	<Form.Control type="text" value={this.state.account.name || ''} 
				    			name="name"
				    			placeholder={Helpers.translate('Name',this.state.translations.general,'general')}
				    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'name','account')}
				    			autoComplete="off"
				    			onKeyPress={(e) => Helpers.submitForm(this,e,'saveAccount') } />
				    		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Language',this.state.translations.general,'general')}</Form.Label>
							<Select options={this.state.account.languages}
					    		value={this.state.account.language && this.state.account.language.length ? this.state.account.language : []} 
					    		className="selectize"
          						isClearable={false}
          						isSearchable={false}
          						placeholder={Helpers.translate('Language',this.state.translations.general,'general')}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.code}
					    		onChange={(value)=>Helpers.updateStateValue(this,(value !== null ? [value] : value),'language','account')}
					    	/>
					    	{this.state.errors.language && <p className="text-danger mt-1">{this.state.alertsField.language}</p>}
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Timezone',this.state.translations.general,'general')}</Form.Label>
							<Select options={this.state.account.timezones}
					    		value={this.state.account.timezone && this.state.account.timezone.length ? this.state.account.timezone : []} 
					    		className="selectize"
          						isClearable={true}
          						isSearchable={true}
          						isLoading={this.state.timezoneLoading}
          						placeholder={Helpers.translate('Timezone',this.state.translations.general,'general')}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.code}
					    		onChange={(value)=>Helpers.updateStateValue(this,(value !== null ? [value] : value),'timezone','account')}
					    		onInputChange={(str,action)=>this.timezoneSearch(str,action.action)}
					    	/>
					  	</Form.Group>
					  	<Form.Group>
					  		<div>
					  			<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Image',this.state.translations.general,'general')}</Form.Label>
					  		</div>
						  	{this.state.account.image === null && <div className="text-left">		            		
			            		<Dropzone onDrop={(acceptedFiles)=>this.onImageDrop(acceptedFiles)} accept={'image/jpg,image/png,image/jpeg'} multiple={false}>
								  {({getRootProps, getInputProps}) => (
								    <section className="d-inline-block">
								      <div {...getRootProps()}>
								        <input {...getInputProps()} />
								        <Button variant="secondary">{Helpers.translate('Upload image',this.state.translations.general,'general')}</Button>
								      </div>
								    </section>
								  )}
								</Dropzone>
								<p><small>{Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')} {this.state.account.image_max_size/1000}Kb</small></p>
			            	</div>}
			            	{this.state.account.image !== null && <div className="col-sm-4">
			            		<div className="delete_picture text-right">
									<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>Helpers.updateStateValue(this,null,'image','account')}></i>		      	
								</div>
				            	<div className="dropZoneListThumb" >
							      <div className="thumbInner pointer">
							        <img src={this.state.account.image.preview} alt="" onClick={()=>window.open(this.state.account.image.preview,'_blank')}/>
							      </div>
							    </div>
							</div>}
					    </Form.Group>
					  	<div className="mt-3 text-left">
							<Button className="btn-general" onClick={()=>this.saveAccount()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
						</div>							  	
					</Form>
				</div>}
		   	</div>
    	);
    }

}
export default withRouter(MyAccount);