import React from 'react';
import { withRouter } from 'react-router-dom';

class Dashboard extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {};
	  	this.moduleName='dashboard';
	}

	render(){
		let dashContent;
		return (
			<div className="content">
				{dashContent}
		   	</div>
		);
	}

}
export default withRouter(Dashboard);