import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from './pages/Header/Header';
import Login from './pages/Login/Login';
import Store from './services/Store.js';
import Onboarding from './pages/Onboarding/Onboarding';
import GoogleAuth from './pages/GoogleAuth/GoogleAuth';
import MicrosoftAuth from './pages/MicrosoftAuth/MicrosoftAuth';
import Impersonate from './pages/Impersonate/Impersonate';
import Dashboard from './pages/Dashboard/Dashboard';
import Campaigns from './pages/Campaigns/Campaigns';
import Campaign from './pages/Campaigns/Campaign';
import Message from './pages/Messages/Message';
import Teams from './pages/Teams/Teams';
import Team from './pages/Teams/Team';
import TeamMember from './pages/TeamMembers/TeamMember';
import Notification from './components/Notification';
import MicroTrainings from './pages/MicroTrainings/MicroTrainings';
import MicroTraining from './pages/MicroTrainings/MicroTraining';
import Administration from './pages/Administration/Administration';
import User from './pages/Users/User';
import Role from './pages/Roles/Role';
import MyAccount from './pages/MyAccount/MyAccount';

const eventEmitter = require('events');
window.ee = new eventEmitter();

class App extends React.Component{

  constructor(props) {
      super(props);
      this.state = {
        isLoged:Store.retrieveStatus(),
        main_page:Store.retrieveMainPage(),
        permissions:Store.retrieveUserPermissions(),
        environments:Store.retrieveUserEnvironments(),
        public_routes:["gauth", "mauth", "impersonate"]
      };
      this.listenerData=[];
  }

  componentDidMount() {
    if(this.listenerData.indexOf("storeChange") === -1){
      window.ee.on('storeChange', () => { 
        let oldState=Object.assign({}, this.state);
        oldState.isLoged=Store.retrieveStatus();
        oldState.main_page=Store.retrieveMainPage();
        oldState.permissions=Store.retrieveUserPermissions();
        oldState.environments=Store.retrieveUserEnvironments();
        this.setState(oldState);
      });
    }     
  }

  componentWillUnmount(){
    if(this.listenerData.indexOf("storeChange") !== -1){
      window.ee.removeListener('storeChange', () => { 
        //
      });
    }   
  }

  render(){ 
    return (    
        <div>          
          <BrowserRouter>
            {this.state.isLoged && <Notification />}
            {this.state.isLoged && 
              <Header 
                main_page={this.state.main_page}
                permissions={this.state.permissions}
                environments={this.state.environments}
              />
            }
            <Switch>
                <Route path="/gauth" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <GoogleAuth />) }></Route>
                <Route path="/mauth" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <MicrosoftAuth />) }></Route>
                <Route path="/impersonate" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Impersonate />) }></Route>
                <Route path="/onboarding" render={() => (this.state.isLoged && this.state.main_page === 'onboarding' ? <Onboarding/> : <Redirect to='/' />) }></Route>
                <Route path="/dashboard" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 ? <Dashboard/> : <Redirect to='/' />) }></Route>
                <Route exact path="/campaigns" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.campaign) ? <Campaigns permissions={this.state.permissions}/> : <Redirect to='/' />) }></Route>
                <Route exact path="/campaigns/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.campaign) ? <Campaign permissions={this.state.permissions}/> : <Redirect to='/' />) }></Route>
                <Route exact path="/campaigns/:campaign_id/messages/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.campaign) ? <Message/> : <Redirect to='/' />) }></Route>
                <Route exact path="/teams" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.team) ? <Teams permissions={this.state.permissions} /> : <Redirect to='/' />) }></Route>
                <Route exact path="/teams/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.team) ? <Team permissions={this.state.permissions}/> : <Redirect to='/' />) }></Route>               
                <Route exact path="/teams/:team_id/members/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.team) ? <TeamMember/> : <Redirect to='/' />) }></Route>
                <Route exact path="/trainings" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.microtraining) ? <MicroTrainings permissions={this.state.permissions}/> : <Redirect to='/' />) }></Route>
                <Route exact path="/trainings/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && (this.state.permissions.environment || this.state.permissions.microtraining) ? <MicroTraining permissions={this.state.permissions}/> : <Redirect to='/' />) }></Route>
                <Route exact path="/administration" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && this.state.permissions.environment ? <Administration/> : <Redirect to='/' />) }></Route>
                <Route exact path="/users/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && this.state.permissions.environment ? <User/> : <Redirect to='/' />) }></Route>
                <Route exact path="/roles/:id" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 && this.state.permissions.environment ? <Role/> : <Redirect to='/' />) }></Route>
                <Route exact path="/account" render={() => (this.state.isLoged && this.state.public_routes.indexOf(this.state.main_page) === -1 ? <MyAccount/> : <Redirect to='/' />) }></Route>
                <Route exact path="/" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Login />) }></Route>
                <Route render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Redirect to='/' /> ) }></Route>          
            </Switch>
          </BrowserRouter>
        </div>  
    )
  } 
}
export default App;
