import React from 'react';
import styles from './css/Register.module.css';
import Helpers from './../services/Helpers.js';
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default class Register extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	registration:this.props.registration,
	      	translations:{"general":{}}
	  	};
	  	this.validations={
	  		"email":["required","email"],
	  		"name":["required","min:3"],
	  		"environment_name":["required","min:3","alpha_lower_numeric"],
	  		"accept_terms":["required"]
	  	};
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	oldState[parent][model]=value;
        }else{
        	oldState[model]=value;
        }
        this.setState(oldState);
    }

	saveRegistration(){
		let fieldTranslations={
    		"email":"This",
    		"name":"This",
    		"environment_name":"This",
    		"accept_terms":"This"
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.registration,fieldTranslations,this.state.translations.general,true);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.registration);
		Helpers.doRequest('post','register',obj)
            .then(data=>{
            	this.props.onSuccess(data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
			<DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return (
			<div className="page-login registration">
				<div className="page-login-vertical-registration">
					<div className="page-login-box">
						<div className="row">
							<div className="col-sm-12">
								<h1 className="text-center">Registration</h1>
							</div>
						</div>
						<div className="row">
							<div className="page-login-panel">
								{generalMsg}
								<Form className="text-left">
									<Form.Group>
								    	<Form.Label className="ft-16">Email</Form.Label>
								    	<InputGroup className={this.state.errors.email ? 'border-error' : ''}>
								    		<InputGroup.Prepend>
										     	<i className={"fas fa-envelope "+styles['registration-input-group-prepend-i']}></i>
										    </InputGroup.Prepend>
										    <Form.Control className={"pl-0 "+styles['registration-input-group-input']} type="email" value={this.state.registration.email || ''} name="email" placeholder="Email" autoComplete="off" disabled/>
								    	</InputGroup>
								  		{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
								  		<p>We'll never share your email with anyone else</p>
								  	</Form.Group>
								  	<Form.Group>
								    	<Form.Label className="ft-16">Name</Form.Label>
								    	<InputGroup className={this.state.errors.email ? 'border-error' : ''}>
										    <InputGroup.Prepend>
										     	<i className={"fas fa-user "+styles['registration-input-group-prepend-i']}></i>
										    </InputGroup.Prepend>
										    <Form.Control type="text" className={"pl-0 "+styles['registration-input-group-input']} value={this.state.registration.name || ''} name="name" placeholder="Name" autoComplete="off" disabled/>
										</InputGroup>
								  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
								  	</Form.Group>
								  	<Form.Group>
								    	<h4 className="mt-3 font-weight-bold">Configure environment</h4>
								  	</Form.Group>
								  	<Form.Group>
								    	<Form.Label className="ft-16 font-weight-bold">Environment name</Form.Label>
								    	<div className="row">
								    		<div className="col-sm-6">
								    			<Form.Control type="text" className="pl-1" value={this.state.registration.environment_name || ''}
								    			name="environment_name"
								    			onChange={(e)=>this.updateStateValue(e.target.value,'environment_name','registration')}
								    			autoComplete="off"/>
								    		</div>
								    	
								    		<div className="col-sm-6 pl-sm-0">
								    			<Form.Label className="ft-16 pt-2">.senseii.ai</Form.Label>
								    		</div>
								    	</div>
								  		{this.state.errors.environment_name && <p className="text-danger mt-1">{this.state.alertsField.environment_name}</p>}
								  	</Form.Group>
								  	<Form.Group controlId="accept_terms">
									    <Form.Check
									    	type="checkbox"
									    	custom
									    	label={<span>Accept <a href="#" target="_blank">Terms</a> & <a href="#" target="_blank">Privacy Policy</a></span>}
									    	onChange={(e)=>this.updateStateValue(e.target.checked,'accept_terms','registration')}
									    	checked={this.state.registration.accept_terms && this.state.registration.accept_terms === true ? 'checked' : ''}
									    />
									    {this.state.errors.accept_terms && <p className="text-danger mt-1">{this.state.alertsField.accept_terms}</p>}
									</Form.Group>
								</Form>
								<div className="col-sm-12 mt-4">
									<Button type="submit" onClick={()=>this.saveRegistration()} disabled={this.state.registration.accept_terms && this.state.registration.accept_terms === true && this.state.registration.environment_name ? false : true}>Register</Button>
								</div>
							</div>
						</div>
					</div>
			   	</div>
		   	</div>
		)
	}

}
