import React from 'react';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import { withRouter } from 'react-router-dom';

class Impersonate extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
	      	alertsField:[]
	  	};	  	
	}

	componentDidMount(){
		if(this.props.location.search.length){
			Helpers.doRequest('get','login/impersonate'+this.props.location.search)
	 			.then(data=>{
	 				if(data.main_page){
	 					Store.saveLoginData(data);
		 				window.ee.emit('storeChange');
		 				this.props.history.push("/"+data.main_page);	
	 				}else{
	 					Store.clearData();
		 				window.ee.emit('storeChange');
		 				this.props.history.push("/");	
	 				}
	 			}).catch(error=>{
	            	Store.clearData();
	 				window.ee.emit('storeChange');
	 				this.props.history.push("/");	
	        	});
		}else{
			window.ee.emit('storeChange');
			this.props.history.push("/");	
		}
	}

	render(){
		return (
			<div className="content">
					
			</div>
		);		
	}
}
export default withRouter(Impersonate);