import React from 'react';
import Helpers from './../services/Helpers.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CampaignCard from './CampaignCard.js';
import CardDeck from 'react-bootstrap/CardDeck';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

export default class DomainData extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	      	show_modal:false,
	      	activeTab:"campaigns", 	
	      	campaigns:{},
	  		trainings:{}
	  	};
	}

	componentDidMount(){
		this.renderTab('campaigns');
	}

	renderTab(tab){
    	let oldState=Object.assign({}, this.state);
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];	
		oldState.success={};
	    oldState.alertsField=[];
    	switch(tab){
    		case 'campaigns':
    			Helpers.doRequest('get','domains/campaigns?domain_id='+this.props.domain_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.campaigns=data.campaigns;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'trainings':
    			Helpers.doRequest('get','domains/trainings?domain_id='+this.props.domain_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.trainings=data.trainings;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		default:	
    			this.setState(oldState);		
    	}	
    }

	render(){
		let campaigns;
		let trainings;
		if(this.state.activeTab === 'campaigns'){
			if(this.state.campaigns.list && this.state.campaigns.list.length>0){
				let campaignContent=this.state.campaigns.list.map((item, key) =>
	    			<CampaignCard item={item} index={key} key={key}
	    				showClick={false}
	    				showFooter={false}
	    				showLastUpdated={true}
	    				translations={this.props.translations}
	    				container_class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2"
	    			/>		
			    );
	    		campaigns=<CardDeck className="row">{campaignContent}</CardDeck>
			}
		}else if(this.state.activeTab === 'trainings'){
			let trainingsContent;
			if(this.state.trainings.list){
				trainingsContent=this.state.trainings.list.map((item, key) =>
			        <tr key={key}>
			        	<td>{ item.campaign_name }</td>
			        	<td>{ item.messages_number }</td>
			        	<td>{ item.team_name }</td>
			        	<td className={ (item.status === 2 ? 'bg-danger' : (item.status === 1 ? 'bg-success' : "bg-warning")) }>
			        		{ Helpers.translate(item.status_name,this.props.translations.general,'general') }
			        	</td>
			        	<td>{ new moment(Helpers.adjustDateTimezone(new Date(item.start_date))).format("DD/MM/YYYY HH:mm") }</td>
			        	<td>
			        		{ (item.next_date ? new moment(Helpers.adjustDateTimezone(new Date(item.next_date))).format("DD/MM/YYYY HH:mm") : item.next_date)  }
			        	</td>
			        	<td>
			        		{ Helpers.translate(item.type_name,this.props.translations.general,'general') }
			        	</td>
			        	<td>{ item.messages_sent }</td>
			        	<td>
			        		{ (item.end_date ? new moment(Helpers.adjustDateTimezone(new Date(item.end_date))).format("DD/MM/YYYY HH:mm") : item.end_date)  }
			        	</td>
			        </tr>
			    );

			}
			trainings=<Table responsive="sm" size="sm" className="text-left active_rows">
				  			<thead>
						      <tr className="table-secondary">
						        <th>
						        	<span>{Helpers.translate('Campaign',this.props.translations.general,'general')}</span>
						        </th>
						        <th>{Helpers.translate('Messages',this.props.translations.general,'general')}</th>
						        <th>
						        	<span>{Helpers.translate('Team',this.props.translations.general,'general')}</span>
						        </th>
						        <th>
						        	<span>{Helpers.translate('Status',this.props.translations.general,'general')}</span>
						        </th>
						        <th>
						        	<span>{Helpers.translate('Start',this.props.translations.general,'general')}</span>
						        </th>
						        <th>
						        	<span>{Helpers.translate('Next message',this.props.translations.training,'training')}</span>
						        </th>
						        <th>{Helpers.translate('Type',this.props.translations.general,'general')}</th>
						      	<th>
						        	<span>{Helpers.translate('Sent',this.props.translations.general,'general')}</span>
						        </th>
						        <th>
						        	<span>{Helpers.translate('End date',this.props.translations.training,'training')}</span>
						        </th>
						      </tr>
						    </thead>
						    <tbody>
						    	{ trainingsContent }
						    </tbody>
				  		</Table>
		}	
		return (
			<Tabs className="width_100" id="domain_tabs" activeKey={this.state.activeTab} onSelect={(k) => this.renderTab(k)}>
		      	<Tab eventKey="campaigns" title={Helpers.translate('Campaigns',this.props.translations.general,'general')}>
		      		{campaigns}
		      	</Tab>
		      	<Tab eventKey="trainings" title={Helpers.translate('Microtrainings',this.props.translations.general,'general')}>
		      		{trainings}
		      	</Tab>
		    </Tabs>
		);
	}

}