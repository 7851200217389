import React from 'react';
import Helpers from './../services/Helpers.js';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import DomainData from './DomainData.js';
import Button from 'react-bootstrap/Button';

export default class Domains extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	show_data:false,
	      	domain_id:null  	
	  	};
	}

	render(){
		const domainsContent=this.props.domains.list.map((item, key) =>
		        <tr key={key}>
		        	<td onClick={()=>Helpers.updateStateValue(this,[true,item.id],['show_data','domain_id'])}>{ item.name }</td>	        	
		        	<td className="text-right">
		        		<a href="#" className="dropdown-item" onClick={(event)=>Helpers.updateStateValue(this,[true,item.id],['show_data','domain_id'])}><i className="fas fa-info-circle"></i></a>
		        	</td>
		        </tr>
		    ); 
		return (
    		<div>
				<Table responsive="sm" size="sm" className="text-left active_rows">
		  			<thead>
				      <tr>
				      	<th>
				        	<span>{Helpers.translate('Name',this.props.translations.general,'general')}</span>
				        </th>
				        <th className="text-center"></th>
				      </tr>
				    </thead>		    
				    <tbody>{ domainsContent }</tbody>
		  		</Table>
		  		<Modal
					show={this.state.show_data}
			      	size="lg"
			      	aria-labelledby="contained-modal-label"
			      	centered
			      	backdrop="static"
			      	onHide={()=>Helpers.updateStateValue(this,[false,null],['show_data','domain_id'])}
			    >
				    <Modal.Body>
				    	<DomainData 
				    		domain_id={this.state.domain_id}
				    		translations={this.props.translations}
				    	/>
				    </Modal.Body>
				    <Modal.Footer>
		        		<Button onClick={()=>Helpers.updateStateValue(this,[false,null],['show_data','domain_id'])}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button>
		      		</Modal.Footer>
			    </Modal>
		   	</div>
    	);
	}

}