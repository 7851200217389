import React from 'react';
import Helpers from './../services/Helpers.js';
import Table from 'react-bootstrap/Table';
import TrainingMessage from './TrainingMessage.js';

export default class TrainingMessages extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	messages:this.props.messages,
	      	messages_details:{},
	      	search:{
	      		order_by:"",
	      		order:"asc"
	      	}     	
	  	};
	}

	sortList(value){
    	let oldState=Object.assign({}, this.state);
    	if(oldState.search.order_by !== value){
    		oldState.search.order='asc';
    		oldState.search.order_by=value;  
    	}else{
    		oldState.search.order_by=value;  
    		if(oldState.search.order === 'asc'){
	    		oldState.search.order = 'desc';
	    	}else if(oldState.search.order === 'desc'){
	    		oldState.search.order = '';
	    		oldState.search.order_by='';  
	    	}else{
	    		oldState.search.order = 'asc';
	    	}
    	} 
    	oldState.messages_details={};  		
    	let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        Helpers.doRequest('get','campaigns/statistics?campaign_id='+this.props.campaign_id+urlString)
            .then(data=>{                                                                   
                oldState.messages=data.messages;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

    toggleDetails(index){
    	let oldState=Object.assign({}, this.state);
    	oldState.messages.list[index].expanded=!oldState.messages.list[index].expanded;
    	if(oldState.messages.list[index].expanded && !oldState.messages_details[oldState.messages.list[index].message_language_id]){
    		Helpers.doRequest('get','campaigns/message_statistics?campaign_id='+this.props.campaign_id+"&message_language_id="+oldState.messages.list[index].message_language_id)
            .then(data=>{                                                                   
                oldState.messages_details[oldState.messages.list[index].message_language_id]=data.message;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    	}else{
    		this.setState(oldState);
    	}
    }

    render(){
    	const messagesContent=this.state.messages.list.map((item, key) =>
    			<React.Fragment key={key}>
			        <tr>
			        	<td className="text-left" onClick={()=>(item.sent && item.sent>0 ? this.toggleDetails(key) : ()=>{})}>
			        		{ (item.sent && item.sent>0 ? (!item.expanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>) : null) }
			        		<span className={ (item.sent && item.sent>0 ? "ml-2" : "")}>{ item.title }</span>
			        	</td>
			        	<td onClick={()=>(item.sent && item.sent>0 ? this.toggleDetails(key) : ()=>{})}>{ (item.sent && item.sent>0 ? item.sent : '-') }</td>
			        	<td className="p-0" onClick={()=>(item.sent && item.sent>0 ? this.toggleDetails(key) : ()=>{})}>
			        		<Table responsive="sm" className="mb-0">
	        					<tbody>
	        						<tr>
	        							<td className="border-0 w-50">{ (item.sent && item.sent>0 ? item.opened : '-') }</td>
	        							<td 
	        								className="border-right-0 border-top-0 border-bottom-0 w-50"
	        								style={ (item.open_rate_rgb ? {backgroundColor:item.open_rate_rgb} : {}) }
	        								>
	        								{ (item.sent && item.sent>0 ? item.open_rate+" %" : "-") }
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>
			        	</td>
			        	<td className="p-0" onClick={()=>(item.sent && item.sent>0 ? this.toggleDetails(key) : ()=>{})}>
			        		<Table responsive="sm" className="mb-0">
	        					<tbody>
	        						<tr>
	        							<td className="border-0 w-50">{ (item.sent && item.sent>0 ? item.clicked : '-') }</td>
	        							<td 
	        								className="border-right-0 border-top-0 border-bottom-0 w-50"
	        								style={ (item.click_rate_rgb ? {backgroundColor:item.click_rate_rgb} : {}) }
	        								>
	        								{ (item.sent && item.sent>0 ? item.click_rate+" %" : "-") }
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>
			        	</td>
			        	<td className="p-0" onClick={()=>(item.sent && item.sent>0 ? this.toggleDetails(key) : ()=>{})}>
			        		<Table responsive="sm" className="mb-0">
	        					<tbody>
	        						<tr>
	        							<td className="border-0 w-50">{ (item.sent && item.sent>0 ? item.correct : '-') }</td>
	        							<td 
	        								className="border-right-0 border-top-0 border-bottom-0 w-50"
	        								style={ (item.correct_rate_rgb ? {backgroundColor:item.correct_rate_rgb} : {}) }
	        								>
	        								{ (item.sent && item.sent>0 ? item.correct_rate+" %" : "-") }
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>
			        	</td>
			        </tr>
			        {
			        	(item.expanded && this.state.messages_details[item.message_language_id] ? 
			        		<tr>
			        			<td colSpan="3">
			        				<TrainingMessage data={this.state.messages_details[item.message_language_id]}
				        				translations={this.props.translations}
				        			/>
			        			</td>
			        		</tr>
			        	: null)
			        }
		        </React.Fragment>
		    ); 
    	return (
    		<div className="mt-4">
				<Table responsive="sm" size="sm" className="text-left active_rows">
		  			<thead>
				      <tr className="table-secondary">
				        <th>{Helpers.translate('Message',this.props.translations.general,'general')}</th>
				        <th>{Helpers.translate('Sent',this.props.translations.general,'general')}</th>
				        <th className="p-0">
				        	<Table responsive="sm" className="mb-0 no-background">
	        					<tbody>
	        						<tr>
	        							<td colSpan="2" className="border-0">{Helpers.translate('Opens',this.props.translations.general,'general')}</td>
	        						</tr>
	        						<tr>
	        							<td className="pointer font-weight-normal border-left-0 border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("opened")}>
		        							<span>{Helpers.translate('Opens',this.props.translations.general,'general')}</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'opened' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        							<td className="pointer font-weight-normal border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("open_rate")}>
	        								<span>{Helpers.translate('Open Rate',this.props.translations.general,'general')}</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'open_rate' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>			        	
				        </th>
				        <th className="p-0">
				        	<Table responsive="sm" className="mb-0 no-background">
	        					<tbody>
	        						<tr>
	        							<td colSpan="2" className="border-0">{Helpers.translate('Clicks',this.props.translations.general,'general')}</td>
	        						</tr>
	        						<tr>
	        							<td className="pointer font-weight-normal border-left-0 border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("clicked")}>
		        							<span>{Helpers.translate('Clicks',this.props.translations.general,'general')}</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'clicked' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        							<td className="pointer font-weight-normal border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("click_rate")}>
	        								<span>{Helpers.translate('Click Rate',this.props.translations.general,'general')}</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'click_rate' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>			        	
				        </th>
				        <th className="p-0">
				        	<Table responsive="sm" className="mb-0 no-background">
	        					<tbody>
	        						<tr>
	        							<td colSpan="2" className="border-0">{Helpers.translate('Correct',this.props.translations.general,'general')}</td>
	        						</tr>
	        						<tr>
	        							<td className="pointer font-weight-normal border-left-0 border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("correct")}>
		        							<span>{Helpers.translate('Correct',this.props.translations.general,'general')}</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'correct' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        							<td className="pointer font-weight-normal border-right-0 border-bottom-0 w-50" onClick={()=>this.sortList("correct_rate")}>
	        								<span>{Helpers.translate('Correct',this.props.translations.general,'general')} %</span>
								        	{
								        		(this.state.search.order && this.state.search.order_by === 'correct_rate' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
								        	}
	        							</td>
	        						</tr>
	        					</tbody>
	        				</Table>			        	
				        </th>
				      </tr>
				    </thead>		    
				    <tbody>{ messagesContent }</tbody>
		  		</Table>
		   	</div>
    	);
    }

}