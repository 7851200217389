import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Helpers from './services/Helpers.js';
import Store from './services/Store.js';

function startApp(){
	ReactDOM.render(<App />,document.getElementById('root'));
}

function getSubdomain(hostname) {
    let regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,10}$');
    let urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0],'').slice(0, -1);
}

if(localStorage.MicroTData){
	let storedData=JSON.parse(localStorage.MicroTData);
	if(parseInt(storedData.expiresIn) < Math.floor(Date.now() / 1000)){
		Store.clearData();
		startApp();
	}else{	
		Helpers.showLoading();
		setTimeout(()=>{ 
			Store.updateLoginData(storedData);
            Helpers.doRequest('get','tryconnection')
				.then(data=>{
					storedData.step=data.step;
					storedData.main_page=data.main_page;
					storedData.permissions=data.permissions;
					storedData.environments=data.environments;
					Store.updateLoginData(storedData,true);						
					startApp();
				}).catch(error=>{
					Store.clearData();
					startApp();
				});
        },300);		
	}	
}else if(/microjwt/.test(window.location.href)){
	let splitted=window.location.href.split("=");
	if(splitted.length === 2){
	    Helpers.doRequest('get','tryauth?code='+splitted[1])
			.then(data=>{
				Store.updateLoginData(data,true);						
				startApp();
			}).catch(error=>{
				Store.clearData();
				window.location.href=process.env.REACT_APP_DEFAULT_URL;
			});
	}else{
		window.location.href=process.env.REACT_APP_DEFAULT_URL;
	}
}else if(process.env.NODE_ENV !== 'development'){
	let appSubdomain=getSubdomain(window.location.hostname);
	if(appSubdomain && appSubdomain !== 'login'){
		window.location.href=process.env.REACT_APP_DEFAULT_URL;		
	}else{
		startApp();
	}
}else{
	startApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
