import React from 'react';
import Helpers from './../../services/Helpers.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Users from './../../components/Users.js';
import Domains from './../../components/Domains.js';
import Roles from './../../components/Roles.js';
import { withRouter } from 'react-router-dom';

class Administration extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		activeTab:"users",
	  		show_content:false,
	  		users:{},
	  		domains:{},
	  		roles:{}
	  	};
	  	this.moduleName='administration';
	  	this.allowed_tabs=['users','domains','roles'];
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		if(this.props.location.state && this.props.location.state.tab && this.allowed_tabs.indexOf(this.props.location.state.tab) !== -1){
			this.renderTab(this.props.location.state.tab,true);
		}else{
			Helpers.doRequest('get','users?order_by=email')
	 			.then(data=>{  		 				       	                 	            	
	            	oldState.show_content=true;
	            	oldState.users=data.users;
	            	Helpers.getTranslations(this.moduleName)
			            .then(data=>{
			            	oldState.translations=data;			                						                
			                this.setState(oldState);
			                if(this.props.location.state && this.props.location.state.msg){
			                	let msg=Object.assign({}, this.props.location.state.msg);
			                	window.history.pushState(null, '');
			                	let genThis=this;
			                	setTimeout(function(){		                		                		
			                		Helpers.showAlerts(genThis,msg);				                					           
				            	},200);
			                }
			            }).catch(error=>{
			            	oldState.translations=error;
			            	this.setState(oldState);
			            });
	 			}).catch(error=>{
	            	//	
	        	});
		}	
	}

	renderTab(tab,withTranslations){
    	let oldState=Object.assign({}, this.state);
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];	
		oldState.success={};
	    oldState.alertsField=[];
    	switch(tab){
    		case 'users':
    			Helpers.doRequest('get','users')
		 			.then(data=>{  		 		
		 				oldState.show_content=true;		       	                 	            	
		            	oldState.users=data.users;
		            	if(withTranslations){
		            		Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					                if(this.props.location.state && this.props.location.state.msg){
					                	let msg=Object.assign({}, this.props.location.state.msg);
					                	window.history.pushState(null, '');
					                	let genThis=this;
					                	setTimeout(function(){		                		                		
					                		Helpers.showAlerts(genThis,msg);				                					           
						            	},200);
					                }
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
		            	}else{
		            		this.setState(oldState);
		            	}	            		            	
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'domains':
    			Helpers.doRequest('get','domains')
		 			.then(data=>{  		 		
		 				oldState.show_content=true;		       	                 	            	
		            	oldState.domains=data.domains;
		            	if(withTranslations){
		            		Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					                if(this.props.location.state && this.props.location.state.msg){
					                	let msg=Object.assign({}, this.props.location.state.msg);
					                	window.history.pushState(null, '');
					                	let genThis=this;
					                	setTimeout(function(){		                		                		
					                		Helpers.showAlerts(genThis,msg);				                					           
						            	},200);
					                }
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
		            	}else{
		            		this.setState(oldState);
		            	}
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'roles':
    			Helpers.doRequest('get','roles?order_by=name')
		 			.then(data=>{  	
		 				oldState.show_content=true;	 				       	                 	            	
		            	oldState.roles=data.roles;
		            	if(withTranslations){
		            		Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					                if(this.props.location.state && this.props.location.state.msg){
					                	let msg=Object.assign({}, this.props.location.state.msg);
					                	window.history.pushState(null, '');
					                	let genThis=this;
					                	setTimeout(function(){		                		                		
					                		Helpers.showAlerts(genThis,msg);				                					           
						            	},200);
					                }
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
		            	}else{
		            		this.setState(oldState);
		            	}
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		default:	
    			this.setState(oldState);		
    	}	
    }

	render(){
		let users;
		let domains;
		let roles;
		if(this.state.activeTab === 'users'){
			users=<div>
				<div className="mt-3 text-left">
		  			<Button onClick={()=>this.props.history.push("/users/new")}>{Helpers.translate('Add new user',this.state.translations.administration,'administration')}</Button>				    						  								
				</div>
				<div className="mt-3 text-left">
						<Users
		    				users={this.state.users} 
		    				translations={this.state.translations} 
		    				showError={(data)=>Helpers.showAlerts(this,data)}
		    				editUser={(id)=>this.props.history.push("/users/"+id)}/>
					</div>
			</div>
		}else if(this.state.activeTab === 'domains'){
			domains=<div>
				<div className="mt-3 text-left">
						<Domains
		    				domains={this.state.domains} 
		    				translations={this.state.translations}/>
					</div>
			</div>
		}else if(this.state.activeTab === 'roles'){
			roles=<div>
				<div className="mt-3 text-left">
		  			<Button onClick={()=>this.props.history.push("/roles/new")}>{Helpers.translate('Add new role',this.state.translations.administration,'administration')}</Button>				    						  								
				</div>
				<div className="mt-3 text-left">
						<Roles
		    				roles={this.state.roles} 
		    				translations={this.state.translations} 
		    				showError={(data)=>Helpers.showAlerts(this,data)}
		    				editRole={(id)=>this.props.history.push("/roles/"+id)}/>
					</div>
			</div>
		}
		return (
			<div className="content">
				{this.state.show_content && <div className="col-sm-12 mt-5">
					<h3 className="text-left">{Helpers.translate('Domain and Roles',this.state.translations.general,'general')}</h3>
					<Tabs className="width_100" id="general_tabs" activeKey={this.state.activeTab} onSelect={(k) => this.renderTab(k)}>
				      	<Tab eventKey="users" title={Helpers.translate('Users',this.state.translations.general,'general')}>
				      		{users}
				      	</Tab>
				      	<Tab eventKey="roles" title={Helpers.translate('Roles',this.state.translations.general,'general')}>
				      		{roles}
				      	</Tab>
				      	<Tab eventKey="domains" title={Helpers.translate('Domains',this.state.translations.general,'general')}>
				      		{domains}
				      	</Tab>				      		
				    </Tabs>				
				</div>}
		   	</div>
		);
	}

}
export default withRouter(Administration);