import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

class User extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		create:true,
	  		show_content:false,
	  		user:{}
	  	};
	  	this.moduleName='administration';
	  	this.user_validations={
	  		"email":["required","email"],
	  		"permissions":["required","permission:1"]
	  	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','users/'+this.props.match.params.id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.create=false;
		            	oldState.show_content=true;
		            	oldState.user=data.user;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}else{	
 				Helpers.doRequest('get','users/create')
		 			.then(data=>{  		 				       	                 	       
		            	oldState.show_content=true;
		            	oldState.user=data.user;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}		
		}		
	}

	updatePermissions(value,index,subindex){
		let oldState=Object.assign({}, this.state);
		if(oldState.user.permissions[index].identifier === 'environment'){
			oldState.user.permissions[index].checked=value;
			for(let x in oldState.user.permissions){
				if(oldState.user.permissions[x].identifier === 'environment'){
					continue;
				}
				oldState.user.permissions[x].checked=value;
				oldState.user.permissions[x].disabled=value ? true : false;
				if(oldState.user.permissions[x].extra.length>0){
					for(let y in oldState.user.permissions[x].extra){
						oldState.user.permissions[x].extra[y].checked=value;
						oldState.user.permissions[x].extra[y].disabled=value ? true : false;
					}
				}			
			}	
		}else{
			if(subindex !== undefined){
				oldState.user.permissions[index].extra[subindex].checked=value;
			}else{
				oldState.user.permissions[index].checked=value;
				if(oldState.user.permissions[index].extra.length>0){
					for(let x in oldState.user.permissions[index].extra){
						oldState.user.permissions[index].extra[x].checked=value;
						oldState.user.permissions[index].extra[x].disabled=value ? true : false;
					}
				}
			}			
		}
		this.setState(oldState); 
	}

	updateRole(role){
		let oldState=Object.assign({}, this.state);
		oldState.user.role=role;
		if(Array.isArray(role) && role.length === 1){
			oldState.user.permissions=role[0].permissions;
		}
		this.setState(oldState); 
	}

	saveUser(){
		let fieldTranslations;
		if(this.state.user.id){
			fieldTranslations={
	    		"permissions":Helpers.translate('This',this.state.translations.general,'general')
	    	};			
		}else{
			fieldTranslations={
	    		"email":Helpers.translate('Email',this.state.translations.general,'general'),
	    		"permissions":Helpers.translate('This',this.state.translations.general,'general')
	    	};
		}		
		let formErrors=FormValidation.checkValidity(this.user_validations,this.state.user,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.user);
		delete obj.teams;
		if(Array.isArray(obj.role) && obj.role.length === 1){
			obj.role_id= obj.role[0].id;
			delete obj.role;
		}
		if(this.state.user.id){
			Helpers.doRequest('put','users/'+this.state.user.id,obj)
	            .then(data=>{
	            	this.props.history.push("/administration",{"msg":data});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}else{
			Helpers.doRequest('post','users',obj)
	            .then(data=>{
	            	this.props.history.push("/administration",{"msg":data});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}
	}

	render(){
	    let bodyContent;
	    if(this.state.create === true){
	    	let permissions;
			if(this.state.user.permissions){
				permissions=this.state.user.permissions.map((item, key)=>
						<Form.Group className="width_100" controlId={"permission_check_"+key} key={key}>
						    <Form.Label className="ft-16 font-weight-bold">{Helpers.translate(item.title,this.state.translations.administration,'administration')}</Form.Label>
							<Form.Check 
							    type="checkbox" 
							    custom 
							    label={Helpers.translate(item.subtitle,this.state.translations.administration,'administration')} 
							    onChange={(e)=>this.updatePermissions(e.target.checked,key)} 
							    checked={item.checked && item.checked === true ? 'checked' : ''}
							    disabled={item.disabled && item.disabled === true ? true : false}
						    />
						    {
						    	(item.extra.length > 0 ?
						    			item.extra.map((subitem, subkey)=>
						    				<Form.Group className="ml-4" controlId={"permission_extra_"+item.identifier+"_"+subitem.identifier} key={subkey}>
						    					<Form.Check 
												    type="checkbox" 
												    custom 
												    label={Helpers.translate(subitem.name,this.state.translations.administration,'administration')} 
												    onChange={(e)=>this.updatePermissions(e.target.checked,key,subkey)} 
												    checked={subitem.checked && subitem.checked === true ? 'checked' : ''}
												    disabled={subitem.disabled && subitem.disabled === true ? true : false}
												   />
						    				</Form.Group>
						    			)
						    		: null)
						    }
						</Form.Group>									      
	    			);
			}
	    	bodyContent=<div>
		    	<Form className="text-left width_100 mt-3">
		    		<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">
				    		<span>{Helpers.translate('Email',this.state.translations.general,'general')}</span>
				    		<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
				    	</Form.Label>
				    	<div className="mb-1 text-left">
							<span>{Helpers.translate("The user will receive an invitation on this address. Make sure it is a valid Google or Microsoft account.",this.state.translations.administration,'administration')}</span>
						</div>
				    	<Form.Control type="text" value={this.state.user.email || ''} 
			    			name="email"
			    			placeholder={Helpers.translate('Email',this.state.translations.general,'general')}
			    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'email','user')}
			    			autoComplete="off"
			    			onKeyPress={(e) => Helpers.submitForm(this,e,'saveUser') } />
			    		{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Role',this.state.translations.administration,'administration')}</Form.Label>
				    	<Select options={this.state.user.roles}
				    		value={this.state.user.role && this.state.user.role.length ? this.state.user.role : []} 
				    		className="selectize"
      						isClearable={true}
      						isSearchable={false}
      						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.id}
				    		onChange={(value)=>this.updateRole((value !== null ? [value] : []))}
				    	/>
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Select permission(s)',this.state.translations.general,'general')}</Form.Label>
				    	<div>{ permissions }</div>			    									    	
				  		{this.state.errors.permissions && <p className="text-danger mt-1">{this.state.alertsField.permissions}</p>}
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Limit access to teams',this.state.translations.administration,'administration')}</Form.Label>
				    	<Select options={this.state.user.teams}
				    		value={this.state.user.team && this.state.user.team.length ? this.state.user.team : []} 
				    		className="selectize"
      						isMulti={true}
      						isClearable={true}
      						isSearchable={true}
      						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.id}
				    		onChange={(value)=>Helpers.updateStateValue(this,value,'team','user')}
				    	/>
				    	{this.state.errors.frequency && <p className="text-danger mt-1">{this.state.alertsField.frequency}</p>}
				  	</Form.Group>
				  	<div className="mt-3 text-left">
						<Button onClick={()=>this.saveUser()}>{Helpers.translate('Send invitation',this.state.translations.administration,'administration')}</Button>
					</div>					
		    	</Form>
		    </div>
	    }else{
	    	let permissions;
			if(this.state.user.permissions){
				permissions=this.state.user.permissions.map((item, key)=>
						<Form.Group className="width_100" controlId={"permission_check_"+key} key={key}>
						    <Form.Label className="ft-16 font-weight-bold">{Helpers.translate(item.title,this.state.translations.administration,'administration')}</Form.Label>
						    <Form.Check 
							    type="checkbox" 
							    custom 
							    label={Helpers.translate(item.subtitle,this.state.translations.administration,'administration')} 
							    onChange={(e)=>this.updatePermissions(e.target.checked,key)} 
							    checked={item.checked && item.checked === true ? 'checked' : ''}
							    disabled={(item.disabled && item.disabled === true) || this.state.user.block_edit ? true : false}
						    />
						    {
						    	(item.extra.length > 0 ?
						    			item.extra.map((subitem, subkey)=>
						    				<Form.Group className="ml-4" controlId={"permission_extra_"+item.identifier+"_"+subitem.identifier} key={subkey}>
						    					<Form.Check 
												    type="checkbox" 
												    custom 
												    label={Helpers.translate(subitem.name,this.state.translations.administration,'administration')} 
												    onChange={(e)=>this.updatePermissions(e.target.checked,key,subkey)} 
												    checked={subitem.checked && subitem.checked === true ? 'checked' : ''}
												    disabled={(subitem.disabled && subitem.disabled === true) || this.state.user.block_edit ? true : false}
												   />
						    				</Form.Group>
						    			)
						    		: null)
						    }
						</Form.Group>									      
	    			);
			}
	    	bodyContent=<div>
		    	<Form className="text-left width_100 mt-3">
		    		<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">
				    		<span>{Helpers.translate('Name',this.state.translations.general,'general')}</span>
				    	</Form.Label>
				    	<Form.Control type="text" 
				    		value={this.state.user.name || ''} 
				    		name="name" 
				    		placeholder={Helpers.translate('Name',this.state.translations.general,'general')} 
				    		autoComplete="off" disabled/>
				  	</Form.Group>
		    		<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">
				    		<span>{Helpers.translate('Email',this.state.translations.general,'general')}</span>
				    	</Form.Label>
				    	<Form.Control type="text" 
				    		value={this.state.user.email || ''} 
				    		name="email" 
				    		placeholder={Helpers.translate('Email',this.state.translations.general,'general')} 
				    		autoComplete="off" disabled/>
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Role',this.state.translations.administration,'administration')}</Form.Label>
				    	<Select options={this.state.user.roles}
				    		value={this.state.user.role && this.state.user.role.length ? this.state.user.role : []} 
				    		className="selectize"
      						isClearable={true}
      						isSearchable={false}
      						isDisabled={this.state.user.block_edit}
      						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.id}
				    		onChange={(value)=>this.updateRole((value !== null ? [value] : []))}
				    	/>
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Select permission(s)',this.state.translations.general,'general')}</Form.Label>
				    	<div>{ permissions }</div>			    									    	
				  		{this.state.errors.permissions && <p className="text-danger mt-1">{this.state.alertsField.permissions}</p>}
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Limit access to teams',this.state.translations.administration,'administration')}</Form.Label>
				    	<Select options={this.state.user.teams}
				    		value={this.state.user.team && this.state.user.team.length ? this.state.user.team : []} 
				    		className="selectize"
      						isMulti={true}
      						isClearable={true}
      						isSearchable={true}
      						isDisabled={this.state.user.block_edit}
      						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.id}
				    		onChange={(value)=>Helpers.updateStateValue(this,value,'team','user')}
				    	/>
				    	{this.state.errors.frequency && <p className="text-danger mt-1">{this.state.alertsField.frequency}</p>}
				  	</Form.Group>
				  	<div className="mt-3 text-left">
						<Button onClick={()=>this.saveUser()} disabled={this.state.user.block_edit}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</div>					
		    	</Form>
		    </div>
	    }	    
		return (
			<div className="content">
				{this.state.show_content && <div className="col-sm-8 mt-5">
					<div className="back-button text-left">
						<Link to="/administration"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to users overview',this.state.translations.training,'administration')}</span></Link>
					</div>
					<h3 className="text-left mt-3">
						{ (this.state.create ? <span>{Helpers.translate('New user',this.state.translations.administration,'administration')}</span> : <span>{Helpers.translate('Edit user',this.state.translations.administration,'administration')}</span>) }					
					</h3>
					{bodyContent}
				</div>}
		   	</div>
		);
	}

}
export default withRouter(User);