import React from 'react';
import Image from 'react-bootstrap/Image';

export default class Avatar extends React.Component{

	render(){		
		return (
			<div className="d-inline">
				<div className="d-inline-block">
					{(this.props.user_image && this.props.user_image!==null ? <p className="avatar_container"><Image className="avatar_image" src={this.props.user_image} /></p> : null)}					
				</div>
				<div className="d-inline-block">
					<span className={(this.props.user_image && this.props.user_image!==null ? "pl-1" : "")}>{this.props.user_name}</span>
				</div>
			</div>
			
		);
	}

}