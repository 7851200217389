import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Messages from './../../components/Messages.js';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import * as loadImage from 'blueimp-load-image';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import InputGroup from 'react-bootstrap/InputGroup';
import { Editor } from '@tinymce/tinymce-react';
import CampaignStatistics from './../../components/CampaignStatistics.js';
import Badge from 'react-bootstrap/Badge';

class Campaign extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		show_content:false,
	  		create:true,
	  		step:1,
	  		campaign_id:null,
	  		campaign:{},
	  		activeTab:"messages",
	  		messages:{},
	  		settings:{},
	  		description:{},
	  		email:{},
	  		tmp:{
	  			edit_name:false,
	  			name:''
	  		},
	  		statistics:{}
	  	};
	  	this.moduleName='campaign';
	  	this.campaign_validations={
	  		"name":["required","min:3"],
	  		"languages":["required","checked:1"]
	  	};
	  	this.email_validations={
	  		"frequency":["required"]
	  	};
	  	this.tinymceConfig={
           height: 200,
           menubar: false,
           branding: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help'
           ],
           toolbar:
             'bold italic | bullist numlist'
        };
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','messages?campaign_id='+this.props.match.params.id+'&campaign=1')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.create=false;
		            	oldState.show_content=true;
		            	oldState.campaign=data.campaign;
		            	oldState.campaign_id=data.campaign.campaign_id;
		            	oldState.messages=data.messages;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
			}else{
				if(!this.props.permissions.campaign.full_control){
					this.props.history.push("/campaigns");
					return false;
				}
				Helpers.doRequest('get','campaigns/create')
		 			.then(data=>{ 
		 				if(!data.campaign.allow_create){
		 					this.props.history.push("/campaigns");
							return false;
		 				} 	
		 				oldState.show_content=true;	 				       	                 	    
		            	oldState.campaign=data.campaign;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});		
			}			
		}
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		if(model === 'frequency'){
        			if(value !== null){
		        		oldState[parent][model]=[];
		        		oldState[parent][model].push(value);
		        	}else{
		        		oldState[parent][model]=value;
		        	}
        		}else{
        			oldState[parent][model]=value;
        		}      		
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        oldState.errors={};
        this.setState(oldState);  
    }

    updateCampaignLanguages(value,index){
		let oldState=Object.assign({}, this.state);
		oldState.campaign.languages[index].checked=value;
		this.setState(oldState); 
    }

    goToStepTwoCreate(){
    	let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general'),
    	};
		let formErrors=FormValidation.checkValidity({"name":["required","min:3"]},this.state.campaign,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		this.setState({'step':2});
    }

	createCampaign(){
		let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general'),
    		"languages":Helpers.translate('This',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.campaign_validations,this.state.campaign,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.campaign);
		Helpers.doRequest('post','campaigns',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.campaign=data.campaign;
            	oldState.campaign_id=data.campaign.campaign_id;
            	oldState.messages=data.messages;
            	oldState.create=false;
            	this.setState(oldState);
            	this.props.history.push("/campaigns/"+data.campaign.campaign_id)
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	renderTab(tab){
    	let oldState=Object.assign({}, this.state);
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];	
		oldState.success={};
	    oldState.alertsField=[];
    	switch(tab){
    		case 'messages':
    			Helpers.doRequest('get','messages?campaign_id='+this.state.campaign_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.messages=data.messages;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'publication_settings':
    			Helpers.doRequest('get','campaigns/'+this.state.campaign_id+'/edit')
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.settings=data.settings;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'email_settings':
    			Helpers.doRequest('get','campaigns/email?campaign_id='+this.state.campaign_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.email=data.email;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'description':
    			Helpers.doRequest('get','campaigns/description?campaign_id='+this.state.campaign_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.description=data.description;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		case 'statistics':
    			Helpers.doRequest('get','campaigns/statistics?campaign_id='+this.state.campaign_id)
		 			.then(data=>{  		 				       	                 	            	
		            	oldState.statistics=data.messages;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//	
		        	});
    			break;
    		default:	
    			this.setState(oldState);		
    	}	
    }

    updateName(){
		let fieldTranslations={
    		"name":Helpers.translate('Campaign name',this.state.translations.campaign,'campaign')
    	};
		let formErrors=FormValidation.checkValidity({"name":["required","min:3"],},this.state.tmp,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.tmp);
		obj.campaign_id=this.state.campaign_id;
		Helpers.doRequest('post','campaigns/update_name',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.campaign.name=oldState.tmp.name;
            	oldState.tmp.name='';
            	oldState.tmp.edit_name=false;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updatePublicationLanguage(index,status){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{ (status === 1 ? Helpers.translate('Do you want to publish this language?',this.state.translations.campaign,'campaign') : Helpers.translate('Do you want to unpublish this language?',this.state.translations.campaign,'campaign')) }</p>
		        <button onClick={onClose}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					let obj={};
			    	obj.campaign_language_id=this.state.settings.data.languages[index].id;
					obj.status=status;
					Helpers.doRequest('post','campaigns/status',obj)
			            .then(data=>{
			            	let oldState=Object.assign({},this.state);
			            	oldState.settings.data.languages[index].status=status;
					        this.setState(oldState);
			            }).catch(error=>{
			            	Helpers.showAlerts(this,error);
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    activatePublicationLanguage(code){
    	let obj={};
    	obj.campaign_id=this.state.campaign_id;
		obj.code=code;
		Helpers.doRequest('post','campaigns/language_activate',obj)
            .then(data=>{
            	let oldState=Object.assign({},this.state);
            	oldState.settings=data.settings;
		        this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error);
            });
    }

    /*updatePublicationAvailability(index,value){
    	let oldState=Object.assign({},this.state);
    	let obj={};
    	obj.campaign_language_id=this.state.settings.data.languages[index].id;
		obj.availability=value ? '1' : '0';
		Helpers.doRequest('post','campaigns/availability',obj)
            .then(data=>{            	
            	oldState.settings.data.languages[index].availability=value;
		        this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error);
            });
    }*/

    removeLanguage(item){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{ Helpers.translate('Do you want delete this language?',this.state.translations.campaign,'campaign') }</p>
		        <button onClick={onClose}>{Helpers.translate('Cancel',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
					let obj={};
			    	obj.campaign_language_id=item.id;
					obj.campaign_id=item.campaign_id;
					Helpers.doRequest('post','campaigns/language_delete',obj)
			            .then(data=>{
			            	let oldState=Object.assign({},this.state);
			            	oldState.settings=data.settings;
					        this.setState(oldState);
			            }).catch(error=>{
			            	Helpers.showAlerts(this,error);
			            });
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    updateEmailSubject(value,index){
    	let oldState=Object.assign({}, this.state);
		oldState.email.languages[index].subject=value;
		this.setState(oldState); 
    }

    onEmailHeaderDrop(acceptedFiles){
    	if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		if(parseInt(acceptedFiles[0].size/1000) > parseInt(this.state.email.header_max_size/1000)){
	    			let errorMsg={'errors':{'error':Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')+" "+this.state.email.header_max_size/1000+"Kb"}};
	    			Helpers.showAlerts(this,errorMsg);
	    			return false;
	    		}  		
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(acceptedFiles[0], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(acceptedFiles[0], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(acceptedFiles[0].type));
				  		convertedImage.preview=URL.createObjectURL(convertedImage);
				  		Helpers.stopLoading();	
				  		oldState.email.header_image=convertedImage;
				    	this.setState(oldState); 
				  	}, loadImageOptions)
				});	    
	    	}    	
	    }
    }

    updateCampaignEmailSettings(){
    	let fieldTranslations={
    		"frequency":Helpers.translate('Email frequency',this.state.translations.campaign,'campaign')
    	};
		let formErrors=FormValidation.checkValidity(this.email_validations,this.state.email,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
    	let genThis=this;
		let formData = new FormData();
		formData.append("campaign_id", this.state.campaign_id);
		formData.append("frequency", this.state.email.frequency[0].id);
		if(this.state.email.header_image !== null){
			formData.append("header_image", this.state.email.header_image);
		}
		for(let x in this.state.email.languages){
			formData.append("languages["+this.state.email.languages[x].code+"]", this.state.email.languages[x].subject);
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'campaigns/email', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
	        	let oldState=Object.assign({}, genThis.state);
	   	     	oldState.email=data.email;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);             	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
    }

    onDescriptionDrop(acceptedFiles){
    	if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		if(parseInt(acceptedFiles[0].size/1000) > parseInt(this.state.description.description_max_size/1000)){
	    			let errorMsg={'errors':{'error':Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')+" "+this.state.description.description_max_size/1000+"Kb"}};
	    			Helpers.showAlerts(this,errorMsg);
	    			return false;
	    		}  		
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(acceptedFiles[0], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(acceptedFiles[0], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(acceptedFiles[0].type));
				  		convertedImage.preview=URL.createObjectURL(convertedImage);
				  		Helpers.stopLoading();	
				  		oldState.description.description_image=convertedImage;
				    	this.setState(oldState); 
				  	}, loadImageOptions)
				});	    
	    	}    	
	    }
    }

    updateDescriptionData(value,model,index){
    	let oldState=Object.assign({}, this.state);
		oldState.description.languages[index][model]=value;
		this.setState(oldState); 
    }

    updateCampaignDescriptionSettings(){
    	let genThis=this;
		let formData = new FormData();
		formData.append("campaign_id", this.state.campaign_id);
		if(this.state.description.description_image !== null){
			formData.append("description_image", this.state.description.description_image);
		}
		for(let x in this.state.description.languages){
			formData.append("title["+this.state.description.languages[x].code+"]", this.state.description.languages[x].title);
			formData.append("description["+this.state.description.languages[x].code+"]", this.state.description.languages[x].description);
			formData.append("long_description["+this.state.description.languages[x].code+"]", this.state.description.languages[x].long_description);
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'campaigns/description', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
	        	let oldState=Object.assign({}, genThis.state);
	   	     	oldState.description=data.description;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);             	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
    }

	render(){
		let bodyContent;
		if(this.state.create === true){
			let languages;
			if(this.state.campaign.languages){
				languages=this.state.campaign.languages.map((item, key)=>
						<Form.Group className="width_100" controlId={"language_check_"+key} key={key}>
						    <Form.Check 
						    type="checkbox" 
						    custom 
						    label={<span><img alt="" src={require('./../../assets/images/campaign/'+item.code+'.png')} /> <span>{Helpers.translate(item.name,this.state.translations.general,'general')}</span></span>} 
						    onChange={(e)=>this.updateCampaignLanguages(e.target.checked,key)} 
						    checked={this.state.campaign.languages[key].checked && this.state.campaign.languages[key].checked === true ? 'checked' : ''}
						    />
						</Form.Group>									      
	    			);
			}
			bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
					<h4 className="text-left font-weight-bold">{Helpers.translate('New Campaign',this.state.translations.campaign,'campaign')}</h4>			
					<div className="row width_100">
						<div className="d-flex mt-2 width_100 steps">
							<div className="col-md-4 col-sm-12 text-left">
								<div className="back-button text-left">
									<Link to="/campaigns"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to campaigns overview',this.state.translations.campaign,'campaign')}</span></Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 text-center">
								<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center d-inline-block "+(this.state.step===1 ? "active" : "")}>
									<div className="setup_card d-block">
										<Badge pill variant="light">1</Badge>									
									</div>
									<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Campaign name',this.state.translations.campaign,'campaign')}</span>
								</div>
								<div className={"setup_step col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center d-inline-block "+(this.state.step===2 ? "active" : "")}>
									<div className="setup_card d-block">
										<Badge pill variant="light">2</Badge>										
									</div>
									<span className="float-left d-block text-center width_100 mt-2">{Helpers.translate('Language',this.state.translations.general,'general')}</span>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 text-right">
								{
									this.state.step === 1 &&
									<Button className="float-right btn-general" onClick={()=>this.goToStepTwoCreate()}>
										<span>{Helpers.translate('Continue',this.state.translations.general,'general')}</span>
									</Button>
								}
								{
									this.state.step === 2 &&
									<Button className="float-right btn-general" onClick={()=>this.createCampaign()}>
										<span>{Helpers.translate('Save',this.state.translations.general,'general')}</span>
									</Button>
								}
							</div>					
						</div>					
					</div>
					{this.state.step === 1 && <div className="mt-4 w-100">
						<h4 className="text-left font-weight-bold">{Helpers.translate('Campaign name',this.state.translations.campaign,'campaign')}</h4>
							<Form className="text-left width_100">						
							  	<Form.Group>
							    	<Form.Label className="ft-16">{Helpers.translate('Set a campaign name (this will be used in your overview)',this.state.translations.campaign,'campaign')}</Form.Label>
							    	<div className="row">
							    		<div className="col-sm-4">
							    			<Form.Control type="text" value={this.state.campaign.name || ''} 
							    			name="name"
							    			placeholder={Helpers.translate('Campaign name',this.state.translations.campaign,'campaign')}
							    			onChange={(e)=>this.updateStateValue(e.target.value,'name','campaign')}
							    			autoComplete="off" 
							    			onKeyPress={(e) => Helpers.submitForm(this,e,'goToStepTwoCreate') } />
							    		</div>
							    	</div>			    									    	
							  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
							  	</Form.Group>						  	
							</Form>					
					</div>}
					{this.state.step === 2 && <div className="mt-4 w-100">
						<h4 className="text-left font-weight-bold">{Helpers.translate('Language',this.state.translations.general,'general')}</h4>
							<Form className="text-left width_100">						
							  	<Form.Group>
							    	<Form.Label className="ft-16">{Helpers.translate('Select at least 1 language',this.state.translations.campaign,'campaign')}</Form.Label>
							    	<div>{ languages }</div>			    									    	
							  		{this.state.errors.languages && <p className="text-danger mt-1">{this.state.alertsField.languages}</p>}
							  	</Form.Group>						  	
							</Form>					
					</div>}
					{this.state.step === 2 && <h3 className="mt-5">
						<p className="d-inline-block float-left">			
							<Button variant="link" size="sm" onClick={()=>this.setState({ step:1 })}>
								<i className="fas fa-chevron-left"></i>
								<span className="ml-3">{Helpers.translate('Previous',this.state.translations.general,'general')}</span>
							</Button>
						</p>							
					</h3>}
				</div>
		}else{
			let campaignMessages;
			if(this.state.activeTab === 'messages'){				
		    	campaignMessages=<div className="mt-3">
		    		{this.state.show_content && 
		    			<Messages campaign_id={this.state.campaign_id} 
		    			messages={this.state.messages} 
		    			translations={this.state.translations} 
		    			showError={(data)=>Helpers.showAlerts(this,data)}
		    			editMessage={(id)=>this.props.history.push("/campaigns/"+this.state.campaign_id+"/messages/"+id)}/>}
		    	</div>
			}
			let publicationSettings;
			if(this.state.activeTab === 'publication_settings'){
				let languages;
				if(this.state.settings.data && this.state.settings.data.languages){
					languages=this.state.settings.data.languages.map((item, key)=>
						<Form.Group className="width_100" controlId={"language_check_"+key} key={key}>
				    		<div className="col-md-3 col-sm-4 d-inline-block">
						    	<img alt="" src={require('./../../assets/images/campaign/'+item.code+'.png')} /> 
						    	<span className="ml-2">{Helpers.translate(item.name,this.state.translations.general,'general')}</span>
						    	{item.status !== 1 && <Button variant="link" size="sm" onClick={()=>this.removeLanguage(item)}>
						    		<i className="fas fa-times-circle"></i>
						    	</Button>}
						    </div>
						    <div className="col-md-9 col-sm-8 d-inline-block">
						    	{item.status === 0 && item.total_messages>0 && 
						    		item.total_messages === item.total_ready_messages && 
						    		item.subject && item.description && item.long_description && item.title && this.state.settings.description_image_done && <Button variant="secondary" size="sm" onClick={()=>this.updatePublicationLanguage(key,1)}>
						    		{Helpers.translate('Publish',this.state.translations.campaign,'campaign')}
						    	</Button>}
						    	{item.status === 1 && <React.Fragment>
						    		<Button variant="secondary" size="sm" onClick={()=>()=>{}}>
						    			<i className="fas fa-check text-success"></i>
						    			<span className="ml-2">{Helpers.translate('Published',this.state.translations.campaign,'campaign')}</span>
						    		</Button>
						    	</React.Fragment>}
						    	{item.status === 0 && (item.total_messages === 0 || item.total_messages !== item.total_ready_messages) && <Alert variant="info" className="mb-0 d-inline pt-2 pb-2 mr-2">
							        <i className="fas fa-info-circle text-info"></i>
							    	<span className="ml-2">{Helpers.translate('Not all messages ready yet',this.state.translations.campaign,'campaign')}</span>
							    </Alert>}
							    {item.status === 0 && !item.subject && <Alert variant="info" className="mb-0 d-inline pt-2 pb-2 mr-2">
							        <i className="fas fa-info-circle text-info"></i>
							    	<span className="ml-2">{Helpers.translate('Email settings not complete',this.state.translations.campaign,'campaign')}</span>
							    </Alert>}
							    {item.status === 0 && (!item.description || !item.long_description || !item.title || !this.state.settings.description_image_done) && <Alert variant="info" className="mb-0 d-inline pt-2 pb-2 mr-2">
							        <i className="fas fa-info-circle text-info"></i>
							    	<span className="ml-2">{Helpers.translate('Description not ready yet',this.state.translations.campaign,'campaign')}</span>
							    </Alert>}			    							    	
						    </div>		   					  
						</Form.Group>									      
	    			);
				}
				let activateLanguages;
				if(this.state.settings.languages && this.state.settings.languages.length>0){
					activateLanguages=this.state.settings.languages.map((item, key)=>
						<Form.Group className="width_100" controlId={"language_activate_"+key} key={key}>
						    <div className="col-md-3 col-sm-4 d-inline-block">
						    	<img alt="" src={require('./../../assets/images/campaign/'+item.code+'.png')} /> 
						    	<span className="ml-2">{Helpers.translate(item.name,this.state.translations.general,'general')}</span>
						    </div>
						    <div className="col-md-9 col-sm-8 d-inline-block">
						    	<Button className="btn-general" variant="secondary" size="sm" onClick={()=>this.activatePublicationLanguage(item.code)}>
						    		{Helpers.translate('Activate',this.state.translations.general,'general')}
						    	</Button>					    	
						    </div>
						</Form.Group>									      
	    			);
				}
				publicationSettings=<div className="mt-3">
					<Form className="text-left width_100">
						<Form.Group>
					    	<div className="row">{ languages }</div>			    									    				  		
					  	</Form.Group>
					  	{this.state.settings.languages && this.state.settings.languages.length>0 && 
					  	<Form.Group>
					  		<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Activate another language',this.state.translations.campaign,'campaign')}</Form.Label>
					  		<div className="row">{ activateLanguages }</div>		    									    				  		
					  	</Form.Group>}
					</Form>
				</div>
			}
			let emailSettings;
			if(this.state.activeTab === 'email_settings'){
				let languages;
				if(this.state.email.languages){
					languages=this.state.email.languages.map((item, key)=>
						<Form.Group key={key}>
							<div className="row">
								<Form.Label className="ft-16 col-sm-2 text-right">
									<img alt="" src={require('./../../assets/images/campaign/'+item.code+'.png')} /> 
								</Form.Label>
					    		<div className="col-sm-10">
					    			<Form.Control maxLength="191" type="text" value={ item.subject || ''} 
					    			name="name"
					    			placeholder={Helpers.translate('Subject',this.state.translations.general,'general')}
					    			onChange={(e)=>this.updateEmailSubject(e.target.value,key)}
					    			autoComplete="off" />
					    		</div>
							</div>				    
						</Form.Group>									      
	    			);
				}
				emailSettings=<div className="mt-3 col-sm-12 col-md-12 col-lg-6">
					<Form className="text-left width_100">
						<Form.Group className="row">
					    	<Form.Label className="ft-14 font-weight-bold col-sm-2">{Helpers.translate('Email frequency',this.state.translations.campaign,'campaign')}</Form.Label>
					    	<Select options={this.state.email.frequencies}
					    		value={this.state.email.frequency && this.state.email.frequency.length ? this.state.email.frequency : []} 
					    		className={"col-sm-10 selectize "+(this.state.errors.frequency ? 'border-error' : '')}
          						isClearable={true}
          						isSearchable={true}
          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.id}
					    		onChange={(value)=>this.updateStateValue(value,'frequency','email')}
					    	/>
					    	{this.state.errors.frequency && <p className="text-danger mt-1">{this.state.alertsField.frequency}</p>}
					  	</Form.Group>
						<Form.Group>
							<Form.Label className="ft-14 font-weight-bold">{Helpers.translate('Email subject',this.state.translations.campaign,'campaign')}</Form.Label>
					    	<div>{ languages }</div>			    									    				  		
					  	</Form.Group>
					  	<Form.Group>
					  		<div>
					  			<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Header image',this.state.translations.general,'general')}</Form.Label>
					  			{this.state.email.header_image !== null && <div className="delete_picture text-right">
									<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.updateStateValue(null,'header_image','email')}></i>		      	
								</div>}
					  		</div>
						  	{this.state.email.header_image === null && <div className="text-left">		            		
			            		<Dropzone onDrop={(acceptedFiles)=>this.onEmailHeaderDrop(acceptedFiles)} accept={'image/jpg,image/png,image/jpeg'} multiple={false}>
								  {({getRootProps, getInputProps}) => (
								    <section className="d-inline-block">
								      <div {...getRootProps()}>
								        <input {...getInputProps()} />
								        <Button variant="secondary">{Helpers.translate('Upload header image',this.state.translations.general,'general')}</Button>
								      </div>
								    </section>
								  )}
								</Dropzone>
								<p><small>{Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')} {this.state.email.header_max_size/1000}Kb</small></p>
			            	</div>}
			            	{this.state.email.header_image !== null && <div className="dropZoneListThumb" >
						      <div className="thumbInner pointer">
						        <img src={this.state.email.header_image.preview} alt="" onClick={()=>window.open(this.state.email.header_image.preview,'_blank')}/>
						      </div>
						    </div>}
					    </Form.Group>				  	
					  	<div className="mt-3 mb-3 text-left">
							<Button className="btn-general" onClick={()=>this.updateCampaignEmailSettings()}>{Helpers.translate('Save changes',this.state.translations.general,'general')}</Button>
						</div>
					</Form>
				</div>
			}
			let campaignDescription;
			if(this.state.activeTab === 'description'){
				let descriptionTitles;
				let descriptionTexts;
				let descriptionLongTexts;	
				if(this.state.description.languages){
					descriptionTitles=this.state.description.languages.map((item, key)=>
			    		<Form.Group key={key}>
			    			<div className="row">
			    				<Form.Label className="ft-16 col-sm-2 text-right mb-0">{ item.code.toUpperCase() }</Form.Label>
					    		<div className="col-sm-10">
					    			<Form.Control maxLength="191" type="text" value={ item.title || ''} 
					    			placeholder={Helpers.translate('Title',this.state.translations.general,'general')}
					    			onChange={(e)=>this.updateDescriptionData(e.target.value,'title',key)}
					    			autoComplete="off" />
					    		</div>
			    			</div>				    
						</Form.Group>												      
	    			);
	    			descriptionTexts=this.state.description.languages.map((item, key)=>
			    		<Form.Group key={key}>
			    			<div className="row">
			    				<Form.Label className="ft-16 col-sm-2 text-right mb-0">{ item.code.toUpperCase() }</Form.Label>
			    				<div className="col-sm-10">
					    			<Form.Control as="textarea" rows="3" value={ item.description || ''} 
					    			onChange={(e)=>this.updateDescriptionData(e.target.value,'description',key)}/>
					    		</div>
			    			</div>					  				    	
						</Form.Group>													      
	    			);
	    			descriptionLongTexts=this.state.description.languages.map((item, key)=>
			    		<Form.Group key={key}>
			    			<div className="row">
			    				<Form.Label className="ft-16 col-sm-2 text-right mb-0">{ item.code.toUpperCase() }</Form.Label>
			    				<div className="col-sm-10">
					    			<Editor
					    				apiKey={ process.env.REACT_APP_TINYMCE_KEY }
								        value={ item.long_description || '' }
								        init={ this.tinymceConfig }
								        onEditorChange={(content,editor)=>this.updateDescriptionData(content,'long_description',key)}
	       							/>			
					    		</div>
			    			</div>
						</Form.Group>													      
	    			);
				}	
				campaignDescription=<div className="mt-3 col-sm-12">
					<div className="row">
						<Form className="text-left width_100">
							<div className="row">
								<div className="col-sm-12 col-md-6">
									<Form.Group>
										<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Campaign title',this.state.translations.campaign,'campaign')}</Form.Label>
								    	<div>{ descriptionTitles }</div>			    									    				  		
								  	</Form.Group>
								  	<Form.Group>
										<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Short description text',this.state.translations.campaign,'campaign')}</Form.Label>
								    	<div>{ descriptionTexts }</div>			    									    				  		
								  	</Form.Group>
								  	<Form.Group>
										<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Long description text',this.state.translations.campaign,'campaign')}</Form.Label>
								    	<div>{ descriptionLongTexts }</div>			    									    				  		
								  	</Form.Group>
								</div>
								<div className="offset-md-1 col-md-5 col-sm-12">
									<Form.Group>
								  		<div>
								  			<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Campaign image',this.state.translations.general,'general')}</Form.Label>
								  			{this.state.description.description_image !== null && <div className="delete_picture text-right">
												<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.updateStateValue(null,'description_image','description')}></i>		      	
										    </div>}
								  		</div>
									  	{this.state.description.description_image === null && <div className="text-left">		            		
						            		<Dropzone onDrop={(acceptedFiles)=>this.onDescriptionDrop(acceptedFiles)} accept={'image/jpg,image/png,image/jpeg'} multiple={false}>
											  {({getRootProps, getInputProps}) => (
											    <section className="d-inline-block">
											      <div {...getRootProps()}>
											        <input {...getInputProps()} />
											        <Button variant="secondary">{Helpers.translate('Upload campaign image',this.state.translations.general,'general')}</Button>
											      </div>
											    </section>
											  )}
											</Dropzone>
											<p><small>{Helpers.translate('Maximum size allowed is',this.state.translations.general,'general')} {this.state.description.description_max_size/1000}Kb</small></p>
						            	</div>}
						            	{this.state.description.description_image !== null && <div className="dropZoneListThumb" >
									      <div className="thumbInner pointer">
									        <img src={this.state.description.description_image.preview} alt="" onClick={()=>window.open(this.state.description.description_image.preview,'_blank')}/>
									      </div>
									    </div>}
								    </Form.Group>
								</div>					    			  					  	
							  	<div className="col-sm-12 mt-3 mb-3 text-left">
									<Button className="btn-general" onClick={()=>this.updateCampaignDescriptionSettings()}>{Helpers.translate('Save changes',this.state.translations.general,'general')}</Button>
								</div>
							</div>					
						</Form>
					</div>				
				</div>
			}
			let campaignStatistics;
			if(this.state.activeTab === 'statistics'){
				campaignStatistics=<CampaignStatistics
							campaign_id={this.state.campaign_id} 
		    				messages={this.state.statistics} 
		    				translations={this.state.translations} 
		    			/>
			}
			bodyContent=<div className="col-sm-12 mt-5 mt-3-mobile">
				<div className="row">
					<div className="col-sm-12">
						<div className="row">
							<div className="col-sm-12 col-md-3">
								<div className="back-button text-left">
									<Link to="/campaigns"><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to campaigns overview',this.state.translations.campaign,'campaign')}</span></Link>
								</div>
							</div>
							<Nav className="col-sm-12 col-md-9 text-right" id="pills-tab" as="nav" variant="pills" role="tablist" activeKey={this.state.activeTab} onSelect={(k) => this.renderTab(k)}>
								<Nav.Link className="nav-general" eventKey="messages">{Helpers.translate('Messages',this.state.translations.general,'general')}</Nav.Link>
								{this.props.permissions.campaign.full_control && 
								    <Nav.Link className="nav-general" eventKey="publication_settings">{Helpers.translate('Publication settings',this.state.translations.campaign,'campaign')}</Nav.Link>
								}
								<Nav.Link className="nav-general" eventKey="email_settings">{Helpers.translate('Email settings',this.state.translations.campaign,'campaign')}</Nav.Link>
								<Nav.Link className="nav-general" eventKey="description">{Helpers.translate('Description',this.state.translations.general,'general')}</Nav.Link>
								<Nav.Link className="nav-general" eventKey="statistics">{Helpers.translate('Trainings statistics',this.state.translations.campaign,'campaign')}</Nav.Link>
							</Nav>
						</div>
					</div>
					<div className="col-sm-12 mt-5">
						{!this.state.tmp.edit_name && <h3 className={"text-left "+(this.state.activeTab === 'publication_settings' || this.state.activeTab === 'email_settings' ? "mb-3": "")}>
							<span>{ this.state.campaign.name || '' }</span>
							{this.props.permissions.campaign.full_control && <Button className="ml-2" variant="link" size="sm" onClick={()=>this.updateStateValue([this.state.campaign.name,true],['name','edit_name'],'tmp')}>
					    		<i className="fas fa-pencil-alt"></i>
					    	</Button>}
						</h3>}
						{this.state.tmp.edit_name && 
						<h3>
							<Form.Group>
						    	<div className="row">
						    		<div className="col-sm-8">
						    			<InputGroup>
						    				<Form.Control type="text" value={this.state.tmp.name || ''} 
						    				name="name"
						    				maxLength="191"
						    				placeholder={Helpers.translate('Campaign name',this.state.translations.campaign,'campaign')}
						    				onChange={(e)=>this.updateStateValue(e.target.value,'name','tmp')}
						    				autoComplete="off" />
						    				<InputGroup.Append>
										      	<InputGroup.Text className="pointer" onClick={()=>this.updateName()}><i className="fas fa-check"></i></InputGroup.Text>
										      	<InputGroup.Text className="pointer" onClick={()=>this.updateStateValue(['',false],['name','edit_name'],'tmp')}><i className="fas fa-times"></i></InputGroup.Text>
										    </InputGroup.Append>
						    			</InputGroup>				    			
						    		</div>
						    	</div>			    									    	
						  		{this.state.errors.name && <p className="text-danger mt-1 ft-16 text-left">{this.state.alertsField.name}</p>}
						  	</Form.Group>
						</h3>
						}
					</div>						
					<div className="tab-content col-sm-12">
						<div id="messages" role="tabpanel" aria-hidden="false" className={"fade tab-pane "+(this.state.activeTab === "messages" ? "active show" : "")}>
							{campaignMessages}
						</div>						
						{this.props.permissions.campaign.full_control && <div id="publication_settings" role="tabpanel" aria-hidden="false" className={"fade tab-pane "+(this.state.activeTab === "publication_settings" ? "active show" : "")}>
							{publicationSettings}
						</div>}						
						<div id="email_settings" role="tabpanel" aria-hidden="false" className={"fade tab-pane "+(this.state.activeTab === "email_settings" ? "active show" : "")}>
							{emailSettings}
						</div>					
						<div id="description" role="tabpanel" aria-hidden="false" className={"fade tab-pane "+(this.state.activeTab === "description" ? "active show" : "")}>
							{campaignDescription}
						</div>					
						<div id="statistics" role="tabpanel" aria-hidden="false" className={"fade tab-pane "+(this.state.activeTab === "statistics" ? "active show" : "")}>
							{campaignStatistics}
						</div>					
					</div>
				</div>
			</div>
		}	
		return (
			<div className="content training_view">
				{this.state.show_content && bodyContent}
		   	</div>
		);
	}

}
export default withRouter(Campaign);