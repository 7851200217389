import React from 'react';
import Helpers from './../services/Helpers.js';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import DismissibleAlert from './DismissibleAlert.js';

export default class CampaignMoreInfo extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	preview_email:this.props.data.preview_email  	
	  	};
	}

	sendPreviewMail(){
    	let obj={};
		obj.campaign_language_id=this.props.data.id;
		obj.email=this.state.preview_email;
		Helpers.doRequest('post','trainings/preview_send',obj)
            .then(data=>{
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data,false,true);
            	},200);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true,true);
            });
    }

    previewInBrowser(){
    	let obj={};
		obj.campaign_language_id=this.props.data.id;
		obj.email=this.state.preview_email;
		Helpers.doRequest('post','trainings/preview_browser',obj)
            .then(data=>{
            	//open new tab with the html content
            	let docWindow=window.open();
            	docWindow.document.write(data.content);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true,true);
            });
    }

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
			<DismissibleAlert key={key} index={key} type={item.type} modal={true} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );

		return (
			<div className="col-xs-12">
				{generalMsg}
				<p className="ft-20 font-weight-bold text-justify">{this.props.data.title}</p>
				<div className="row">
					<div className="col-sm-6">
						<img src={this.props.data.campaign_image} className="img-fluid" alt=""/>
					</div>
					<div className="col-sm-6">
						<Form className="text-left width_100">
							<Form.Group className="training_line_separator">
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{Helpers.translate('Language',this.props.translations.general,'general')}</Form.Label>		    									    				  		
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{ this.props.data.language_name }</Form.Label>		    									    				  
						  	</Form.Group>
						  	<Form.Group className="training_line_separator">
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{Helpers.translate('Messages',this.props.translations.general,'general')}</Form.Label>		    									    				  		
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{ this.props.data.total_ready_messages }</Form.Label>		    									    				  
						  	</Form.Group>
						  	<Form.Group className="training_line_separator">
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{Helpers.translate('Message frequency',this.props.translations.general,'general')}</Form.Label>		    									    				  		
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{ this.props.data.frequency }</Form.Label>		    									    				  
						  	</Form.Group>
						  	<Form.Group className="training_line_separator">
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{Helpers.translate('Last publication/update',this.props.translations.general,'general')}</Form.Label>		    									    				  		
						  		<Form.Label className="ft-16 col-sm-6 pl-0">{ this.props.data.last_updated }</Form.Label>		    									    				  
						  	</Form.Group>
						</Form>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-sm-12" dangerouslySetInnerHTML={{__html: this.props.data.long_description}}>
					</div>				
				</div>
				<div className="row">
					<div className="col-sm-12">
						<Form.Group>
					    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Get preview of first message',this.props.translations.general,'general')}</Form.Label>
					    	<InputGroup>					    		
					    		<InputGroup.Prepend>
								      <InputGroup.Text><i className="far fa-envelope"></i></InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control type="text" value={this.state.preview_email || ''} 
					    			name="name"
					    			placeholder={Helpers.translate('Email',this.props.translations.general,'general')}
					    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'preview_email')}
					    			autoComplete="off" />
					    		<InputGroup.Append>
								    <Button variant="outline-secondary" onClick={()=>this.sendPreviewMail()}>{Helpers.translate('Send',this.props.translations.general,'general')}</Button>
								</InputGroup.Append>
					    	</InputGroup>
					    	{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
					  	</Form.Group>
					  	<Form.Group className="mb-0">
					    	<Form.Label>
					    		<Button className="text-decoration-none pl-0 btn_preview_browser" variant="link" size="sm" onClick={()=>this.previewInBrowser()}>
						    		<span>{Helpers.translate('Preview in browser',this.props.translations.general,'general')}</span>
						    		<i className="ml-2 fas fa-external-link-alt"></i>
						    	</Button>
					    	</Form.Label>
					  	</Form.Group>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<Form className="text-left width_100">
							<Form.Group>
								<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Messages',this.props.translations.general,'general')}</Form.Label>
							</Form.Group>
						{
							this.props.data.messages_data.map((item, key) =>
				    			<Form.Group className="training_line_separator" key={key}>	    									    				  		
							  		<Form.Label className="ft-16 col-sm-12 pl-0">{Helpers.translate(item.title,this.props.translations.general,'general')}</Form.Label>		    									    				  
							  	</Form.Group>		
						    )
						}
						</Form>
					</div>
				</div>
			</div>	
		);
	}

}