import React from 'react';
import { withRouter,Link } from 'react-router-dom';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

class TeamMember extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	  		translations:{},
	  		create:true,
	  		team_id:null,
	  		show_content:false,
	  		member:{}
	  	};
	  	this.moduleName='team';
	  	this.member_validations={
	  		"email":["required","email"]
	  	};
	}

	componentDidMount(){
		if(!this.props.match.params.team_id || (this.props.match.params.team_id && isNaN(this.props.match.params.team_id))){
			this.props.history.push("/");
		}else{
			if(this.props.match.params.id){
				let oldState=Object.assign({}, this.state);
				if(!isNaN(parseInt(this.props.match.params.id))){
					Helpers.doRequest('get','team_members/'+this.props.match.params.id+'/edit?team_id='+this.props.match.params.team_id)
			 			.then(data=>{  		 				       	                 	            	
			            	oldState.create=false;
			            	oldState.show_content=true;
			            	oldState.member=data.member;
			            	oldState.team_id=this.props.match.params.team_id;
			            	Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
			 			}).catch(error=>{
			            	//	
			        	});
				}else{
					Helpers.doRequest('get','team_members/create?team_id='+this.props.match.params.team_id)
			 			.then(data=>{  	
			 				if(!data.member.allow_create){
			 					this.props.history.push("/teams/"+this.props.match.params.team_id);
								return false;
			 				}
			 				oldState.show_content=true;	 
			 				oldState.team_id=this.props.match.params.team_id;	
			 				oldState.member=data.member;		       	                 	    
			            	Helpers.getTranslations(this.moduleName)
					            .then(data=>{
					            	oldState.translations=data;			                						                
					                this.setState(oldState);
					            }).catch(error=>{
					            	oldState.translations=error;
					            	this.setState(oldState);
					            });
			 			}).catch(error=>{
			            	//	
			        	});		
				}		
			}
		}		
	}

    saveMember(){
		let fieldTranslations={
    		"email":Helpers.translate('Email',this.state.translations.general,'general')
    	};
		let formErrors=FormValidation.checkValidity(this.member_validations,this.state.member,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.member);
		obj.team_id=this.state.team_id;
		if(this.state.member.language && this.state.member.language.length){
			obj.language=this.state.member.language[0].code;
		}
		if(this.state.member.id){
			Helpers.doRequest('put','team_members/'+this.state.member.id,obj)
	            .then(data=>{
	            	this.props.history.push("/teams/"+this.state.team_id,{"msg":data});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}else{
			Helpers.doRequest('post','team_members',obj)
	            .then(data=>{
	            	this.props.history.push("/teams/"+this.state.team_id,{"msg":data});
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,true);
	            });
		}
		
	}

	render(){
	    let bodyContent;
	    if(this.state.show_content){
	    	bodyContent=<div className="col-md-6 col-sm-12 mt-5 mt-3-mobile">
	    		<div className="back-button text-left">
					<Link to={"/teams/"+this.state.team_id}><i className="fas fa-chevron-left"></i> <span>{Helpers.translate('Back to',this.state.translations.team,'team')} {this.state.member.team_name}</span></Link>
				</div>
		    	<Form className="text-left width_100 mt-5">
		    		<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">
				    		<span>{Helpers.translate('Email',this.state.translations.general,'general')}</span>
				    		<span><i className="fas fa-asterisk text-danger ml-1 ft-11"></i></span>
				    	</Form.Label>
				    	<Form.Control type="text" value={this.state.member.email || ''} 
			    			name="email"
			    			placeholder={Helpers.translate('Email',this.state.translations.general,'general')}
			    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'email','member')}
			    			autoComplete="off"
			    			onKeyPress={(e) => Helpers.submitForm(this,e,'saveMember') } />
			    		{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('First name',this.state.translations.general,'general')}</Form.Label>
				    	<Form.Control type="text" value={this.state.member.first_name || ''} 
			    			name="first_name"
			    			placeholder={Helpers.translate('First name',this.state.translations.general,'general')}
			    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'first_name','member')}
			    			autoComplete="off"
			    			onKeyPress={(e) => Helpers.submitForm(this,e,'saveMember') } />
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Last name',this.state.translations.general,'general')}</Form.Label>
				    	<Form.Control type="text" value={this.state.member.last_name || ''} 
			    			name="last_name"
			    			placeholder={Helpers.translate('Last name',this.state.translations.general,'general')}
			    			onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'last_name','member')}
			    			autoComplete="off"
			    			onKeyPress={(e) => Helpers.submitForm(this,e,'saveMember') } />
				  	</Form.Group>
				  	<Form.Group>
				    	<Form.Label className="ft-16 font-weight-bold">{Helpers.translate('Language',this.state.translations.general,'general')}</Form.Label>
						<Select options={this.state.member.languages}
				    		value={this.state.member.language && this.state.member.language.length ? this.state.member.language : []} 
				    		className="selectize"
      						isClearable={false}
      						isSearchable={false}
      						placeholder={Helpers.translate('Language',this.state.translations.general,'general')}
				    		getOptionLabel={option => option.name}
				    		getOptionValue={option => option.code}
				    		onChange={(value)=>Helpers.updateStateValue(this,(value !== null ? [value] : value),'language','member')}
				    	/>
				    	{this.state.errors.language && <p className="text-danger mt-1">{this.state.alertsField.language}</p>}
				  	</Form.Group>
				  	<div className="mt-3 text-left">
						<Button className="btn-general" onClick={()=>this.saveMember()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</div>
					<div className="mt-5 text-left">
						<span>{Helpers.translate("We do not send confirmation emails to imported addresses and trust that you've gathered proper permissions to send to every address in your audience",this.state.translations.team,'team')}</span>
					</div>
		    	</Form>
		    </div>
	    }	    
		return (
			<div className="content">
				{bodyContent}
		   	</div>
		);
	}

}
export default withRouter(TeamMember);