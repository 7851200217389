import React from 'react';
import Button from 'react-bootstrap/Button';
import Helpers from './../../services/Helpers.js';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { withRouter,Link } from 'react-router-dom';

class MicroTrainings extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	currentPage: 1,
	  		translations:{},
	  		trainings:{
	  			'list':[]
	  		},
	  		show_content:false,
	  		search:{
	      		page:1,
	      		order_by:'start_date',
	      		order:'desc'
	      	}
	  	};
	  	this.moduleName='training';
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        urlString=urlString.replace("&", "?");
		Helpers.doRequest('get','trainings'+urlString)
 			.then(data=>{  		 				       	                 	            	
            	oldState.trainings=data.trainings;
            	oldState.show_content=true;
        		Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		                if(this.props.location.state && this.props.location.state.msg){
		                	let msg=Object.assign({}, this.props.location.state.msg);
		                	window.history.pushState(null, '');
		                	let genThis=this;
		                	setTimeout(function(){		                		                		
		                		Helpers.showAlerts(genThis,msg);				                					           
			            	},200);
		                }
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });      	
 			}).catch(error=>{
            	//	
        	});
	}

	loadList(value,model){
		let oldState=Object.assign({}, this.state);
		oldState.errors={};
        oldState.alerts=[]; 
        oldState.alertsField=[];
        oldState.search[model]=value;
    	let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        urlString=urlString.replace("&", "?");
		Helpers.doRequest('get','trainings'+urlString)
 			.then(data=>{  		 				       	                 	            	
            	oldState.trainings=data.trainings;
            	this.setState(oldState);        	
 			}).catch(error=>{
            	//	
        	});
	}

	sortList(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.search.page=1;
    	if(oldState.search.order_by !== value){
    		oldState.search.order='asc';
    		oldState.search.order_by=value;  
    	}else{
    		oldState.search.order_by=value;  
    		if(oldState.search.order === 'asc'){
	    		oldState.search.order = 'desc';
	    	}else if(oldState.search.order === 'desc'){
	    		oldState.search.order = '';
	    		oldState.search.order_by='';  
	    	}else{
	    		oldState.search.order = 'asc';
	    	}
    	}   		
    	let urlString="";
        for(let x in oldState.search){
        	urlString+="&"+x+"="+oldState.search[x];
        }
        urlString=urlString.replace("&", "?");
        Helpers.doRequest('get','trainings'+urlString)
            .then(data=>{                                                                   
                oldState.trainings=data.trainings;
                this.setState(oldState);
            }).catch(error=>{
                //  
            });
    }

	render(){
		let trainingsContent;
		if(this.state.trainings.list){
			trainingsContent=this.state.trainings.list.map((item, key) =>
		        <tr key={key}>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ item.campaign_name }</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ item.language_name }</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ item.messages_number }</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ item.team_name }</td>
		        	<td className={ (item.status === 2 ? 'bg-success' : (item.status === 1 ? 'bg-info' : "bg-warning")) }
		        		onClick={()=>this.props.history.push("/trainings/"+item.id)}>
		        		{ Helpers.translate(item.status_name,this.state.translations.general,'general') }
		        	</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ new moment(Helpers.adjustDateTimezone(new Date(item.start_date))).format("DD/MM/YYYY HH:mm") }</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>
		        		{ (item.next_date ? new moment(Helpers.adjustDateTimezone(new Date(item.next_date))).format("DD/MM/YYYY HH:mm") : item.next_date)  }
		        	</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>
		        		{ Helpers.translate(item.type_name,this.state.translations.general,'general') }
		        	</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>{ item.messages_sent }</td>
		        	<td onClick={()=>this.props.history.push("/trainings/"+item.id)}>
		        		{ (item.end_date ? new moment(Helpers.adjustDateTimezone(new Date(item.end_date))).format("DD/MM/YYYY HH:mm") : item.end_date)  }
		        	</td>
		        </tr>
		    );

		}	
    	let trainingsPagination;
	    if(this.state.trainings.page_count>1){
	    	let forcePageObj = {}
			if (this.state.search.page === 1) {
			    forcePageObj['forcePage'] = 0;
			}
    		trainingsPagination=<ReactPaginate
				        previousLabel={'<'}
				        nextLabel={'>'}
				        breakLabel={'...'}
				        pageCount={this.state.trainings.page_count}
				        marginPagesDisplayed={2}
				        pageRangeDisplayed={5}	
				        {...forcePageObj}			        
				        onPageChange={(data)=>this.loadList(parseInt(data.selected)+1,'page')}
				        containerClassName={'pagination pagination-sm'}
				        pageClassName={'page-item'}
				        pageLinkClassName={'page-link'}
				        previousClassName={'page-item'}
				        nextClassName={'page-item'}
				        previousLinkClassName={'page-link'}
				        nextLinkClassName={'page-link'}
				        activeClassName={'active'} />
		}

		return (
			<div className="content">
				<div className="col-sm-12 mt-5">
					<h4 className="text-left">{Helpers.translate('Microtrainings',this.state.translations.training,'training')}</h4>
					{this.state.show_content && this.props.permissions.microtraining && this.props.permissions.microtraining.full_control && <div className="mt-3 text-left">
						<Button className="btn-general" disabled={(this.state.trainings.allow_create ? false : true)} onClick={()=>(this.state.trainings.allow_create ? this.props.history.push("/trainings/new"): ()=>{})}>{Helpers.translate('Launch new training',this.state.translations.training,'training')}</Button>
						{this.state.trainings.list && !this.state.trainings.allow_create && 
							<p className="mt-2">{Helpers.translate('You have reached the maximum numbers of trainings',this.state.translations.training,'training')}</p>
						}
					</div>}
					{this.state.show_content && this.state.trainings.list.length>0 && <div className="mt-3 text-left">
						<Table responsive="sm" size="sm" className="text-left active_rows">
				  			<thead>
						      <tr className="table-secondary">
						        <th className="pointer" onClick={()=>this.sortList("campaign_name")}>
						        	<span>{Helpers.translate('Campaign',this.state.translations.general,'general')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'campaign_name' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th>{Helpers.translate('Language',this.state.translations.general,'general')}</th>
						        <th>{Helpers.translate('Messages',this.state.translations.general,'general')}</th>
						        <th className="pointer" onClick={()=>this.sortList("team_name")}>
						        	<span>{Helpers.translate('Team',this.state.translations.general,'general')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'team_name' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th className="pointer" onClick={()=>this.sortList("status")}>
						        	<span>{Helpers.translate('Status',this.state.translations.general,'general')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'status' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th className="pointer" onClick={()=>this.sortList("start_date")}>
						        	<span>{Helpers.translate('Start',this.state.translations.general,'general')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'start_date' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th className="pointer" onClick={()=>this.sortList("next_date")}>
						        	<span>{Helpers.translate('Next message',this.state.translations.training,'training')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'next_date' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th>{Helpers.translate('Type',this.state.translations.general,'general')}</th>
						      	<th className="pointer" onClick={()=>this.sortList("messages_sent")}>
						        	<span>{Helpers.translate('Sent',this.state.translations.general,'general')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'messages_sent' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						        <th className="pointer" onClick={()=>this.sortList("end_date")}>
						        	<span>{Helpers.translate('End date',this.state.translations.training,'training')}</span>
						        	{
						        		(this.state.search.order && this.state.search.order_by === 'end_date' ? (this.state.search.order === 'asc' ? <i className="ml-2 fas fa-sort-up fa-lg"></i> : <i className="ml-2 fas fa-sort-down fa-lg"></i>) : null)
						        	}
						        </th>
						      </tr>
						    </thead>
						    <tbody>
						    	{ trainingsContent }
						    </tbody>
				  		</Table>
					</div>}
					{this.state.show_content && this.state.trainings.list.length === 0 &&
						<div className="mt-3">
							<div className="row min-vh-50 justify-content-center align-items-center">
								<Link to="/trainings/new">
									<img alt="" src={require('./../../assets/images/training/empty_list.png')} />  
								</Link>					    
							</div>
						</div>
					}
					<div>{ trainingsPagination }</div>
				</div>			
		   	</div>
		);
	}

}
export default withRouter(MicroTrainings);