import React from 'react';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Register from './../../components/Register.js';
import Activation from './../../components/Activation.js';
import { withRouter } from 'react-router-dom';

class MicrosoftAuth extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	registration:{},
	      	step:null,
	      	activation:{}
	  	};  	
	}

	componentDidMount(){
		if(this.props.location.search.length){
			Helpers.doRequest('get','login/microsoft/callback'+this.props.location.search)
	 			.then(data=>{
	 				if(!data.main_page){
	 					let oldState=Object.assign({}, this.state);
	 					if(data.activation){ 						
		 					oldState.step="activation";
		 					oldState.activation=data.activation;
	 					}else{
		 					oldState.step="registration";
		 					oldState.registration=data.registration;		 					
	 					}
	 					this.setState(oldState);				
	 				}else{
	 					window.location.href=data.redirect+'?microjwt='+data.microjwt;
	 				}	
	 			}).catch(error=>{
	            	Store.clearData();
	 				Helpers.showAlerts(this,error,'',true);	
	        	});
		}else{
			window.ee.emit('storeChange');
			this.props.history.push("/");	
		}
	}

	registrationComplete(data){
		window.location.href=data.redirect+'?microjwt='+data.microjwt;
	}

	render(){
		let bodyContent;
		if(this.state.step !== null){
			if(this.state.step === 'registration'){
				bodyContent=<Register registration={this.state.registration} onSuccess={(data)=>this.registrationComplete(data)}/>;
			}else{
				bodyContent=<Activation data={this.state.activation} onSuccess={(data)=>this.registrationComplete(data)}/>;
			}
		}else{
			const generalMsg = this.state.alerts.map((item, key) =>
				<Jumbotron fluid key={key}>
				  <h1>{item.msg}</h1>
				  <p>
				    <Button variant="primary" onClick={()=>this.props.history.push("/")}>Back to login</Button>
				  </p>
				</Jumbotron>
		    );
		    bodyContent=<div>{generalMsg}</div>
		}
		
		return (
			<div className="content">				
				{bodyContent}
			</div>
		);		
	}
}
export default withRouter(MicrosoftAuth);